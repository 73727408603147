import React, { useEffect, useState } from "react";
// import HeaderLogoComponent from "../../Components/LogoComponent";
import { Footer } from "antd/lib/layout/layout";
import { Link, useLocation } from "react-router-dom";
import FooterBGImage from "../../../../assets/images/footer-bg.jpg";
import SVGSprite from "../../../../assets/images/icon/connect-sprite.svg";
// import RoadTruckImg from "../../../../assets/images/footer-roadTruck.png";
import { Col, Row, Form } from "antd";
import TextInputBox from "../../../../components/AntdAddons/components/InputBox/TextInputBox";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import {
  HomeSubscribeRequest,
  vsmNotify,
} from "../../../../requests/PagesRequest";
import { FormBox } from "../../../../components/AntdAddons";

import { ReactComponent as FacbookIcon } from "../../../../assets/images/icon/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/images/icon/twitterX.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/images/icon/instagram.svg";
import { ReactComponent as LinkedIcon } from "../../../../assets/images/icon/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/images/icon/youtube.svg";

const FooterView: React.FC = observer(() => {
  const {
    AUTH: { footerData },
  } = useStore();
  const location = useLocation();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [pathname, setPathname] = useState<string>("");

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const emailSubscribed = () => {
    form.resetFields();
    setDisabled(true);
    vsmNotify.success({ message: "You have subscribed successfully" });
  };

  return footerData ? (
    <Footer style={{ backgroundImage: `url(${FooterBGImage})` }}>
      <div className="container">
        <Row gutter={30} className="footer_top">
          <Col xs={{ span: 24 }} lg={{ span: 16 }} className="col_info">
            {/* <h3>{footerData?.top_row?.heading}</h3> */}
            <h3
              dangerouslySetInnerHTML={{
                __html: footerData?.top_row?.heading,
              }}
            ></h3>
            <div className="links">
              <a
                href={footerData?.top_row?.google_play_link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={footerData?.top_row?.google_play_image}
                  alt="Play Store"
                />
              </a>
              <a
                href={footerData?.top_row?.apple_store_link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={footerData?.top_row?.apple_store_image}
                  alt="App Store"
                />
              </a>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }} className="col_form">
            <h4>{footerData?.top_row?.subscriber_heading}</h4>
            <FormBox form={form} id="appoinment" onChange={handleChange}>
              <div className="input_group">
                <TextInputBox
                  className="input"
                  placeholder="Your Email Address"
                  name="email"
                  required
                  rules={HomeSubscribeRequest.email}
                />
                <button
                  className="btn btn_secondary"
                  disabled={disabled}
                  onClick={() => emailSubscribed()}
                >
                  Subscribe
                </button>
              </div>
            </FormBox>
          </Col>
        </Row>

        <div className="footer_bottom">
          <div className="col_address">
            <div className="title_wrap">
              <h3>{footerData?.widgets?.corporate_office.title}</h3>
            </div>
            <address>{footerData?.widgets?.corporate_office.content}</address>
          </div>
          <div className="col_usefulLink">
            <div className="title_wrap">
              <h3>{footerData?.widgets?.useful_links.title}</h3>
            </div>
            <ul className="links">
              {footerData?.widgets?.useful_links.links.map(
                (item: any, index: any) => {
                  return (
                    <li key={index}>
                      <Link
                        to={item.url}
                        className={pathname === item.url ? "active" : ""}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div className="col_socialLink">
            <div className="title_wrap">
              <h3>{footerData?.widgets?.social_link.title}</h3>
            </div>
            <ul className="social_link">
              {footerData?.widgets?.social_link.facebook && (
                <li>
                  <a
                    href={footerData?.widgets?.social_link.facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg className="img">
                      <FacbookIcon />
                    </svg>
                  </a>
                </li>
              )}
              {footerData?.widgets?.social_link.instagram && (
                <li>
                  <a
                    href={footerData?.widgets?.social_link.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </li>
              )}
              {footerData?.widgets?.social_link.twitter && (
                <li>
                  <a
                    href={footerData?.widgets?.social_link.twitter}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                </li>
              )}
              {footerData?.widgets?.social_link.linkedin && (
                <li>
                  <a
                    href={footerData?.widgets?.social_link.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIcon />
                  </a>
                </li>
              )}
              {footerData?.widgets?.social_link.youtube && (
                <li>
                  <a
                    href={footerData?.widgets?.social_link.youtube}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YoutubeIcon />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="col_qrCode">
            <div className="title_wrap">
              <h3>{footerData?.widgets?.website_qr_code.title}</h3>
            </div>
            <div className="col_qrCode__img">
              <img
                src={footerData?.widgets?.website_qr_code.qr_image}
                className="img"
                alt="QR code"
              />
            </div>
          </div>
          <div className="col_contact">
            <div className="title_wrap">
              <h3>{footerData?.widgets?.contact_us.title}</h3>
            </div>
            <div className="col_contact__wrap">
              <div className="box1">
                <p>
                  Helpline No:{" "}
                  <a
                    href={`tel:${footerData?.widgets?.contact_us.helpline_no}`}
                  >
                    {footerData?.widgets?.contact_us.helpline_no}
                  </a>
                </p>
                <p>
                  Email:{" "}
                  <a href={`mailto:${footerData?.widgets?.contact_us.email}`}>
                    {footerData?.widgets?.contact_us.email}
                  </a>
                </p>
              </div>
              <br />
              <div className="box2">
                <h4>Office Hours:</h4>
                <p>{footerData?.widgets?.contact_us.open_hours}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_cpy">
        <Link
          to="#"
          className="btn btn_secondary backToTop"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        >
          <svg className="img">
            <use xlinkHref={`${SVGSprite}#arrow-up`}></use>
          </svg>
        </Link>
        <div className="container">
          <div className="col_link">
            <div className="title_wrap" style={{ opacity: 0 }}>
              <h3>&nbsp;</h3>
            </div>
            {footerData?.bottom_row?.menu.map((item: any, index: any) => {
              return (
                <Link
                  to={item.url}
                  key={index}
                  className={pathname === item.url ? "active" : ""}
                >
                  {item.title}
                </Link>
              );
            })}
          </div>
          <div className="col_office">
            <div className="title_wrap">
              <h3>{footerData?.bottom_row?.head_office?.title}</h3>
            </div>
            <address>{footerData?.bottom_row?.head_office?.address}</address>
          </div>
          <div className="col_cpy">
            <div className="title_wrap" style={{ opacity: 0 }}>
              <h3>&nbsp;</h3>
            </div>
            <p>{footerData?.bottom_row?.copyright}</p>
          </div>
        </div>
      </div>

      {/* <div
        className="road_wrap"
        style={{ backgroundImage: `url(${RoadTruckImg})` }}
      >
        <span
          className="truck1"
          style={{ backgroundImage: `url(${RoadTruckImg})` }}
        ></span>
        <span
          className="truck2"
          style={{ backgroundImage: `url(${RoadTruckImg})` }}
        ></span>
      </div> */}
    </Footer>
  ) : (
    <></>
  );
});

export default FooterView;
