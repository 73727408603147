// import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Tooltip } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import useStore from "../../../../store";
import { ReactComponent as LeftArrow } from "../../../../assets/images/icon/left-arrow.svg";
import { TravelfreeFormRequest } from "../../../../requests/PagesRequest";
import { ReactComponent as InfoCircle } from "../../../../assets/images/icon/info-circle.svg";
import SeoHeader from "../../../../components/SeoHeader";

const PickupDelivery: React.FC<any> = observer(
  ({ setCurrent, current }: any) => {
    const [form] = Form.useForm();

    const { TRAVELFREESTORE } = useStore();

    const [tabEnable, setTabEnable] = useState<string>("Pickup");
    const [pickupDataAdded, setPickupDataAdded] = useState<boolean>(false);
    const [deliveryDataAdded, setDeliveryDataAdded] = useState<boolean>(false);

    const VisibleTab = (val: string) => {
      setTabEnable(val);
    };

    const handlePickUpSubmit = (data: any) => {
      form.validateFields().then(() => {
        TRAVELFREESTORE.travelfree_form_data = {
          ...TRAVELFREESTORE.travelfree_form_data,
          ...data,
        };
        localStorage.setItem(
          "travelfree-form",
          JSON.stringify(TRAVELFREESTORE.travelfree_form_data)
        );
        setPickupDataAdded(true);
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (deliveryDataAdded === false) {
          setTabEnable("Delivery");
        } else {
          setCurrent(current + 1);
        }
      });
    };

    const handleDeliverySubmit = (data: any) => {
      form.validateFields().then(() => {
        TRAVELFREESTORE.travelfree_form_data = {
          ...TRAVELFREESTORE.travelfree_form_data,
          ...data,
        };
        localStorage.setItem(
          "travelfree-form",
          JSON.stringify(TRAVELFREESTORE.travelfree_form_data)
        );
        setDeliveryDataAdded(true);
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (pickupDataAdded === false) {
          setTabEnable("Pickup");
        } else {
          setCurrent(current + 1);
        }
      });
    };

    useEffect(() => {
      let getLocalData: any | null = JSON.parse(
        localStorage.getItem("travelfree-form") as any | null
      );
      let updateData: any =
        TRAVELFREESTORE?.travelfree_form_data?.length > 0
          ? TRAVELFREESTORE?.travelfree_form_data
          : getLocalData;

      form.setFieldsValue({
        pickup_city: JSON.parse(
          localStorage.getItem("travelfree_pickup_pin") ?? ""
        ).city_pick,
        pickup_state: JSON.parse(
          localStorage.getItem("travelfree_pickup_pin") ?? ""
        ).state_pick,
        delivery_city: JSON.parse(
          localStorage.getItem("travelfree_delivery_pin") ?? ""
        ).city,
        delivery_state: JSON.parse(
          localStorage.getItem("travelfree_delivery_pin") ?? ""
        ).state,
        pickup_name: updateData?.pickup_name,
        pickup_address1: updateData?.pickup_address1,
        pickup_address2: updateData?.pickup_address2,
        delivery_name: updateData?.delivery_name,
        delivery_mobile: updateData?.delivery_mobile,
        delivery_address1: updateData?.delivery_address1,
        delivery_address2: updateData?.delivery_address2,
        delivery_email: updateData?.delivery_email,
        delivery_what3words: updateData?.delivery_what3words,
      });
    }, [form, TRAVELFREESTORE]);

    const tooltipText = () => {
      return (
        <label className="tooltipLabel">
          What3words?{" "}
          <Tooltip title="Help">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://what3words.com/downs.roadblock.circle"
            >
              <InfoCircle />
            </a>
          </Tooltip>
        </label>
      );
    };

    const SeoData = {
      title: "Pick-up & Delivery Details for Travel Free",
      description:
        "Shree Maruti provides Travel Free process for online parcel booking, same-day door-to-door delivery service, and the best home pickup courier service in India.",
      keywords:
        "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
      url: "/travelfree-form",
      ogTitle: "Shipment Details for Travel Free",
    };

    return (
      <>
        <SeoHeader seoData={SeoData} />

        <div className="eBook_form tabs_wrapper">
          <ul className="tab_wrap">
            <li
              className={`tab ${tabEnable === "Pickup" ? "active" : ""}`}
              onClick={() => VisibleTab("Pickup")}
            >
              <span>Pickup Address</span>
            </li>
            <li
              className={`tab ${tabEnable === "Delivery" ? "active" : ""}`}
              onClick={() => VisibleTab("Delivery")}
            >
              <span>Delivery Address</span>
            </li>
          </ul>

          <div className="tabs_container">
            {tabEnable === "Pickup" ? (
              <>
                <FormBox form={form} onFinish={handlePickUpSubmit}>
                  <Row gutter={30} align="middle" className="input_group_blk">
                    <Col xs={{ span: 24 }}>
                      <div className="input_group">
                        <h4>
                          Add Pickup address at pincode :{" "}
                          {
                            JSON.parse(
                              localStorage.getItem("travelfree_pickup_pin") ??
                                ""
                            ).pincode
                          }
                        </h4>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Full Name"
                          name="pickup_name"
                          required
                          rules={TravelfreeFormRequest.pickup_name}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Address Line 1"
                          name="pickup_address1"
                          required
                          rules={TravelfreeFormRequest.pickup_address1}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Address Line 2"
                          name="pickup_address2"
                          rules={TravelfreeFormRequest.pickup_address2}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="City"
                          name="pickup_city"
                          required
                          disabled
                          rules={TravelfreeFormRequest.pickup_city}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="State"
                          name="pickup_state"
                          required
                          disabled
                          rules={TravelfreeFormRequest.pickup_state}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }}>{""}</Col>
                    <Col
                      xs={{ span: 12 }}
                      className="text-left col_btn prevBtnWrap"
                    >
                      <Button
                        className="prevBtn"
                        onClick={() => setCurrent(current - 1)}
                      >
                        <LeftArrow />
                        Previous
                      </Button>
                    </Col>
                    <Col
                      xs={{ span: 12 }}
                      className="text-right col_btn submitBtn"
                    >
                      <Button
                        className="btn btn_secondary"
                        htmlType="submit"
                        // disabled={pickupDisabled}
                      >
                        Save & Continue
                      </Button>
                    </Col>
                  </Row>
                </FormBox>
              </>
            ) : (
              <>
                <FormBox
                  form={form}
                  onFinish={handleDeliverySubmit}
                  // onChange={deliveryHandleChange}
                >
                  <Row gutter={30} align="middle" className="input_group_blk">
                    <Col xs={{ span: 24 }}>
                      <div className="input_group">
                        <h4>
                          Add Delivery Address at pincode :{" "}
                          {
                            JSON.parse(
                              localStorage.getItem("travelfree_delivery_pin") ??
                                ""
                            ).pincode
                          }
                        </h4>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Full Name"
                          name="delivery_name"
                          required
                          rules={TravelfreeFormRequest.delivery_name}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Mobile Number"
                          name="delivery_mobile"
                          required
                          rules={TravelfreeFormRequest.delivery_mobile}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Address Line 1"
                          name="delivery_address1"
                          required
                          rules={TravelfreeFormRequest.delivery_address1}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Address Line 2"
                          name="delivery_address2"
                          rules={TravelfreeFormRequest.delivery_address2}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="Email"
                          name="delivery_email"
                          required
                          rules={TravelfreeFormRequest.delivery_email}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="City"
                          name="delivery_city"
                          required
                          disabled
                          rules={TravelfreeFormRequest.delivery_city}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="input_group">
                        <InputBox.Text
                          label="State"
                          name="delivery_state"
                          required
                          disabled
                          rules={TravelfreeFormRequest.delivery_city}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      {/* <label>What3words?<a href="#">i</a></label> */}
                      <div className="input_group">
                        <InputBox.Text
                          label={tooltipText()}
                          // tooltip={tooltipText()}
                          name="delivery_what3words"
                          rules={TravelfreeFormRequest.delivery_what3words}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }}>{""}</Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      className="text-left col_btn prevBtnWrap"
                    >
                      <Button
                        className="prevBtn"
                        onClick={() => setCurrent(current - 1)}
                      >
                        <LeftArrow />
                        Previous
                      </Button>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      className="text-right col_btn submitBtn"
                    >
                      <Button
                        className="btn btn_secondary"
                        htmlType="submit"
                        // disabled={deliveryDisabled}
                      >
                        Save & Continue
                      </Button>
                    </Col>
                  </Row>
                </FormBox>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default PickupDelivery;
