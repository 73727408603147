import React from "react";
import { Helmet } from "react-helmet-async";
import { seoDefaultData } from "../../config/seoDefaultData";

type Props = {
  seoData: {
    title: string;
    description: string;
    keywords: string;
    url: string;
    ogTitle: string;
    ogImage: string;
  };
};

const SeoHeader: React.FC<any> = (props: Props) => {
  const {
    seoData: { title, description, keywords, url, ogTitle, ogImage },
  } = props;
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{title ?? seoDefaultData.title}</title>
        <meta
          name="description"
          content={description ?? seoDefaultData.description}
        />
        <meta name="keywords" content={keywords ?? seoDefaultData.keywords} />
        <link rel="canonical" href={url ?? seoDefaultData.url} />

        {/* Open Graph Metadata */}

        <meta property="og:title" content={ogTitle ?? seoDefaultData.ogTitle} />
        <meta property="og:url" content={url ?? seoDefaultData.url} />
        <meta property="og:image" content={ogImage ?? seoDefaultData.ogImage} />
        <meta
          property="og:site_name"
          content="Shree Maruti Courier Pvt. Ltd."
        />
        <meta property="og:type" content="website" />
      </Helmet>
    </>
  );
};

export default SeoHeader;
