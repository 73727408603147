import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd";
// import { LoginRequest } from "../../../requests/AuthRequest";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
// import CardImage from "../../../assets/images/card-bg-01.png";
import { ReactComponent as EbookIcon } from "../../../assets/images/icon/booking.svg";
import { ReactComponent as ClientIcon } from "../../../assets/images/icon/user.svg";
import { ReactComponent as EmployeeIcon } from "../../../assets/images/icon/employee_user.svg";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import SVGSprite from "../../../assets/images/icon/connect-sprite.svg";
import { randomnumbersubstring } from "../../../utils/GlobalFunction";
import { ClientLogin, ContactRequest } from "../../../requests/PagesRequest";

const Login: React.FC<any> = observer(({ visible, close }) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const { AUTH, ROOT } = useStore();
  const { doClientLogin } = AUTH;
  const { assignErrorToInput } = ROOT;

  const handleSubmit = (data: any) => {
    setSaving(true);

    doClientLogin(data)
      .then(() => {
        close();
        form.resetFields();
        navigate("/booking-detail");
      })
      .catch((e: ErrorProps) => {
        assignErrorToInput(form, e?.errors);
        setSaving(false);
      })
      .finally(() => setSaving(false));
  };

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const resetCaptcha = useCallback(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  }, [form]);

  const handleCancel = () => {
    close();
    form.resetFields();
    resetCaptcha();
    // setDisabled(true);
  };

  useEffect(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
    resetCaptcha();
  }, [form, resetCaptcha]);

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleCancel}
        title={false}
        footer={false}
        centered
        className="primaryModal loginModal"
        destroyOnClose={true}
        forceRender
      >
        <>
          <div className="loginModalWrap">
            <div className="title" data-title="Login">
              <h3 className="mainTitle">Login</h3>
            </div>
            <ul className="loginTypes">
              <li
                onClick={() => {
                  navigate("/e-booking");
                  close();
                }}
              >
                <span>
                  <EbookIcon />
                </span>
                <b>E-booking Login</b>
              </li>
              <li className="active">
                <span>
                  <ClientIcon />
                </span>
                <b>Client Login</b>
              </li>
              <li
                onClick={() => {
                  window.open("https://www.zimyo.work/", "_blank");
                  close();
                }}
              >
                <span>
                  <EmployeeIcon />
                </span>
                <b>Employee User</b>
              </li>
            </ul>
            <div className="clientLoginForm">
              <FormBox
                form={form}
                onFinish={handleSubmit}
                onChange={handleChange}
              >
                <Row gutter={30}>
                  <Col xs={{ span: 24 }} className="">
                    <div className="input_group">
                      <InputBox.Text
                        label="Username"
                        name="username"
                        required
                        rules={ClientLogin.username}
                        autoComplete="off"
                        aria-autocomplete="none"
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} className="">
                    <div className="input_group">
                      <InputBox.Password
                        label="Password"
                        name="password"
                        required
                        rules={ClientLogin.password}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} className="captchaWrap">
                    <Row>
                      <Col xs={{ span: 22 }}>
                        <div className="captchaLeft">
                          {/* <Form.Item label="Captcha"></Form.Item> */}
                          <InputBox.Text
                            label="Captcha"
                            name="captcha"
                            className="captchaCode"
                            disabled
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            // required
                            // rules={ContactRequest.captcha}
                          />
                        </div>
                      </Col>
                      <Col xs={{ span: 2 }}>
                        <Button
                          type="link"
                          className="resetCaptcha autoWidth autoHeight"
                          onClick={() => resetCaptcha()}
                        >
                          <svg className="resetCaptcha">
                            <use xlinkHref={`${SVGSprite}#reset-icon`}></use>
                          </svg>
                        </Button>
                      </Col>
                    </Row>
                    <div className="input_group">
                      <InputBox.Text
                        label="Enter Captcha"
                        name="re_captcha"
                        required
                        // onPaste={(e) => {
                        //   e.preventDefault();
                        //   return false;
                        // }}
                        rules={ContactRequest.re_captcha}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} className="btn_wrap">
                    <Button
                      className="btn btn_secondary radius50"
                      loading={saving}
                      disabled={disabled}
                      size="large"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </FormBox>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
});

export default Login;
