import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
// import { useLocation } from "react-router";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { randomnumbersubstring } from "../../../utils/GlobalFunction";
import SVGSprite from "../../../assets/images/icon/connect-sprite.svg";
import useStore from "../../../store";
import BookingInfo from "./component/BookingInfo";
import TravellingInfo from "./component/TravellingInfo";
import DeliveryInfo from "./component/DeliveryInfo";
import { ContactRequest } from "../../../requests/PagesRequest";
import InfoForm from "./component/InfoForm";
import BannerImg from "../../../assets/images/banner-img-04.png";
import SeoHeader from "../../../components/SeoHeader";

const TrackCourier: React.FC<any> = ({ trackingURL }: any) => {
  const [form] = Form.useForm();

  const { TRACK_COURIER_STORE } = useStore();
  const { getShipmentData, track_shipment_data, multiple_tracking } =
    TRACK_COURIER_STORE;

  const [saving, setSaving] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showShipmentData, setShowShipmentData] = useState<boolean>(false);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(true);
  const [dataFromURL, setDataFromURL] = useState<any>({});

  localStorage.setItem("tracking_otp_verification", "0");

  useEffect(() => {
    const getTrackShipmentData = localStorage.getItem("trackShipmentData");
    // Get the data from the URL query parameter
    // const urlParams = new URLSearchParams(window.location.search);
    // const serializedData = urlParams.get("data");

    if (getTrackShipmentData) {
      // Deserialize the data back into its original form
      const data = JSON.parse(getTrackShipmentData);
      // Use the data in the new tab
      setDataFromURL(data);
    }
  }, []);

  const handleSubmit = (data: any) => {
    setSaving(true);
    // getShipmentData(location.state)
    getShipmentData(dataFromURL)
      .then((data: any) => {
        form.resetFields();
        setDisabled(true);
        setShowShipmentData(true);
        setShowCaptcha(false);
        // vsmNotify.success({
        //   message: data.message,
        // });
      })
      .catch((e: any) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const resetCaptcha = () => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  };

  useEffect(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  }, [form]);

  const SeoData = {
    title:
      track_shipment_data?.seo_data?.title ??
      "Track Your Shipment at Shree Maruti Courier Pvt. Ltd.",
    description:
      track_shipment_data?.seo_data?.description ??
      "Shree Maruti provides Travel Free process for online parcel booking, same-day door-to-door delivery service, and the best home pickup courier service in India.",
    keywords:
      track_shipment_data?.seo_data?.keywords ??
      "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
    url: track_shipment_data?.seo_data?.url ?? "/join-us",
    ogTitle:
      track_shipment_data?.seo_data?.og_title ??
      "Track Your Shipment at Shree Maruti Courier Pvt. Ltd.",
    ogImage: track_shipment_data?.seo_data?.og_image,
  };

  return (
    <>
      <SeoHeader seoData={SeoData} />
      <div
        className="fullPageBG"
        style={{ backgroundImage: `url(${BannerImg})` }}
      >
        <section
          className="trackShipmentPage pv-64"
          style={{
            backgroundColor: "rgba(0,0,0,0.45)",
            padding: "200px 0 100px",
          }}
        >
          <div className="container">
            {showCaptcha && (
              <FormBox
                form={form}
                id="trackCaptchaForm"
                onFinish={handleSubmit}
                onChange={handleChange}
              >
                <div className="input_group_blk">
                  <Row gutter={0} className="captchaWrap" justify="center">
                    <Col xs={22} sm={12}>
                      <Row gutter={0} className="">
                        <Col xs={22}>
                          <div className="captchaLeft">
                            {/* <Form.Item label="Captcha"></Form.Item> */}
                            <InputBox.Text
                              label="Captcha"
                              name="captcha"
                              className="captchaCode"
                              disabled
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              // required
                              // rules={ContactRequest.captcha}
                            />
                          </div>
                        </Col>
                        <Col xs={2}>
                          <Button
                            type="link"
                            className="resetCaptcha autoWidth autoHeight"
                            onClick={() => resetCaptcha()}
                          >
                            <svg className="resetCaptcha">
                              <use xlinkHref={`${SVGSprite}#reset-icon`}></use>
                            </svg>
                          </Button>
                        </Col>
                        <Col xs={24}>
                          <div className="input_group">
                            <InputBox.Text
                              label="Enter Captcha"
                              name="re_captcha"
                              required
                              // onPaste={(e) => {
                              //   e.preventDefault();
                              //   return false;
                              // }}
                              rules={ContactRequest.re_captcha}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      className="text-center col_btn submitBtn"
                    >
                      <Button
                        className="btn btn_secondary"
                        htmlType="submit"
                        disabled={disabled}
                        loading={saving}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </FormBox>
            )}

            {showShipmentData ? (
              <>
                {multiple_tracking === true ? (
                  <BookingInfo
                    headingText="TRACKING INFORMATION"
                    multipleTracking={true}
                  />
                ) : (
                  <>
                    <BookingInfo
                      headingText="Booking Information"
                      multipleTracking={false}
                    />
                    <TravellingInfo />
                    <DeliveryInfo />
                    {track_shipment_data?.show_send_attachment_form === true ? (
                      <InfoForm />
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default TrackCourier;
