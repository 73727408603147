import React, { useState } from "react";
import { Col, Row, Form, Button } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import useStore from "../../../../store";
import {
  SendAttachmentTrackRequest,
  vsmNotify,
} from "../../../../requests/PagesRequest";
import OTPVerification from "./OTPVerification";

const InfoForm: React.FC = observer(() => {
  const [form] = Form.useForm();

  const { TRACK_COURIER_STORE, ROOT } = useStore();
  const { assignErrorToInput } = ROOT;
  const { track_shipment_data, getTrackingAttachment } = TRACK_COURIER_STORE;

  const [disabled, setDisabled] = useState<boolean>(true);
  const [disabledEmailSubmit, setDisabledEmailSubmit] = useState<boolean>(true);
  const [mobileNoVerModal, setMobileNoVerModal] = useState<boolean>(false);

  const handleSubmit = (data: any) => {
    // setSaving(true);
    data.DocumentNo = track_shipment_data.deliveryData?.DocumentNo;
    data.type = "1"; // type == 1 for Email attachment && type == 2 for Download attachment
    getTrackingAttachment(data)
      .then((data: any) => {
        form.resetFields();
      })
      .catch((e: any) => {
        if (e.data.errors) {
          // form.setFields(e.errors);
          assignErrorToInput(form, e?.data?.errors);
        }
      });
    // .finally(() => setSaving(false));
  };

  const getDownloadPODReq = () => {
    const downloadPODRequest = {
      mobile_number: localStorage.getItem("otp_verified_mobile_no"),
      DocumentNo: track_shipment_data.deliveryData?.DocumentNo,
      type: "2",
    };
    getTrackingAttachment(downloadPODRequest)
      .then((data: any) => {
        form.resetFields();
      })
      .catch((e: any) => {
        if (e.data.errors) {
          // form.setFields(e.errors);
          assignErrorToInput(form, e?.data?.errors);
        }
      });
  };

  const showModal = () => {
    setMobileNoVerModal(true);
  };

  const handleCancel = () => {
    setMobileNoVerModal(false);
    form.resetFields();
    setDisabled(true);
  };

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const verifiyEmail = () => {
    if (
      form.getFieldValue("email").trim() ===
        track_shipment_data.verificationData?.sender_email ||
      form.getFieldValue("email").trim() ===
        track_shipment_data.verificationData?.receiver_email
    ) {
      setDisabledEmailSubmit(false);
      // message.success("Email ID Matched");
      vsmNotify.success({ message: "Email ID Matched" });
    } else {
      setDisabledEmailSubmit(true);
      // message.error("Doesn't match Email ID");
      vsmNotify.error({ message: "Doesn't match Email ID" });
    }
  };

  return (
    <>
      <div className="attachFormWrap">
        <div className="attachementLink">
          <p>
            Send Attachments
            {localStorage.getItem("tracking_otp_verification") === "1" ? (
              <span className="link" onClick={getDownloadPODReq}>
                Download POD
              </span>
            ) : (
              <span className="link" onClick={showModal}>
                Download POD
              </span>
            )}
          </p>
        </div>
        <div className="trackInfoForm">
          <FormBox
            form={form}
            id="contactForm"
            onFinish={handleSubmit}
            onChange={handleChange}
          >
            <Row gutter={30} className="input_group_blk">
              <Col xs={{ span: 24 }}>
                <div className="input_group">
                  <InputBox.Text
                    label="Enter Your Name"
                    name="name"
                    rules={SendAttachmentTrackRequest.name}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }}>
                <div className="input_group">
                  <InputBox.Text
                    label="Enter Email ID given at the time of booking"
                    name="email"
                    onBlur={() => verifiyEmail()}
                    rules={SendAttachmentTrackRequest.email}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} className="text-right col_btn submitBtn">
                <Button
                  className="btn btn_secondary"
                  htmlType="submit"
                  disabled={disabled || disabledEmailSubmit}
                  // loading={saving}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </FormBox>
        </div>
      </div>

      <OTPVerification visible={mobileNoVerModal} close={handleCancel} />
    </>
  );
});

export default InfoForm;
