import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
// import SVGSprite from "../../../assets/images/icon/connect-sprite.svg";
import MainMenuComponent from "./MainMenuComponent";
import useStore from "../../../store";
import { ReactComponent as LoginIcon } from "../../../assets/images/icon/profile-picture.svg";
import Login from "../../app_pages/auth/Login";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";

const HeaderMenuComponent: React.FC = observer(() => {
  const { AUTH } = useStore();
  const navigate = useNavigate();
  const { headerData, token, loginType, setToken } = AUTH;
  const checkedLogin = localStorage.getItem("token");

  const checkedLoginType = localStorage.getItem("login_type");

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openLogin, setOpenLogin] = useState<boolean>(false);

  const [openKeys, setOpenKeys] = useState<any>([]);

  const closeMenu = () => {
    setOpenMenu(false);
    setOpenKeys([]);
  };

  const showLoginModal = () => {
    setOpenLogin(true);
  };

  const handleCancel = () => {
    setOpenLogin(false);
    // form.resetFields();
    // setDisabled(true);
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    navigate("/");
  };

  useEffect(() => {
    // if (!token) {
    //   setIsLogin(false);
    // }

    if (checkedLogin && token && checkedLoginType && loginType) {
      setIsLogin(true);
    } else if (!checkedLogin && !token && !checkedLoginType && !loginType) {
      setIsLogin(false);
    } else if (!loginType && !token) {
      setIsLogin(false);
    } else {
      setIsLogin(false);
    }

    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
      setIsLogin(true);
    } else {
      setToken(null);
      setIsLogin(false);
    }
  }, [token, setToken, loginType, checkedLogin, checkedLoginType]);

  const ebookingMenuItems = [
    { key: "Logout", label: <span onClick={logout}>Logout</span> },
  ];
  const menuItems = [
    { key: "Account", label: <Link to="/booking-detail">My Account</Link> },
    { key: "Logout", label: <span onClick={logout}>Logout</span> },
  ];

  // let isLoginItems: JSX.Element | null = null;

  const ebookingMenu = (
    <Menu className="isLoginItems" items={ebookingMenuItems}></Menu>
  );
  const clientMenu = <Menu className="isLoginItems" items={menuItems}></Menu>;
  // if (checkedLoginType === "ebooking") {
  //   let isLoginItems = (
  //     <Menu className="isLoginItems" items={ebookingMenuItems}></Menu>
  //   );
  // } else if (checkedLoginType === "client") {
  //   let isLoginItems = <Menu className="isLoginItems" items={menuItems}></Menu>;
  // }

  // useEffect(() => {

  // }, [isLoginItems, checkedLoginType]);

  return headerData ? (
    <>
      <div className="contact_wrap">
        <ul>
          {headerData.helpline_no && (
            <li>
              <span className="icon">
                <svg
                  className="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 512 512"
                >
                  {/* <use xlinkHref={`${SVGSprite}#phone-white`}></use> */}
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
                </svg>
              </span>
              <a href={`tel:${headerData.helpline_no}`} className="link">
                {headerData.helpline_no}
              </a>
            </li>
          )}
          {headerData.customercare_email && (
            <li>
              <span className="icon">
                <svg
                  className="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 512 512"
                >
                  {/* <use xlinkHref={`${SVGSprite}#email-white`}></use> */}
                  <path d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z"></path>
                </svg>
              </span>
              <a
                href={`mailto:${headerData.customercare_email}`}
                className="link"
              >
                {headerData.customercare_email}
              </a>
            </li>
          )}
        </ul>
        <div className="menu_icon">
          <div
            className="menu_click"
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <svg
              className="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000"
              viewBox="0 0 38 19"
            >
              {/* <use xlinkHref={`${SVGSprite}#menu-icon`}></use> */}
              <path d="m1.5 0h15c0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5h-15c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5z" />
              <path d="m21.5 16h15c0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5h-15c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5z" />
              <path d="m1.5 8h35c0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5h-35c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5z" />
            </svg>
          </div>
        </div>
        <div className="login_icon">
          <div className={`login_click ${openLogin && "active"}`}>
            {isLogin ? (
              <Dropdown
                overlayClassName="primaryDropdown"
                overlay={
                  checkedLoginType === "ebooking"
                    ? ebookingMenu
                    : checkedLoginType === "client"
                    ? clientMenu
                    : ebookingMenu
                }
                placement="bottomRight"
                trigger={["click"]}
              >
                <LoginIcon />
              </Dropdown>
            ) : (
              <span onClick={showLoginModal}>Login</span>
            )}
          </div>
        </div>
      </div>

      <div className={`menu_wrapper ${openMenu ? "open_menu" : ""}`}>
        <MainMenuComponent
          close={closeMenu}
          openKeys={openKeys}
          setOpenKeys={setOpenKeys}
        />
        <div className="close_menu">
          <svg
            className="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            onClick={() => {
              setOpenMenu(false);
              setOpenKeys([]);
            }}
          >
            {/* <use xlinkHref={`${SVGSprite}#close-icon`}></use> */}
            <circle
              cx="128"
              cy="128"
              r="96"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
            />
            <line
              x1="160"
              x2="96"
              y1="96"
              y2="160"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
            />
            <line
              x1="160"
              x2="96"
              y1="160"
              y2="96"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
            />
          </svg>
        </div>
      </div>

      <Login visible={openLogin} close={handleCancel} />
    </>
  ) : (
    <></>
  );
});

export default HeaderMenuComponent;
