import React, { useState } from "react";
import { Tooltip } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import TooltipIcon from "../../../../assets/images/icon/info.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import OTPVerification from "./OTPVerification";

const DeliveryInfo: React.FC = observer(() => {
  const { TRACK_COURIER_STORE } = useStore();
  const { track_shipment_data } = TRACK_COURIER_STORE;

  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mobileNoVerModal, setMobileNoVerModal] = useState<boolean>(false);

  const showModal = () => {
    setMobileNoVerModal(true);
  };

  const handleCancel = () => {
    setMobileNoVerModal(false);
  };

  const bulkImage =
    track_shipment_data.attachments.BulkImages.length !== 0
      ? track_shipment_data.attachments.BulkImages
      : null;
  const drsImage =
    track_shipment_data.attachments.DrsScanImages.length !== 0
      ? track_shipment_data.attachments.DrsScanImages
      : null;
  const signImage =
    track_shipment_data.attachments.SignatureImages.length !== 0
      ? track_shipment_data.attachments.SignatureImages
      : null;

  const attachImages = bulkImage || drsImage || signImage;

  const deliveryStatus =
    track_shipment_data.deliveryData.Status === "DELIVERED" ||
    track_shipment_data.deliveryData.Status === "RTO DELIVERED" ||
    track_shipment_data.deliveryData.Status === "RETURN DELIVERED";

  return (
    <>
      <div className="trackingInfoBlock">
        <div className="sc_title text-center">
          <div className="main_title mb-2" data-title="Delivery Information">
            <h3>Delivery Information</h3>
          </div>
        </div>

        <div className="infoTableWrap customTableWrap">
          {track_shipment_data.deliveryData?.length !== 0 ? (
            <div className="bookingReportWrap">
              <table className="tracking">
                <thead>
                  <tr>
                    <th>DELIVERY DATE</th>
                    <th>DOCUMENT NO</th>
                    <th>FROM CENTER</th>
                    <th>TO CENTER</th>
                    <th>Receiver Name</th>
                    <th>REASON</th>
                    <th>STATUS</th>
                    <th>POD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Delivery Date">
                      {track_shipment_data.deliveryData.StatusDateTime ?? "-"}
                    </td>
                    <td data-label="Document No">
                      {track_shipment_data.deliveryData.DocumentNo ?? "-"}
                    </td>
                    <td data-label="From Center">
                      {track_shipment_data.deliveryData.FromCenter?.title ??
                        "-"}{" "}
                      {track_shipment_data.deliveryData.FromCenter
                        ?.tool_tip && (
                        <Tooltip
                          placement="leftTop"
                          title={() => (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  track_shipment_data.deliveryData.FromCenter
                                    ?.tool_tip,
                              }}
                            />
                          )}
                        >
                          <img
                            className="tooltipIcon"
                            src={TooltipIcon}
                            alt="ToolTip"
                          />
                        </Tooltip>
                      )}
                    </td>
                    <td data-label="To Center">
                      {track_shipment_data.deliveryData.ToCenter?.title ?? "-"}{" "}
                      {track_shipment_data.deliveryData.ToCenter?.tool_tip && (
                        <Tooltip
                          placement="leftTop"
                          title={() => (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  track_shipment_data.deliveryData.ToCenter
                                    ?.tool_tip,
                              }}
                            />
                          )}
                        >
                          <img
                            className="tooltipIcon"
                            src={TooltipIcon}
                            alt="ToolTip"
                          />
                        </Tooltip>
                      )}
                    </td>
                    <td data-label="Receiver Name">
                      {track_shipment_data.deliveryData.ReceiverName ?? "-"}
                    </td>
                    <td data-label="Reason">
                      {track_shipment_data.deliveryData.Reason ?? "-"}
                    </td>
                    <td data-label="Status">
                      {track_shipment_data.deliveryData.Status ?? "-"}
                    </td>
                    <td data-label="Pod">
                      {deliveryStatus && attachImages ? (
                        localStorage.getItem("tracking_otp_verification") ===
                        "1" ? (
                          attachImages ? (
                            <button
                              className="viewFileBtn"
                              onClick={() => setIsOpen(true)}
                            >
                              View File
                            </button>
                          ) : (
                            "-"
                          )
                        ) : (
                          <button className="viewFileBtn" onClick={showModal}>
                            View File
                          </button>
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <p className="noData">{track_shipment_data.deliveryDataMsg}</p>
          )}
        </div>
      </div>

      {localStorage.getItem("tracking_otp_verification") === "1" && isOpen ? (
        <Lightbox
          mainSrc={attachImages[photoIndex]}
          nextSrc={attachImages[(photoIndex + 1) % attachImages.length]}
          prevSrc={
            attachImages[
              (photoIndex + attachImages.length - 1) % attachImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + attachImages.length - 1) % attachImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % attachImages.length)
          }
        />
      ) : null}

      <OTPVerification visible={mobileNoVerModal} close={handleCancel} />
    </>
  );
});

export default DeliveryInfo;
