import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class NewsletterStore {
  public newsletterData: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public newsletterAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.NEWSLETTER).then(({ data }) => {
      this.newsletterData = data.data;
    });
  };
}
