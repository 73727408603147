import SVGSprite from "../../../../assets/images/icon/eBook-sprite.svg";
import { ReactComponent as BaggageIcon } from "../../../../assets/images/icon/baggage.svg";

export const SelectBagTitle = (
  <>
    <div className="icon">
      <svg className="rightTic">
        <use xlinkHref={`${SVGSprite}#right-tic`}></use>
      </svg>
      {/* <svg className="img">
        <use xlinkHref={`${SVGSprite}#eBokStep-01`}></use>
      </svg> */}
      <BaggageIcon className="img" />
      <svg className="arrow">
        <use xlinkHref={`${SVGSprite}#eBokStep-arrow`}></use>
      </svg>
    </div>
    <div className="title">
      <p>Select Bags</p>
    </div>
  </>
);

export const PickupDeliveryTitle = (
  <>
    <div className="icon">
      <svg className="rightTic">
        <use xlinkHref={`${SVGSprite}#right-tic`}></use>
      </svg>
      <svg className="img">
        <use xlinkHref={`${SVGSprite}#eBokStep-02`}></use>
      </svg>
      <svg className="arrow">
        <use xlinkHref={`${SVGSprite}#eBokStep-arrow`}></use>
      </svg>
    </div>
    <div className="title">
      <p>Pick-up & Delivery</p>
    </div>
  </>
);

export const ScheduleLocationTitle = (
  <>
    <div className="icon">
      <svg className="rightTic">
        <use xlinkHref={`${SVGSprite}#right-tic`}></use>
      </svg>
      <svg className="img">
        <use xlinkHref={`${SVGSprite}#eBokStep-03`}></use>
      </svg>
      <svg className="arrow">
        <use xlinkHref={`${SVGSprite}#eBokStep-arrow`}></use>
      </svg>
    </div>
    <div className="title">
      <p>Schedule & Location</p>
    </div>
  </>
);

export const SummaryTitle = (
  <>
    <div className="icon">
      <svg className="rightTic">
        <use xlinkHref={`${SVGSprite}#right-tic`}></use>
      </svg>
      <svg className="img">
        <use xlinkHref={`${SVGSprite}#eBokStep-04`}></use>
      </svg>
      {/* <svg className="arrow">
        <use xlinkHref={`${SVGSprite}#eBokStep-arrow`}></use>
      </svg> */}
    </div>
    <div className="title">
      <p>Summary</p>
    </div>
  </>
);
