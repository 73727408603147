import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import Router from "./config/RouterConfig";
import useStore from "./store";
import ScrollToTop from "./utils/ScrollToTop";
import { HelmetProvider } from "react-helmet-async";
import SeoHeader from "./components/SeoHeader";

const App: React.FC = observer(() => {
  const { AUTH } = useStore();
  const { InitializeApp, app_loading } = AUTH;

  useEffect(() => {
    InitializeApp();
  }, [InitializeApp]);

  const SeoData = {
    title:
      "India's fastest express delivery, supply chain, and logistics provider",
    description:
      "Shree Maruti is leading in Asia pacific for logistic and courier delivery services, integrated express package distribution, and supply chain services.",
    keywords:
      "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
    url: "/",
    ogTitle:
      "India's fastest express delivery, supply chain, and logistics provider",
  };

  return (
    <>
      <BrowserRouter>
        <HelmetProvider>
          <SeoHeader seoData={SeoData} />
          <ScrollToTop />
          {app_loading ? <PageSpinner /> : <Router />}
        </HelmetProvider>
      </BrowserRouter>
    </>
  );
});

export default App;
