// import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../assets/images/banner-img-01.jpg";
import SVGSprite from "../../assets/images/icon/connect-sprite.svg";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
interface PageBannerProps {
  backgroundImg?: string;
  heading1?: string;
  heading2?: string;
  heading3?: string;
  buttonTitle?: string;
  buttonLink: string;
  breadcrumbData?: any;
}

const PageBanner: React.FC<PageBannerProps> = ({
  backgroundImg,
  heading1,
  heading2,
  heading3,
  buttonTitle,
  buttonLink,
  breadcrumbData,
}) => {
  return (
    <>
      <section className="banner pageBanner">
        <div className="item_wrap">
          <img
            src={backgroundImg ? backgroundImg : BannerImage}
            className="pageBannerImg"
            alt="Banner"
          />
          <div className="container">
            <div className="banner_content">
              <h1>
                {heading1}
                <strong>{heading2}</strong>
              </h1>
              <p>{heading3}</p>
              {buttonTitle ? (
                <Link to={buttonLink} className="link">
                  {buttonTitle}
                  <span className="arrow">
                    <svg className="img">
                      <use xlinkHref={`${SVGSprite}#arrow-left`}></use>
                    </svg>
                  </span>
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="container_sm">
          <BreadcrumbComponent items={breadcrumbData} />
        </div>
      </section>
    </>
  );
};

export default PageBanner;
