// import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Row } from "antd";
import { observer } from "mobx-react";
// import SVGSprite from "../../../../assets/images/icon/eBook-sprite.svg";
import useStore from "../../../../store";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { TravelfreeFormRequest } from "../../../../requests/PagesRequest";

import { ReactComponent as EditIcon } from "../../../../assets/images/icon/edit-box.svg";
import { ReactComponent as DotIcon } from "../../../../assets/images/icon/12dot.svg";
import { ReactComponent as DeliveryBoyIcon } from "../../../../assets/images/icon/delivery-boy-icon.svg";
import SeoHeader from "../../../../components/SeoHeader";

const Summary: React.FC<any> = observer(({ setCurrent, current }: any) => {
  const [form] = Form.useForm();
  const { ROOT, TRAVELFREESTORE } = useStore();
  const { assignErrorToInput } = ROOT;
  const { travelfree_form_data, fetchPaymentInfo, payment_info, saveAsDraft } =
    TRAVELFREESTORE;

  const [showGST, setShowGST] = useState(false);
  const [disabledGST, setDisabledGST] = useState(true);
  const [showIGST, setShowIGST] = useState(false);
  const [disabledPayment, setDisabledPayment] = useState(false);

  const handleSubmit = () => {
    const data = {
      pickup_pincode: JSON.parse(
        localStorage.getItem("travelfree_pickup_pin") ?? ""
      ).pincode,
      delivery_pincode: JSON.parse(
        localStorage.getItem("travelfree_delivery_pin") ?? ""
      ).pincode,
      gst_req: showIGST ? 1 : 0,
      gst_number: showIGST ? form.getFieldValue("gst_number") : "",
      gst_name: showIGST ? form.getFieldValue("gst_name") : "",
      pick_up_charge: payment_info?.pick_up_charge ?? "0.00",
      pickup_mobile: JSON.parse(localStorage.getItem("user_mobile_no") ?? ""),
      cgst_amount: showIGST ? 0 : payment_info?.cgst_charges,
      sgst_amount: showIGST ? 0 : payment_info?.sgst_charges,
      igst_amount: showIGST ? payment_info?.igst_charges : 0,
      price: TRAVELFREESTORE.travelfree_form_data?.totalPrice ?? 0,
      // products: TRAVELFREESTORE.travelfree_form_data?.items ?? {},
      products: TRAVELFREESTORE.travelfree_form_data?.items?.filter(
        (product: any) => product.quantity !== 0
      ),
    };

    TRAVELFREESTORE.travelfree_form_data = {
      ...TRAVELFREESTORE.travelfree_form_data,
      ...data,
    };
    localStorage.setItem(
      "travelfree-form",
      JSON.stringify(TRAVELFREESTORE.travelfree_form_data)
    );

    saveAsDraft(TRAVELFREESTORE.travelfree_form_data)
      .then((data: any) => {
        var form = document.createElement("form");
        var key = document.createElement("input");
        var hash = document.createElement("input");
        var txnid = document.createElement("input");
        var amount = document.createElement("input");
        var firstname = document.createElement("input");
        var email = document.createElement("input");
        var phone = document.createElement("input");
        var productinfo = document.createElement("input");
        var surl = document.createElement("input");
        var furl = document.createElement("input");
        var udf1 = document.createElement("input");

        form.method = "POST";
        form.action =
          data.data.payu_mode === "local"
            ? "https://test.payu.in/_payment"
            : "https://secure.payu.in/_payment";

        key.value = data.data.payu_mode === "local" ? "gtKFFx" : "uc73yu";
        key.name = "key";
        form.appendChild(key);

        hash.value = data.data.hash;
        hash.name = "hash";
        form.appendChild(hash);

        txnid.value = data.data.txnid;
        txnid.name = "txnid";
        form.appendChild(txnid);

        amount.value = data.data.amount;
        amount.name = "amount";
        form.appendChild(amount);

        firstname.value = TRAVELFREESTORE.travelfree_form_data.pickup_name;
        firstname.name = "firstname";
        form.appendChild(firstname);

        email.value = data.data.email;
        email.name = "email";
        form.appendChild(email);

        phone.value = data.data.phone;
        phone.name = "phone";
        form.appendChild(phone);

        productinfo.value = data.data.productinfo;
        productinfo.name = "productinfo";
        form.appendChild(productinfo);

        surl.value = data.data.surl;
        surl.name = "surl";
        form.appendChild(surl);

        furl.value = data.data.furl;
        furl.name = "furl";
        form.appendChild(furl);

        udf1.value = data.data.udf1;
        udf1.name = "udf1";
        form.appendChild(udf1);

        if (data.data.address1 !== "") {
          var address1 = document.createElement("input");
          address1.value = data.data.address1;
          address1.name = "address1";
          form.appendChild(address1);
        }

        if (data.data.city !== "") {
          var city = document.createElement("input");
          city.value = data.data.city;
          city.name = "city";
          form.appendChild(city);
        }

        if (data.data.zipcode !== "") {
          var zipcode = document.createElement("input");
          zipcode.value = data.data.zipcode;
          zipcode.name = "zipcode";
          form.appendChild(zipcode);
        }

        if (data.data.state !== "") {
          var state = document.createElement("input");
          state.value = data.data.state;
          state.name = "state";
          form.appendChild(state);
        }

        if (data.data.country !== "") {
          var country = document.createElement("input");
          country.value = data.data.country;
          country.name = "country";
          form.appendChild(country);
        }

        if (data.data.lastname !== "") {
          var lastname = document.createElement("input");
          lastname.value = data.data.lastname;
          lastname.name = "lastname";
          form.appendChild(lastname);
        }

        if (data.data.udf2 !== "") {
          var udf2 = document.createElement("input");
          udf2.value = data.data.udf2;
          udf2.name = "udf2";
          form.appendChild(udf2);
        }

        if (data.data.udf3 !== "") {
          var udf3 = document.createElement("input");
          udf3.value = data.data.udf3;
          udf3.name = "udf3";
          form.appendChild(udf3);
        }

        if (data.data.udf4 !== "") {
          var udf4 = document.createElement("input");
          udf4.value = data.data.udf4;
          udf4.name = "udf4";
          form.appendChild(udf4);
        }

        if (data.data.udf5 !== "") {
          var udf5 = document.createElement("input");
          udf5.value = data.data.udf5;
          udf5.name = "udf5";
          form.appendChild(udf5);
        }

        if (data.data.api_version !== "") {
          var api_version = document.createElement("input");
          api_version.value = data.data.api_version;
          api_version.name = "api_version";
          form.appendChild(api_version);
        }

        document.body.appendChild(form);

        form.submit();
      })
      .catch((e: any) => {
        if (e) {
          // form.setFields(e.errors);
          assignErrorToInput(form, e);
        }
      });
  };

  const handleGSTChange = (e: any) => {
    if (e.target.checked) {
      setShowGST(true);
      setDisabledPayment(true);
    } else {
      setShowGST(false);
      setShowIGST(false);
      setDisabledPayment(false);
    }
  };

  const handleGSTSubmit = () => {
    setShowIGST(true);
    setDisabledPayment(false);
  };

  const gstHandleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabledGST(false);
      })
      .catch((e) => {
        setDisabledGST(true);
      });
  };

  // const shipmentType: any = {
  // 	1: "Document",
  // 	2: "Parcel",
  // };

  const timeSlot: any = {
    "10-12": "10:00 AM - 12:00 PM",
    "12-02": "12:00 PM - 02:00 PM",
    "02-04": "02:00 PM - 04:00 PM",
    "04-06": "04:00 PM - 06:00 PM",
  };

  let getLocalStorageData: any | null = JSON.parse(
    localStorage.getItem("travelfree-form") as any | null
  );
  let getData: any =
    TRAVELFREESTORE.travelfree_form_data?.length > 0
      ? TRAVELFREESTORE.travelfree_form_data
      : getLocalStorageData;

  let productsData = TRAVELFREESTORE.travelfree_form_data?.items?.filter(
    (product: any) => product.quantity !== 0
  );

  useEffect(() => {
    let pick_up_charge: any | null = JSON.parse(
      localStorage.getItem("travelfree_pick_up_charge") as any | null
    );
    let getLocalData: any | null = JSON.parse(
      localStorage.getItem("travelfree-form") as any | null
    );
    let updateData: any =
      TRAVELFREESTORE.travelfree_form_data?.length > 0
        ? TRAVELFREESTORE.travelfree_form_data
        : getLocalData;

    const payload = {
      pick_up_charge: pick_up_charge ?? "0",
      pick_dropoff_type: updateData?.pick_dropoff_type,
      pickup_sp_id: updateData?.pickup_cp
        ? updateData?.pickup_cp.sp_id
        : updateData?.dropoff_cp.sp_id,
      // pick_up_charge: updateData?.pick_up_charge
      //   ? updateData?.pick_up_charge
      //   : "0.00",
      service_type_id: updateData?.service_type.service_type_id,
      travel_type_id: updateData?.service_type.travel_type_id,
      price: updateData?.price
        ? parseFloat(updateData?.price)
        : parseFloat(updateData?.totalPrice),
    };
    fetchPaymentInfo(payload);
  }, [fetchPaymentInfo, TRAVELFREESTORE]);

  const SeoData = {
    title: "Summary Details for Travel Free",
    description:
      "Shree Maruti provides Travel Free process for online parcel booking, same-day door-to-door delivery service, and the best home pickup courier service in India.",
    keywords:
      "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
    url: "/travelfree-form",
    ogTitle: "Shipment Details for Travel Free",
  };

  return travelfree_form_data ? (
    <>
      <SeoHeader seoData={SeoData} />
      <div className="summaryWrap">
        <Row gutter={30} align="top">
          <Col xs={24} lg={15}>
            <div className="mainBox">
              <div className="dataSection">
                <div className="header">
                  <strong>
                    <DotIcon /> Pick-Up and Delivery Details
                  </strong>

                  <EditIcon onClick={() => setCurrent(1)} className="icon" />
                </div>
                <div className="details">
                  <Row gutter={30} align="middle">
                    <Col xs={24} md={10} lg={9}>
                      <p>
                        <strong className="sectionTitle">
                          From: {getData?.pickup_city}
                        </strong>
                      </p>
                      <p>
                        <strong className="sectionTitle">
                          Name: {getData?.pickup_name}
                        </strong>
                      </p>
                      <address>
                        {getData?.pickup_address1}
                        <br />
                        {getData?.pickup_address2 && (
                          <>
                            {getData?.pickup_address2} <br />
                          </>
                        )}
                        {getData?.pickup_city}, {getData?.pickup_state} <br />
                        {
                          JSON.parse(
                            localStorage.getItem("travelfree_pickup_pin") ?? ""
                          ).pincode
                        }
                      </address>
                      <strong className="sectionTitle">
                        Mo.: {localStorage.getItem("user_mobile_no")}
                      </strong>
                    </Col>
                    <Col xs={24} md={4} lg={6} className="sep">
                      <div className="box">
                        <DeliveryBoyIcon />
                      </div>
                    </Col>
                    <Col xs={24} md={10} lg={9}>
                      <p>
                        <strong className="sectionTitle">
                          To: {getData?.delivery_city}
                        </strong>
                      </p>
                      <p>
                        <strong className="sectionTitle">
                          Name: {getData?.delivery_name}
                        </strong>
                      </p>
                      <address>
                        {getData?.delivery_address1}
                        <br />
                        {getData?.delivery_address2 && (
                          <>
                            {getData?.delivery_address2} <br />
                          </>
                        )}
                        {getData?.delivery_city}, {getData?.delivery_state}{" "}
                        <br />
                        {
                          JSON.parse(
                            localStorage.getItem("travelfree_delivery_pin") ??
                              ""
                          ).pincode
                        }
                      </address>
                      <strong className="sectionTitle">
                        Mo.: {getData?.delivery_mobile}
                      </strong>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="dataSection">
                <div className="header">
                  <strong>
                    <DotIcon /> Schedule & Location Details
                  </strong>

                  <EditIcon onClick={() => setCurrent(2)} className="icon" />
                </div>
                <div className="details">
                  {travelfree_form_data.pick_dropoff_type === 1 && (
                    <Row gutter={30} align="top">
                      <Col xs={24} md={14} lg={15}>
                        <p>
                          <strong className="sectionTitle">
                            CP Name: {getData?.pickup_cp.sp_name}
                          </strong>
                        </p>
                        <address>
                          {getData?.pickup_cp.address1}
                          <br />
                          {getData?.pickup_cp.address2 && (
                            <>
                              {getData?.pickup_cp.address2} <br />
                            </>
                          )}
                        </address>
                        <p>
                          <strong className="sectionTitle">
                            Email:{" "}
                            <a
                              href={`mailto:${getData?.pickup_cp.email}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {getData?.pickup_cp.email}
                            </a>
                          </strong>
                        </p>
                      </Col>
                      <Col xs={24} md={10} lg={9}>
                        <p>
                          Contact No.:
                          <br />
                          <strong className="sectionTitle">
                            {getData?.pickup_cp.contact_no}
                          </strong>
                        </p>
                        <p>
                          Pickup Date:
                          <br />
                          <strong className="sectionTitle">
                            {getData?.pick_date}
                          </strong>
                        </p>
                        <p>
                          Pickup Time Slot:
                          <br />
                          <strong className="sectionTitle">
                            {timeSlot[getData?.pickup_slot]}
                          </strong>
                        </p>
                      </Col>
                    </Row>
                  )}
                  {travelfree_form_data.pick_dropoff_type === 2 && (
                    <Row gutter={30} align="top">
                      <Col xs={24} md={14} lg={15}>
                        <p>
                          <strong className="sectionTitle">
                            CP Name: {getData?.dropoff_cp.sp_name}
                          </strong>
                        </p>
                        <address>
                          {getData?.dropoff_cp.address1}
                          <br />
                          {getData?.dropoff_cp.address2 && (
                            <>
                              {getData?.dropoff_cp.address2} <br />
                            </>
                          )}
                        </address>
                        <p>
                          <strong className="sectionTitle">
                            Email:{" "}
                            <a
                              href={`mailto:${getData?.dropoff_cp.email}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {getData?.dropoff_cp.email}
                            </a>
                          </strong>
                        </p>
                      </Col>
                      <Col xs={24} md={10} lg={9}>
                        <p>
                          Contact No.:
                          <br />
                          <strong className="sectionTitle">
                            {getData?.dropoff_cp.contact_no}
                          </strong>
                        </p>
                        <p>
                          <br />
                          <strong className="sectionTitle">Dropoff</strong>
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>

              <div className="dataSection">
                <div className="header">
                  <strong>
                    <DotIcon /> Order Details
                  </strong>

                  <EditIcon onClick={() => setCurrent(0)} className="icon" />
                </div>
                <div className="details">
                  <table className="responsive orderDetails">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsData?.length > 0
                        ? productsData?.map((item: any, index: any) => {
                            let bagPrice =
                              getData?.service_type_name === "economy"
                                ? item.price_economy
                                : item.price_express;
                            return (
                              <tr key={index}>
                                <td data-label="Item">{item.title}</td>
                                <td data-label="Price">{bagPrice}</td>
                                <td data-label="Qty">{item.quantity}</td>
                                <td data-label="Total Price">
                                  {item.quantity * bagPrice}.00
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={9}>
            <div className="mainBox">
              <div className="paymentSection">
                <h4 className="title">Payment Summary</h4>
                <ul className="priceSummary">
                  <li>
                    <span>Booking Amount</span>
                    <span>{payment_info?.booking_amount}</span>
                  </li>
                  <li>
                    <span>Pickup Charges</span>
                    <span>{payment_info?.pick_up_charge ?? "0.00"}</span>
                  </li>
                  {!showIGST && (
                    <>
                      <li>
                        <span>SGST (9%)</span>
                        <span>{payment_info?.sgst_charges}</span>
                      </li>
                      <li>
                        <span>CGST (9%)</span>
                        <span>{payment_info?.cgst_charges}</span>
                      </li>
                    </>
                  )}
                  {showIGST && (
                    <li>
                      <span>IGST Charges</span>
                      <span>{payment_info?.igst_charges}</span>
                    </li>
                  )}
                  <li>
                    <span>Amount to be paid</span>
                    <span>{payment_info?.amount_to_be_paid}*</span>
                  </li>
                </ul>

                <p className="note">
                  *The rates Indicated are dependent on the actual weight and
                  the final price will be determined in the shree Maruti Courier
                  outler In the case of scheduled pickup.
                </p>
              </div>
            </div>
            <div className="gstForm">
              <Form.Item name="gst_invoce">
                <Checkbox onChange={(e) => handleGSTChange(e)}>
                  Request GST Tax Invoice?
                </Checkbox>
              </Form.Item>
              {showGST && (
                <FormBox
                  form={form}
                  className="gst_form"
                  id="gstForm"
                  onFinish={handleGSTSubmit}
                  onChange={gstHandleChange}
                >
                  <div className="input_group">
                    <InputBox.Text
                      placeholder="Name *"
                      name="gst_name"
                      required
                      rules={TravelfreeFormRequest.gst_name}
                    />
                  </div>
                  <div className="input_group">
                    <InputBox.Text
                      placeholder="GST Number *"
                      name="gst_number"
                      required
                      rules={TravelfreeFormRequest.gst_number}
                    />
                  </div>
                  <div className="input_group">
                    <Button
                      type="default"
                      id="gstForm"
                      htmlType="submit"
                      disabled={disabledGST}
                    >
                      Apply
                    </Button>
                  </div>
                </FormBox>
              )}
            </div>
          </Col>
        </Row>

        <div className="text-center">
          <Button
            type="primary"
            // className="btn btn_secondary"
            onClick={handleSubmit}
            // htmlType="submit"
            disabled={disabledPayment}
          >
            Proceed Payment
          </Button>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});

export default Summary;
