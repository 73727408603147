import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Row, Spin } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import {
  CareerRequest,
  ContactRequest,
} from "../../../../requests/PagesRequest";
import { randomnumbersubstring } from "../../../../utils/GlobalFunction";
import SVGSprite from "../../../../assets/images/icon/connect-sprite.svg";
import useStore from "../../../../store";

const StoragePartnerForm: React.FC = observer(() => {
  const [form] = Form.useForm();
  const { ROOT, JOINUSSTORE } = useStore();
  const [saving, setSaving] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [fetchState, setFetchState] = useState<boolean>(true);

  const getStateID = (stateName: string) => {
    const state = JOINUSSTORE?.dropdown_state_list?.find(
      (state: any) => state.StateName === stateName
    );
    return state ? state.StateID : null;
  };

  const handleStateChange = (stateName: any) => {
    form.setFieldsValue({ city: null, pincode: null });
    const stateID = getStateID(stateName);
    if (stateID && stateID !== undefined) {
      const data = { state_id: parseInt(stateID) };
      JOINUSSTORE.getCityList(data);
      JOINUSSTORE.getStatePincodeList(stateID);
    }
  };

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const handleSubmit = (data: any) => {
    setSaving(true);
    data.form_type = "storage";
    JOINUSSTORE.submitDeliveryPartnerForm(data)
      .then(() => {
        setSaving(false);
        form.resetFields();
        resetCaptcha();
      })
      .catch((e: any) => {
        if (e.data.errors) {
          // form.setFields(e.errors);
          ROOT.assignErrorToInput(form, e?.data?.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const resetCaptcha = () => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  };

  useEffect(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  }, [form]);

  return (
    <section className="career_section pv-48">
      <div className="container_sm">
        <div className="sc_title text-center">
          <div className="main_title" data-title="Storage Partner">
            <h3>Storage Partner</h3>
          </div>
        </div>

        <div className="input_group_blk mt-48">
          <FormBox
            form={form}
            id="StoragePartnerForm"
            onFinish={handleSubmit}
            onChange={handleChange}
          >
            <Row gutter={48}>
              <Col xs={24} md={12} lg={8}>
                {/* <InputBox.Text hidden name="form_type" value="storage" /> */}
                <div className="input_group">
                  <InputBox.Text
                    label="Name"
                    name="name"
                    required
                    rules={CareerRequest.name}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <div className="input_group">
                  <InputBox.Text
                    label="Phone/Mobile"
                    name="contact_number"
                    required
                    rules={CareerRequest.phone}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <div className="input_group">
                  <InputBox.Text
                    label="Address"
                    name="address"
                    required
                    rules={CareerRequest.address}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <div className="input_group">
                  <InputBox.Select
                    label="State"
                    name="state"
                    required
                    rules={CareerRequest.state}
                    showSearch
                    onFocus={() =>
                      fetchState &&
                      JOINUSSTORE.getStateList().then(() =>
                        setFetchState(false)
                      )
                    }
                    notFoundContent={
                      fetchState ? <Spin size="small" /> : "NO Record Found."
                    }
                    onChange={(e) => handleStateChange(e)}
                    options={{
                      list: JOINUSSTORE.dropdown_state_list,
                      valueKey: "StateName",
                      textKey: "StateName",
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={8}>
                {/* <div className="input_group">
                  <InputBox.Select
                    label="City"
                    name="city"
                    required
                    showSearch
                    rules={CareerRequest.city}
                    notFoundContent={
                      JOINUSSTORE?.dropdown_city_list ? (
                        <Spin size="small" />
                      ) : (
                        "NO Record Found."
                      )
                    }
                    options={{
                      list: JOINUSSTORE?.dropdown_city_list,
                      valueKey: "CityName",
                      textKey: "CityName",
                    }}
                  />
                </div> */}

                <div className="input_group">
                  <InputBox.Text
                    label="City"
                    name="city"
                    required
                    rules={CareerRequest.officeCity}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <div className="input_group">
                  <InputBox.Text
                    label="Pincode"
                    name="pincode"
                    maxLength={6}
                    required
                    rules={CareerRequest.pincode}
                  />
                </div>
                {/* <div className="input_group">
                  <InputBox.Select
                    label="Pincode"
                    name="pincode"
                    required
                    showSearch
                    rules={CareerRequest.pincode}
                    notFoundContent={
                      JOINUSSTORE?.dropdown_pincode_list ? (
                        <Spin size="small" />
                      ) : (
                        "NO Record Found."
                      )
                    }
                    options={{
                      list: JOINUSSTORE?.dropdown_pincode_list,
                      valueKey: "value",
                      textKey: "text",
                    }}
                  />
                </div> */}
              </Col>
              <Col xs={24} md={12} lg={8}>
                <div className="input_group">
                  <InputBox.Text
                    label="Total Area in Sqft"
                    name="area_in_sqft"
                    required
                    rules={CareerRequest.total_area}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <div className="input_group">
                  <InputBox.Text
                    label="Address of storage area"
                    name="storage_area_address"
                    required
                    rules={CareerRequest.address}
                  />
                </div>
              </Col>
              <Col xs={0} md={0} lg={8}>
                &nbsp;
              </Col>
              <Col xs={24} md={12} lg={10}>
                <div className="input_group">
                  <InputBox.Radio
                    label="Previous experience in storage and Fulfillment?"
                    name="experience"
                    required
                    rules={CareerRequest.selection}
                    options={{
                      list: [
                        { id: "YES", value: "YES" },
                        { id: "NO", value: "NO" },
                      ],
                      valueKey: "id",
                      textKey: "value",
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={7}>
                <div className="input_group">
                  <InputBox.Radio
                    label="Storage operations?"
                    name="storage_operations"
                    required
                    rules={CareerRequest.selection}
                    options={{
                      list: [
                        { id: "YES", value: "YES" },
                        { id: "NO", value: "NO" },
                      ],
                      valueKey: "id",
                      textKey: "value",
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} md={12} lg={7}>
                <div className="input_group">
                  <InputBox.Radio
                    label="Owner of the Property?"
                    name="property_owner"
                    required
                    rules={CareerRequest.selection}
                    options={{
                      list: [
                        { id: "YES", value: "YES" },
                        { id: "NO", value: "NO" },
                      ],
                      valueKey: "id",
                      textKey: "value",
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} md={12} lg={8} className="captchaWrap">
                <Row>
                  <Col xs={22}>
                    <div className="captchaLeft">
                      <InputBox.Text
                        label="Captcha"
                        name="captcha"
                        className="captchaCode"
                        disabled
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={2}>
                    <Button
                      type="link"
                      className="resetCaptcha autoWidth autoHeight"
                      onClick={() => resetCaptcha()}
                    >
                      <svg className="resetCaptcha">
                        <use xlinkHref={`${SVGSprite}#reset-icon`}></use>
                      </svg>
                    </Button>
                  </Col>
                  <Col xs={24}>
                    <div className="input_group">
                      <InputBox.Text
                        label="Enter Captcha"
                        name="re_captcha"
                        required
                        // onPaste={(e) => {
                        //   e.preventDefault();
                        //   return false;
                        // }}
                        rules={ContactRequest.re_captcha}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                  loading={saving}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </FormBox>
        </div>
      </div>
    </section>
  );
});

export default StoragePartnerForm;
