const API_URL = {
  // LOGIN_WITH_EMAIL: "auth/login/",
  // REGISTER_WITH_EMAIL: "register",
  // ME: "me",
  // LOGOUT: "logout",
  // VERIFY_ACCOUNT: (verification_code: string): string =>
  //   "verify-email/" + verification_code,
  // RESEND_VERIFICATION_LINK: (email: string): string =>
  //   "resend-verification-code/" + btoa(email),
  // USER: {
  //   LIST: "user/list",
  // },
  // All_TRANSACTION: {
  //   LIST: "trade/transactions/list",
  // },

  // Common API url
  HEADER: "init/header/",
  FOOTER: "init/footer/",
  SEND_OTP: "common/send_otp/",
  VERIFY_OTP: "common/verify_otp/",
  STATE_LOV: "lov/states/",
  CITY_LOV: "lov/cities/",
  PINCODE_BY_STATE_LOV: "lov/getpincode_by_state/?state_id=",
  SEND_ATTACHMENT: "tracking/send_attachments/",
  PRINT_BOOKING_ORDER: "common/print-booking-order/",
  PRINT_LABEL: "common/print-label/",

  NETWORK: {
    NETWORK_PAGE: "pages/network/",
    NETWORK_SEARCH: "lov/network-search/",
    NETWORK_SEARCH_DETAIL: "lov/network-search-detail/",
    // GET_ALL_PIN: "lov/get_all_pin/",
    // GET_ALL_CENTER: "lov/get_center/",
    // GET_SERVICEPINCODE: "lov/get_servicepincode/",
  },

  // Pages API url
  HOMEPAGE: "pages/home/",
  PUBLICNOTICE: "init/publicnotices/",
  CAREER: {
    CAREER_DATA: "pages/career/",
    CAREERFORM: "forms/career/",
  },
  CHANNELPARTNER: {
    CP_DATA: "pages/partner/",
    //CP_FORM: "forms/partner/",
    CP_FORM: "tracking/partner/",

  },
  DELIVERYPARTNER_FORM: "forms/delivery-partner/",

  PRIVACYPOLICY: "pages/privacypolicy/",
  INNODEL_PRIVACYPOLICY: "pages/innodel-privacy-policy/",
  DISCLAIMER: "pages/disclaimer/",
  CARRIAGE: "pages/conditionsofcarriage/",
  ONDC_DISCLOSURE: "pages/ondc-disclosure/",

  CONTACT: {
    BANNER: "pages/contactus/",
    CONTACTFORM: "tracking/contactus/",
    // CONTACTFORM: "forms/contactus/",
  },
  BOOKING_INQUIRY: {
    FROM_PINCODE: "lov/get_servicepincode/",
    TO_PINCODE: "lov/get_all_pin/",
    BOOKING_REQ_FORM: "forms/bookinginquiry/",
  },
  TRACK_SHIPMENT: {
    GET_SHIPMENT_DATA: "tracking/shipment/",
  },

  EBOOK_MOBILE_VERIFICATION: "auth/checkuserexist/",
  EBOOK_LOGIN: "auth/login/",
  EBOOK_REGISTRATION: "auth/registration/",
  CLIENT_LOGIN: "auth/client_login/",

  EBOOKING_PINCODE_VERIFICATION: "ebooking/pincode_verification/",
  EBOOK_FETCH_RATECARD: "ebooking/fetchratecard/",
  CHANNEL_PARTNER: "ebooking/get_cp_list/",
  PAYMENT_INFO: "ebooking/get_payment_information/",
  SAVE_AS_DRAFT: "ebooking/save_as_draft/",
  GET_TRANSACTION: "common/get-transaction/",

  BOOKING_DETAIL: "client/booking_detail/",
  DOWNLOAD_POD: "client/download_pod/",
  EXPORT_BOOKING_DATA: "client/export-booking-data/",
  MY_BOOKING: "client/my_booking/",
  MY_POD_DOWNLOAD: "client/download_myorder_pod/",
  PRINT_ORDER: "common/print-booking-order/",

  TRAVELFREE_SELECT_BAG: "travelfree/products",
  TRAVELFREE_CHANNEL_PARTNER: "travelfree/get_cp_list/",
  TRAVELFREE_PAYMENT_INFO: "travelfree/get_payment_information/",
  TRAVELFREE_SAVE_AS_DRAFT: "travelfree/save_as_draft/",
  TRAVELFREE_PINCODE_VERIFICATION: "travelfree/pincode_verification/",
  TRAVELFREE_FETCH_RATECARD: "travelfree/fetchratecard/",

  NEWSLETTER: "pages/newsletter/",

  EBOOKING_FORM: "pages/ebooking/",
  TRAVELFREE_FORM: "pages/travelfree/",

  PENDINGPAYMENTDETAILS: "ebooking/part-payment-pending/",
};

export default API_URL;
