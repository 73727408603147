// import React from "react";
// import { HomeOutlined } from "@ant-design/icons";
import { BreadcrumbConfigProps } from "./InterfacesAndTypes";

const defaultBreadcrumbPath = [{ name: "Home", link: "/" }];

export const ContactUsBreadcrumb: BreadcrumbConfigProps = {
  title: "Contact Us",
  path: [...defaultBreadcrumbPath, { name: "Contact Us" }],
};

export const JoinUsBreadcrumb: BreadcrumbConfigProps = {
  title: "Join Us",
  path: [...defaultBreadcrumbPath, { name: "Join Us" }],
};
export const NetworkBreadcrumb: BreadcrumbConfigProps = {
  title: "Network",
  path: [...defaultBreadcrumbPath, { name: "Network" }],
};
export const ChannelPartnerBreadcrumb: BreadcrumbConfigProps = {
  title: "Channel Partner",
  path: [...defaultBreadcrumbPath, { name: "Channel Partner" }],
};
export const DeliveryPartnerBreadcrumb: BreadcrumbConfigProps = {
  title: "Delivery Partner",
  path: [...defaultBreadcrumbPath, { name: "Delivery Partner" }],
};
export const StoragePartnerBreadcrumb: BreadcrumbConfigProps = {
  title: "Storage Partner",
  path: [...defaultBreadcrumbPath, { name: "Storage Partner" }],
};
export const NewsletterBreadcrumb: BreadcrumbConfigProps = {
  title: "Newsletter",
  path: [...defaultBreadcrumbPath, { name: "Newsletter" }],
};

export const PrivacyPolicyBreadcrumb: BreadcrumbConfigProps = {
  title: "Privacy Policy",
  path: [...defaultBreadcrumbPath, { name: "Privacy Policy" }],
};
export const InnodelPrivacyPolicyBreadcrumb: BreadcrumbConfigProps = {
  title: "Innodel Privacy Policy",
  path: [...defaultBreadcrumbPath, { name: "Innodel Privacy Policy" }],
};

export const DisclaimerBreadcrumb: BreadcrumbConfigProps = {
  title: "Disclaimer",
  path: [...defaultBreadcrumbPath, { name: "Disclaimer" }],
};

export const CarriageBreadcrumb: BreadcrumbConfigProps = {
  title: "Carriage",
  path: [...defaultBreadcrumbPath, { name: "Carriage" }],
};
export const ONDCDisclosureBreadcrumb: BreadcrumbConfigProps = {
  title: "ONDC Disclosure",
  path: [...defaultBreadcrumbPath, { name: "ONDC Disclosure" }],
};

export const UserBreadcrumb: BreadcrumbConfigProps = {
  title: "User Management",
  path: [
    ...defaultBreadcrumbPath,
    { name: "User Management", link: "/user-management" },
  ],
};

export const AllTransactionBreadcrumb: BreadcrumbConfigProps = {
  title: "All Transactions",
  path: [
    ...defaultBreadcrumbPath,
    { name: "All Transaction", link: "/all-transactions" },
  ],
};
