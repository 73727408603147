// For Random number string
export const randomnumbersubstring = () => {
  // return Math.floor(100000 + Math.random() * 900000);
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = " ";
  const charactersLength = characters.length;

  function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const rndInt = randomIntFromInterval(6, 8);

  for (let i = 0; i < rndInt; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const uploadedFileOnPreview = async (file: any) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }

  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

export const b64toBlob = (b64Data: any, contentType: any, sliceSize: any) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = Buffer.from(b64Data, "base64").toString("binary");
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, "pot", { type: contentType });
};

export const textToUrl = (str: any) => {
  if (str) {
    var strLower = str.toLowerCase();
    return strLower.replace(/\W/g, "-");
  }

  return false;
};
