import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class NetworkStore {
  public networkPageData: any;
  public pincodeList: any;
  public pincodeDetail: any;
  public centerList: any;
  public centerDetail: any;
  public centerViewType: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public networkPageAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.NETWORK.NETWORK_PAGE).then(({ data }) => {
      this.networkPageData = data.data;
    });
  };

  public networkPincodeList = async (pincode: any): Promise<any> => {
    return await axios
      // .post(API_URL.NETWORK.NETWORK_SEARCH, payload)
      .get(`${API_URL.NETWORK.NETWORK_SEARCH}?type=pincode&term=${pincode}`)
      .then(({ data }) => {
        this.pincodeList = data.data;
      });
  };
  public networkSearchPincode = async (location: any): Promise<any> => {
    const payload: any = {
      type: "pincode",
      term: location,
    };
    return await axios
      // .post(API_URL.NETWORK.NETWORK_SEARCH, payload)
      .post(`${API_URL.NETWORK.NETWORK_SEARCH_DETAIL}`, payload)
      .then(({ data }) => {
        this.pincodeDetail = data.data;
        this.centerList = null;
      });
  };

  public networkCenterList = async (location: any): Promise<any> => {
    return await axios
      // .post(API_URL.NETWORK.NETWORK_SEARCH, payload)
      .get(`${API_URL.NETWORK.NETWORK_SEARCH}?type=location&term=${location}`)
      .then(({ data }) => {
        this.centerList = data.data;
        this.pincodeDetail = null;
      });
  };
  public networkSearchCenter = async (location: any): Promise<any> => {
    const payload: any = {
      type: "location",
      term: location,
    };
    return await axios
      // .post(API_URL.NETWORK.NETWORK_SEARCH, payload)
      .post(`${API_URL.NETWORK.NETWORK_SEARCH_DETAIL}`, payload)
      .then(({ data }) => {
        this.centerDetail = data.data;
        this.centerViewType = data.view_type;
      });
  };
}
