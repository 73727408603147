import React, { useEffect, useState } from "react";
// import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import SVGSprite from "../../../assets/images/icon/connect-sprite.svg";
import LOGO_IMAGE from "../../../assets/images/logo.png";
import MenuBGImg1 from "../../../assets/images/menu-bg-01.png";
import menuBGImg from "../../../assets/images/menu-bg-02.jpg";
import logoBGImg from "../../../assets/images/logo-bg-opacity.png";
import useStore from "../../../store";
import { ReactComponent as FacbookIcon } from "../../../assets/images/icon/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/icon/twitterX.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/images/icon/instagram.svg";
import { ReactComponent as LinkedIcon } from "../../../assets/images/icon/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/images/icon/youtube.svg";
// import type { MenuProps } from "antd";
// import { toJS } from "mobx";

interface MenuViewProps {
  close: any;
  openKeys: any;
  setOpenKeys: any;
}

const MainMenuComponent: React.FC<MenuViewProps> = observer(
  ({ close, openKeys, setOpenKeys }) => {
    const location = useLocation();
    const { AUTH } = useStore();
    const { headerData, menuList } = AUTH;

    const [openChildMenu, setOpenChildMenu] = useState<number>(0);
    const [activeChildMenu, setActiveChildMenu] = useState<string>("");
    // const [current, setCurrent] = useState<any>([]);

    // const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    //   setCurrent(keys)
    //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    //   if (menu.indexOf(latestOpenKey!) === -1) {
    //     setOpenKeys(keys);
    //   } else {
    //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    //   }
    // };

    // useEffect(() => {
    //   const tempMenu = headerData.menu.map((item: any) => {
    //     const tempMainmenu = item;

    //     if (item?.children) {
    //       item.children.map((i: any) => {
    //         const tempSubmenu = i;
    //         if (i.label === "e-Booking" || i.label === "Travel Free") {
    //           tempSubmenu.label = <a href={i.key}>{i.label}</a>;
    //         } else {
    //           tempSubmenu.label = <Link to={i.key}>{i.label}</Link>;
    //         }
    //         return tempSubmenu;
    //       });
    //     } else {
    //       tempMainmenu.label = <Link to={item.key}>{item.label}</Link>;
    //     }
    //     return tempMainmenu;
    //   });
    //   setMenu(tempMenu);
    // }, [headerData]);
    useEffect(() => {
      localStorage.setItem("activeMenu", location.pathname);

      if (location.pathname === "/booking-inquiry") {
        localStorage.setItem("activeMenu", "1001");
        setActiveChildMenu("/booking-inquiry");
      } else if (location.pathname === "/ebook-login") {
        localStorage.setItem("activeMenu", "1001");
        setActiveChildMenu("/e-booking");
      } else if (location.pathname === "/e-booking") {
        localStorage.setItem("activeMenu", "1001");
        setActiveChildMenu("/e-booking");
      } else if (location.pathname === "/ebooking-form") {
        localStorage.setItem("activeMenu", "1001");
        setActiveChildMenu("/e-booking");
      } else if (location.pathname === "/travelfree") {
        localStorage.setItem("activeMenu", "1001");
        setActiveChildMenu("/travelfree");
      } else if (location.pathname === "/travelfree-form") {
        localStorage.setItem("activeMenu", "1001");
        setActiveChildMenu("/travelfree");
      } else if (location.pathname === "/join-us") {
        localStorage.setItem("activeMenu", "1002");
        setActiveChildMenu("/join-us");
      } else if (location.pathname === "/channel-partner") {
        localStorage.setItem("activeMenu", "1002");
        setActiveChildMenu("/channel-partner");
      } else if (location.pathname === "/delivery-partner") {
        localStorage.setItem("activeMenu", "1002");
        setActiveChildMenu("/delivery-partner");
      } else if (location.pathname === "/storage-partner") {
        localStorage.setItem("activeMenu", "1002");
        setActiveChildMenu("/storage-partner");
      }
    }, [location.pathname]);

    const setActivemenu = (val: any) => {
      localStorage.setItem("activeMenu", location.pathname);
      setOpenChildMenu(0);
    };

    return headerData ? (
      <>
        <div className="menu_grid">
          <div
            className="content_col"
            style={{ backgroundImage: `url(${MenuBGImg1})` }}
          >
            <div className="content_col__box1">
              <div className="header_wrap">
                <div className="logo">
                  <Link to="/">
                    <img src={LOGO_IMAGE} alt="LOGO" />
                  </Link>
                </div>
              </div>
              <div className="content">
                {/* <p>{headerData.section_content.about}</p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: headerData?.section_content?.about,
                  }}
                ></div>
              </div>
            </div>
            <div className="content_col__box2">
              <p>
                <svg className="img">
                  <use xlinkHref={`${SVGSprite}#phone-white`}></use>
                </svg>
                Support center 24/7
              </p>
              <a href={`tel:${headerData.section_content.support_center_no}`}>
                {headerData.section_content.support_center_no}
              </a>
            </div>
          </div>
          <div className="nav_col">
            <img src={menuBGImg} className="img bg_img" alt="Background" />
            <div className="nav_wrap">
              <div className="nav">
                <ul>
                  {menuList.length > 0
                    ? menuList.map((item: any, index: any) => {
                      let activeMenuItem = localStorage.getItem("activeMenu");

                      return (
                        <li
                          key={index}
                          className={`${(
                              activeMenuItem
                                ? activeMenuItem === item.key.toString()
                                : location.pathname === item.key
                            )
                              ? "ant-menu-item-active"
                              : ""
                            }`}
                        >
                          {item?.children ? (
                            <>
                              <span
                                className={`ant-menu-title-content`}
                                onClick={() => {
                                  setOpenChildMenu(item.key);
                                }}
                              >
                                <span className="cursorPointer">
                                  {item.label}
                                </span>
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className={`ant-menu-title-content`}
                                onClick={() => {
                                  setActivemenu(item.key);
                                  close();
                                }}
                              >
                                {item?.key.includes("http") ? (
                                  <a
                                    href={`${item?.children ? "#" : item.key
                                      }`}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                      setActivemenu(item.key);
                                      close();
                                    }}
                                  >
                                    {item.label}
                                  </a>
                                ) : (
                                  <Link
                                    to={`${item?.children ? "#" : item.key}`}
                                    onClick={() => {
                                      setActivemenu(item.key);
                                      close();
                                    }}
                                  >
                                    {item.label}
                                  </Link>
                                )}
                              </span>
                            </>
                          )}
                          {
                            openChildMenu === item.key && (
                              <ul className="ant-menu ant-menu-sub">
                                {item?.children &&
                                  item?.children.map((child: any, i: any) => {
                                    return (
                                      <li
                                        key={i}
                                        className={`${location.pathname === child.key ||
                                            activeChildMenu === child.key
                                            ? "ant-menu-item-active"
                                            : ""
                                          }`}
                                      >
                                         {!child.key.includes("http") ? (<span
                                          className="ant-menu-title-content"
                                          onClick={() => {
                                            setActivemenu(item.key);
                                            close();
                                          }}
                                        >
                                          <Link
                                            to={`${child.key}`}
                                            onClick={() => {
                                              setActivemenu(item.key);
                                              close();
                                            }}
                                          >
                                            {child.label}
                                          </Link>
                                          </span>) : (<span className="ant-menu-title-content" >
                                            <a 
                                            rel="noreferrer"
                                              href={child.key} target="_blank"
                                            >
                                              {child.label}
                                            </a>
                                          </span>)}
                                      </li>
                                    );
                                  })}
                              </ul>
                            )
                            // : null
                          }
                        </li>
                      );
                    })
                    : null}
                </ul>
                {/* <Menu
                  className="header__menu"
                  mode={"inline"}
                  activeKey={location.pathname}
                  // defaultOpenKeys={menu && menu.length > 0 ? [menu[0].key] : []}
                  openKeys={openKeys}
                  selectedKeys={[current]}
                  onOpenChange={onOpenChange}
                  items={menu}
                  onClick={(item) => {
                    !item.key.includes("http") && navigate(item.key);
                    close();
                  }}
                ></Menu> */}
              </div>
            </div>
            <div className="info_wrap">
              <div className="box">
                <img src={logoBGImg} className="img" alt="Background Logo" />
                <h4>Let&apos;s get in touch</h4>
                <p>{headerData.section_content.get_in_touch}</p>
              </div>
            </div>
            <div className="contect_wrap">
              <div className="box">
                <p>
                  <label>
                    <svg className="img">
                      <use xlinkHref={`${SVGSprite}#email-white`}></use>
                    </svg>
                    For careers-
                  </label>
                  <a href={`mailto:${headerData.career_email}`}>
                    {headerData.career_email}
                  </a>
                </p>
                <p>
                  <label>
                    <svg className="img">
                      <use xlinkHref={`${SVGSprite}#email-white`}></use>
                    </svg>
                    For business inquiries-
                  </label>
                  <a href={`mailto:${headerData.customercare_email}`}>
                    {headerData.customercare_email}
                  </a>
                </p>
              </div>
            </div>
            <div className="follow_wrap">
              <ul>
                <li>
                  <p>Follow us:</p>
                </li>
                {headerData.social_link.facebook && (
                  <li>
                    <a
                      href={headerData.social_link.facebook}
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacbookIcon />
                    </a>
                  </li>
                )}
                {headerData.social_link.instagram && (
                  <li>
                    <a
                      href={headerData.social_link.instagram}
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                  </li>
                )}
                {headerData.social_link.twitter && (
                  <li>
                    <a
                      href={headerData.social_link.twitter}
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </li>
                )}
                {headerData.social_link.linkedin && (
                  <li>
                    <a
                      href={headerData.social_link.linkedin}
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedIcon />
                    </a>
                  </li>
                )}
                {headerData.social_link.youtube && (
                  <li>
                    <a
                      href={headerData.social_link.youtube}
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YoutubeIcon />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  }
);

export default MainMenuComponent;
