import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import AuthStore from "../AuthStore/AuthStore";
export default class HomepageStore {
  public homepageData: any;
  public publicNoticeData: any;
  private authStore: AuthStore;
  constructor() {
    this.authStore = new AuthStore();
    makeAutoObservable(this);
  }

  // API Functions
  public homepageAPICall = async (): Promise<any> => {
    return await axios
      .get(API_URL.HOMEPAGE)
      .then(({ data }) => {
        this.homepageData = data.data;
        this.authStore.isMaintenance = false;
      })
      .catch(({ data }) => {
        this.authStore.isMaintenance = true;
        return Promise.reject(data);
      });
  };

  public publicNoticeAPICall = async (): Promise<any> => {
    return await axios
      .get(API_URL.PUBLICNOTICE)
      .then(({ data }) => {
        this.publicNoticeData = data?.data?.popups;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };
}
