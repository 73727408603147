// import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import SelectBag from "./component/SelectBag";
import PickupDelivery from "./component/PickupDelivery";
import ScheduleLocation from "./component/ScheduleLocation";
import Summary from "./component/Summary";
import {
  SelectBagTitle,
  PickupDeliveryTitle,
  ScheduleLocationTitle,
  SummaryTitle,
} from "./component/AllTabTitleIcon";
import { useNavigate } from "react-router";

const Travelfree: React.FC = () => {
  const { Step } = Steps;

  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (
      !localStorage.getItem("travelfree_pickup_pin") &&
      !localStorage.getItem("travelfree_delivery_pin")
    ) {
      localStorage.setItem("desinationPage", "travelfree");
      navigate("/travelfree");
    }
  }, [navigate]);

  const steps = [
    {
      key: "SelectBag",
      title: SelectBagTitle,
      content: <SelectBag current={current} setCurrent={setCurrent} />,
    },
    {
      key: "PickupDelivery",
      title: PickupDeliveryTitle,
      content: <PickupDelivery current={current} setCurrent={setCurrent} />,
    },
    {
      key: "ScheduleLocation",
      title: ScheduleLocationTitle,
      content: <ScheduleLocation current={current} setCurrent={setCurrent} />,
    },
    {
      key: "Summary",
      title: SummaryTitle,
      content: <Summary current={current} setCurrent={setCurrent} />,
    },
  ];
  return (
    <>
      <section className="eBook_section pv-64">
        <div className="container_sm">
          <div className="sc_title text-center">
            <div className="main_title" data-title="Travel Free">
              <h3>Travel Free</h3>
            </div>
          </div>

          <div className="step_wrapper">
            <Steps current={current}>
              {steps.map((item) => (
                <Step
                  key={item.key}
                  title={item.title}
                  // onClick={() => setCurrent(current)}
                />
              ))}
            </Steps>

            <div className="step_content">{steps[current].content}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Travelfree;
