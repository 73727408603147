import React from "react";
import useStore from "../../../../store";
import { observer } from "mobx-react";

const TravellingInfo: React.FC = observer(() => {
  const { TRACK_COURIER_STORE } = useStore();
  const { track_shipment_data } = TRACK_COURIER_STORE;

  return (
    <>
      <div className="trackingInfoBlock">
        <div className="sc_title text-center">
          <div className="main_title mb-2" data-title="Travelling Information">
            <h3>Travelling Information</h3>
          </div>
        </div>
        <div className="infoTableWrap customTableWrap">
          {track_shipment_data.travelingData?.length !== 0 ? (
            <div className="bookingReportWrap">
              <table className="tracking">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>INSCAN / OUTSCAN</th>
                    <th>TRAVELING</th>
                  </tr>
                </thead>
                <tbody>
                  {track_shipment_data.travelingData.map((item: any) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={3} className="text-left darkRow">
                            {item.date}
                          </td>
                        </tr>
                        {item.info.map((infoItem: any) => {
                          return (
                            <tr>
                              <td data-label="Date"></td>
                              <td data-label="Inscan / Outscan">
                                {infoItem.inscan_outscan}
                              </td>
                              <td data-label="Traveling">
                                {infoItem.traveling}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="noData">{track_shipment_data.travelingDataMsg}</p>
          )}
        </div>
      </div>
    </>
  );
});

export default TravellingInfo;
