// import { Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Form, Radio, Button } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
// import { Scrollbars } from "react-custom-scrollbars";
import { ReactComponent as LeftArrow } from "../../../../assets/images/icon/left-arrow.svg";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import moment from "moment";
import { TravelfreeFormRequest } from "../../../../requests/PagesRequest";
import SeoHeader from "../../../../components/SeoHeader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ScheduleLocation: React.FC<any> = observer(
  ({ setCurrent, current }: any) => {
    const [form] = Form.useForm();

    const { TRAVELFREESTORE } = useStore();
    const {
      fetchPickupChannelPartner,
      pickup_channel_partner_data,
      delivery_channel_partner_data,
      server_date_time,
    } = TRAVELFREESTORE;

    // const [disabled, setDisabled] = useState<boolean>(true);
    const [showPickCP, setShowPickCP] = useState<boolean>(true);
    const [slotDisableCP, setSlotDisable] = useState<boolean>(true);
    const [currentDate, setCurrentDate] = useState<boolean>(false);

    let checkTimeSlot = parseInt(moment(server_date_time).format("HH:MM")) + 2;
    let todayDate = moment(server_date_time).format("DD-MM-YYYY");

    const handleSubmit = (data: any) => {
      form.validateFields().then((data) => {
        data.pick_date = moment(data.pick_date).format("DD-MM-YYYY");
        data.pick_dropoff_type = 1;
        TRAVELFREESTORE.travelfree_form_data = {
          ...TRAVELFREESTORE.travelfree_form_data,
          ...data,
        };
        localStorage.setItem(
          "travelfree-form",
          JSON.stringify(TRAVELFREESTORE.travelfree_form_data)
        );
        setCurrent(current + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    };

    const pickupChannelList = useCallback(() => {
      const payload: any = {
        pickup_pincode: JSON.parse(
          localStorage.getItem("travelfree_pickup_pin") ?? ""
        ).pincode,
        pincode_type: JSON.parse(
          localStorage.getItem("travelfree_pickup_pin") ?? ""
        ).pincode_type,
        from_lat: JSON.parse(
          localStorage.getItem("travelfree_pickup_pin") ?? ""
        ).from_lat,
        from_lng: JSON.parse(
          localStorage.getItem("travelfree_pickup_pin") ?? ""
        ).from_lng,
        cp_type: 1,
      };
      fetchPickupChannelPartner(payload);
    }, [fetchPickupChannelPartner]);

    const disableToDateStart = (current: any) => {
      // if (current) {
      if (server_date_time) {
        var addedDate =
          checkTimeSlot < 18
            ? moment(server_date_time)
            : moment().subtract(-1, "day");
        var days = [];
        var dayCount = 0;

        while (dayCount <= 3) {
          // if (addedDate.format("dddd")) {
          if (addedDate.format("dddd") !== "Sunday") {
            days.push(addedDate.format("DD-MM-YYYY"));
            dayCount++;
          }
          addedDate = addedDate.add(1, "day");
        }
        return !(days.indexOf(current.format("DD-MM-YYYY")) >= 0);
      } else {
        return false;
      }
    };

    const pickupDateChnage = (e: any) => {
      form.setFieldValue("pickup_slot", null);
      if (e) {
        if (todayDate === moment(e).format("DD-MM-YYYY")) {
          setCurrentDate(true);
        } else {
          setCurrentDate(false);
        }
        setSlotDisable(false);
      } else {
        setSlotDisable(true);
      }
    };

    useEffect(() => {
      let getLocalData: any | null = JSON.parse(
        localStorage.getItem("travelfree-form") as any | null
      );
      let updateData: any =
        TRAVELFREESTORE.travelfree_form_data?.length > 0
          ? TRAVELFREESTORE.travelfree_form_data
          : getLocalData;

      form.setFieldsValue({
        pick_date: updateData?.pick_date
          ? moment(updateData?.pick_date, "DD-MM-YYYY")
          : null,
        pickup_slot: updateData?.pickup_slot,
        pick_dropoff_type: updateData?.pick_dropoff_type,
      });

      if (updateData?.pick_dropoff_type === 2) {
        setShowPickCP(false);
      }
    }, [form, TRAVELFREESTORE]);

    useEffect(() => {
      pickupChannelList();
    }, [pickupChannelList]);

    const SeoData = {
      title: "Schedule & Location Details for Travel Free",
      description:
        "Shree Maruti provides Travel Free process for online parcel booking, same-day door-to-door delivery service, and the best home pickup courier service in India.",
      keywords:
        "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
      url: "/travelfree-form",
      ogTitle: "Shipment Details for Travel Free",
    };

    const settings = {
      infinite: false,
      dots: true,
      arrows: false,
      autoplay: false,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 7680,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const SliderData = (data: any, index: number) => {
      const item = data.item;
      return item ? (
        <>
          <div className="cpListBox" key={index}>
            <Radio value={item}>
              <div className="title">{item?.sp_name}</div>
              <div className="content">
                <address>
                  {item?.address1}
                  <br />
                  {item?.address2}
                  <br />
                  {item?.city}
                  <br />
                  {item?.contact_no && (
                    <>
                      Contact No: {item?.contact_no}, {item?.contact_no2}
                    </>
                  )}
                  <br />
                  {item?.email && <>Email: {item?.email}</>}
                </address>
              </div>
            </Radio>
          </div>
        </>
      ) : null;
    };

    return (
      <>
        <SeoHeader seoData={SeoData} />
        <div className="eBook_form">
          <FormBox
            form={form}
            // onChange={handleChange}
            onFinish={handleSubmit}
          >
            <Row gutter={30} className="input_group_blk">
              <Col xs={{ span: 24 }}>
                <div className="input_group">
                  {showPickCP ? (
                    <h4>Book a time slot for a pickup.</h4>
                  ) : (
                    <h4>Drop off details.</h4>
                  )}
                </div>
              </Col>
              {/* <Col xs={{ span: 24 }}>
							<div className="input_group">
								<InputBox.Radio
									onChange={(e: any) => changePickupDropoff(e)}
									initialValue={1}
									name="pick_dropoff_type"
									required
									rules={TravelfreeFormRequest.pick_dropoff_type}
									options={{
										list: [
											{ id: 1, name: "Pickup" },
											{ id: 2, name: "Dropoff" },
										],
										valueKey: "id",
										textKey: "name",
									}}
								/>								
							</div>
						</Col> */}
              <Col xs={{ span: 24 }}>
                {showPickCP ? (
                  <Row gutter={30} className="input_group_blk">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <div className="input_group">
                        <InputBox.DatePicker
                          label="Pickup Date"
                          name="pick_date"
                          format={"DD-MM-YYYY"}
                          required
                          rules={TravelfreeFormRequest.pick_date}
                          disabledDate={disableToDateStart}
                          onChange={(e) => pickupDateChnage(e)}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <div className="input_group">
                        <InputBox.Select
                          label="Pickup Time Slot"
                          name="pickup_slot"
                          placeholder="Select Time Slot"
                          required
                          rules={TravelfreeFormRequest.pickup_slot}
                          disabled={slotDisableCP}
                          options={{
                            list: [
                              {
                                id: "10-12",
                                name: "10:00 AM - 12:00 PM",
                                disabled: currentDate
                                  ? checkTimeSlot >= 12
                                    ? true
                                    : false
                                  : false,
                              },
                              {
                                id: "12-02",
                                name: "12:00 PM - 02:00 PM",
                                disabled: currentDate
                                  ? checkTimeSlot >= 14
                                    ? true
                                    : false
                                  : false,
                              },
                              {
                                id: "02-04",
                                name: "02:00 PM - 04:00 PM",
                                disabled: currentDate
                                  ? checkTimeSlot >= 16
                                    ? true
                                    : false
                                  : false,
                              },
                              {
                                id: "04-06",
                                name: "04:00 PM - 06:00 PM",
                                disabled: currentDate
                                  ? checkTimeSlot >= 18
                                    ? true
                                    : false
                                  : false,
                              },
                            ],
                            valueKey: "id",
                            textKey: "name",
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} className="col_address">
                      <h4>Select Pickup Channel Partner</h4>
                      <div className="addressPickup">
                        {/* <Scrollbars
                          universal={true}
                          autoHeight
                          // autoHeightMin={"400px"}
                          style={{ height: "100%", maxHeight: "100%" }}
                        > */}
                        <div className="cpListWrap">
                          <Form.Item
                            name="pickup_cp"
                            rules={TravelfreeFormRequest.pickup_cp}
                          >
                            <Radio.Group>
                              <Slider className="cPValue_slick" {...settings}>
                                {pickup_channel_partner_data &&
                                  pickup_channel_partner_data?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <SliderData item={item} index={index} />
                                      );
                                    }
                                  )}
                              </Slider>
                              {/* <Slider className="cPValue_slick" {...settings}>
                                {pickup_channel_partner_data &&
                                  pickup_channel_partner_data.map(
                                    (item: any, index: any) => {
                                      return (
                                        <div className="cpListBox" key={index}>
                                          <Radio value={item}>
                                            <div className="title">
                                              {item.sp_name}
                                            </div>
                                            <div className="content">
                                              <address>
                                                {item.address1}
                                                <br />
                                                {item.address2}
                                                <br />
                                                {item.city}
                                                <br />
                                                {item.contact_no && (
                                                  <>
                                                    Contact No:{" "}
                                                    {item.contact_no},{" "}
                                                    {item.contact_no2}
                                                  </>
                                                )}
                                                <br />
                                                {item.email && (
                                                  <>Email: {item.email}</>
                                                )}
                                              </address>
                                            </div>
                                          </Radio>
                                        </div>
                                      );
                                    }
                                  )}
                              </Slider> */}
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        {/* </Scrollbars> */}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={30} className="input_group_blk">
                    <Col xs={{ span: 24 }}>
                      <p className="dropoffNote">
                        Note: Pickup charge will not be applicable in case of
                        Drop. You can drop the shipment on Monday - Saturday
                        between 10am - 7am.{" "}
                      </p>
                    </Col>
                    <Col xs={{ span: 24 }} className="col_address">
                      <h4>Select Dropoff Channel Partner</h4>
                      <div className="addressPickup">
                        {/* <Scrollbars
                          universal={true}
                          autoHeight
                          // autoHeightMin={"400px"}
                          style={{ height: "100%", maxHeight: "100%" }}
                        > */}
                        <div className="cpListWrap">
                          <Form.Item
                            name="dropoff_cp"
                            rules={TravelfreeFormRequest.dropoff_cp}
                            noStyle
                          >
                            <Radio.Group>
                              <Slider className="cPValue_slick" {...settings}>
                                {delivery_channel_partner_data &&
                                  delivery_channel_partner_data?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <SliderData item={item} index={index} />
                                      );
                                    }
                                  )}
                              </Slider>
                              {/* <Slider className="cPValue_slick" {...settings}>
                                {delivery_channel_partner_data &&
                                  delivery_channel_partner_data.map(
                                    (item: any, index: number) => {
                                      return (
                                        <>
                                          <div
                                            className="cpListBox"
                                            key={index}
                                          >
                                            <Radio value={item}>
                                              <div className="title">
                                                {item.sp_name}
                                              </div>
                                              <div className="content">
                                                <address>
                                                  {item.address1}
                                                  <br />
                                                  {item.address2}
                                                  <br />
                                                  {item.city}
                                                  <br />
                                                  {item.contact_no && (
                                                    <>
                                                      Contact No:{" "}
                                                      {item.contact_no},{" "}
                                                      {item.contact_no2}
                                                    </>
                                                  )}
                                                  <br />
                                                  {item.email && (
                                                    <>Email: {item.email}</>
                                                  )}
                                                </address>
                                              </div>
                                            </Radio>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </Slider> */}
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        {/* </Scrollbars> */}
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col xs={{ span: 24 }}>
                <Row className="mt-30">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    className="text-left col_btn prevBtnWrap"
                  >
                    <Button
                      className="prevBtn"
                      onClick={() => setCurrent(current - 1)}
                    >
                      <LeftArrow />
                      Previous
                    </Button>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    className="text-right col_btn submitBtn"
                  >
                    <Button
                      className="btn btn_secondary"
                      htmlType="submit"
                      // disabled={disabled}
                    >
                      Save & Continue
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormBox>
        </div>
      </>
    );
  }
);

export default ScheduleLocation;
