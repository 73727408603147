import React, { useState } from "react";
import { Modal, Form, Row, Col, Button } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import "react-image-lightbox/style.css";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { CommonRequest, vsmNotify } from "../../../../requests/PagesRequest";
import { ReactComponent as ModalLogo } from "../../../../assets/images/logo.svg";
import CardImage from "../../../../assets/images/card-bg-01.png";

interface OTPVerModalOpenCloseProps {
  visible: any;
  close: any;
}

const OTPVerification: React.FC<OTPVerModalOpenCloseProps> = observer(
  ({ visible, close }) => {
    const [form] = Form.useForm();
    const { TRACK_COURIER_STORE, ROOT } = useStore();
    const { assignErrorToInput } = ROOT;
    const { track_shipment_data, getTrackingOTP, getVerifyOTP } =
      TRACK_COURIER_STORE;

    const [disabled, setDisabled] = useState<boolean>(true);
    const [disabledOTPSubmit, setDisabledOTPSubmit] = useState<boolean>(true);
    const [mobileNoVerfi, setMobileNoVerfi] = useState<boolean>(true);
    const [showOTPScreen, setShowOTPScreen] = useState<boolean>(false);

    const handleSubmit = (data: any) => {
      // setSaving(true);
      data.DocumentNo = track_shipment_data.deliveryData?.DocumentNo;
      data.RoID = track_shipment_data.verificationData?.RoID
        ? track_shipment_data.verificationData.RoID
        : "";
      data.otp_type = "2";
      getTrackingOTP(data)
        .then((data: any) => {
          form.resetFields();
          setShowOTPScreen(true);
        })
        .catch((e: any) => {
          if (e.data.errors) {
            // form.setFields(e.errors);
            assignErrorToInput(form, e?.data?.errors);
          }
        });
      // .finally(() => setSaving(false));
    };

    const verifyOTPHandleSubmit = (data: any) => {
      // setSaving(true);
      data.DocumentNo = track_shipment_data.deliveryData?.DocumentNo;
      // data.RoID = track_shipment_data.verificationData?.RoID
      //   ? track_shipment_data.verificationData.RoID
      //   : "";
      data.otp_type = "2";
      data.mobile_no = localStorage.getItem("otp_verified_mobile_no");
      getVerifyOTP(data)
        .then((data: any) => {
          form.resetFields();
          localStorage.setItem("tracking_otp_verification", "1");
          handleCancel();
        })
        .catch((e: any) => {
          if (e.data.errors) {
            // form.setFields(e.errors);
            assignErrorToInput(form, e?.data?.errors);
          }
        });
      // .finally(() => setSaving(false));
    };

    const handleCancel = () => {
      close();
      form.resetFields();
      setDisabled(true);
    };

    const checkMobileNumber = (e: any) => {
      if (e.target.value.length === 10) {
        if (
          form.getFieldValue("mobile_no").trim() ===
            track_shipment_data.verificationData?.sender_phone ||
          form.getFieldValue("mobile_no").trim() ===
            track_shipment_data.verificationData?.receiver_phone
        ) {
          setMobileNoVerfi(false);
          // message.success("Mobile Number Matched");
          vsmNotify.success({ message: "Mobile Number Matched" });
          localStorage.setItem(
            "otp_verified_mobile_no",
            form.getFieldValue("mobile_no")
          );
        } else {
          setMobileNoVerfi(true);
          // message.error("Doesn't match Mobile Number");
          vsmNotify.error({ message: "Doesn't match Mobile Number" });
        }
      }
    };

    const handleChange = () => {
      form
        .validateFields()
        .then((data) => {
          setDisabled(false);
        })
        .catch((e) => {
          setDisabled(true);
        });
    };

    const verifyOTPHandleChange = () => {
      form
        .validateFields()
        .then((data) => {
          setDisabledOTPSubmit(false);
        })
        .catch((e) => {
          setDisabledOTPSubmit(true);
        });
    };

    // const modalHeader = () => {
    // 	<div className="modalHeaderLogo">
    // 		<ModalLogo />
    // 	</div>
    // }

    return (
      <>
        <Modal
          // title={()=>modalHeader}
          open={visible}
          onCancel={handleCancel}
          footer={null}
          centered
          // footer={[
          //   <Button
          //     className="btn btn_secondary"
          //     htmlType="submit"
          //     // disabled={disabled}
          //     // loading={saving}
          //   >
          //     Submit
          //   </Button>,
          // ]}
        >
          <>
            <div className="modalHeaderLogo">
              <ModalLogo />
            </div>
            {!showOTPScreen && (
              <FormBox
                form={form}
                id="sendOTPForm"
                onFinish={handleSubmit}
                onChange={handleChange}
              >
                <Row gutter={30} className="input_group_blk">
                  <Col xs={{ span: 24 }}>
                    <div className="input_group textBlack">
                      <InputBox.Text
                        label="Please enter mobile number"
                        placeholder="Enter mobile no. given at the time of booking"
                        name="mobile_no"
                        onChange={(e: any) => checkMobileNumber(e)}
                        rules={CommonRequest.phone}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    className="text-center col_btn submitBtn"
                  >
                    <Button
                      className="btn btn_primary"
                      htmlType="submit"
                      disabled={disabled || mobileNoVerfi}
                    >
                      Send OTP
                    </Button>
                  </Col>
                </Row>
              </FormBox>
            )}
            {showOTPScreen && (
              <FormBox
                form={form}
                id="verifyOTPForm"
                onFinish={verifyOTPHandleSubmit}
                onChange={verifyOTPHandleChange}
              >
                <Row gutter={30} className="input_group_blk">
                  <Col xs={{ span: 24 }}>
                    <div className="input_group textBlack">
                      <InputBox.Text
                        label="Please enter OTP"
                        name="otp"
                        rules={CommonRequest.otp}
                        maxLength={6}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    className="text-center col_btn submitBtn"
                  >
                    <Button
                      className="btn btn_primary"
                      htmlType="submit"
                      disabled={disabledOTPSubmit}
                    >
                      Verify OTP
                    </Button>
                  </Col>
                </Row>
              </FormBox>
            )}
            <div
              className="modalBG"
              style={{ backgroundImage: `url(${CardImage})` }}
            ></div>
          </>
        </Modal>
      </>
    );
  }
);

export default OTPVerification;
