import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { b64toBlob } from "../../utils/GlobalFunction";

export default class BookingDetailStore {
  public booking_detail_data: any;
  public my_booking_detail_data: any;

  constructor() {
    makeAutoObservable(this);
  }

  public fetchMyBookingDetail = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.MY_BOOKING, payload)
      .then(({ data }) => {
        this.my_booking_detail_data = data;
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  public fetchBookingDetail = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.BOOKING_DETAIL, payload)
      .then(({ data }) => {
        this.booking_detail_data = data;
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  public fetchDownloadPOD = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.DOWNLOAD_POD, payload)
      .then(({ data }) => {
        if (data.file_url) {
          window.location.href = data.file_url;
        }
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  public fetchMyPodDownload = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.MY_POD_DOWNLOAD, payload)
      .then(({ data }) => {
        if (data.file_url) {
          window.location.href = data.file_url;
        }
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  public printBookingOrder = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.PRINT_ORDER, data)
      .then(({ data }) => {
        const file = b64toBlob(data.blob_content, "application/pdf", 512);
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        //pdfWindow.location.href = fileURL

        return file;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchBookingExport = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.EXPORT_BOOKING_DATA, payload)
      .then(({ data }) => {
        const file = b64toBlob(data.blob_content, "text/csv", 512);
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        //pdfWindow.location.href = fileURL
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };
}
