import React, { useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import AuthLayout from "../views/layouts/AuthLayout";
import Travelfree from "../views/front_pages/Travelfree";
import useStore from "../store";
import Newsletter from "../views/front_pages/Newsletter";
import TrackingURL from "../views/front_pages/Tracking";
import DeliveryPartner from "../views/front_pages/DeliveryPartner";
import StoragePartner from "../views/front_pages/StoragePartner";
import PartPaymentPending from "../views/front_pages/PartPaymentPending";
import ONDCDislosurePage from "../views/front_pages/ONDCDislosurePage";
import TrackYourShipment from "../views/front_pages/TrackYourShipment";
import Playstore from "../views/front_pages/Playstore";

const Login = React.lazy(() => import("../views/app_pages/auth/Login"));
const EBookLogin = React.lazy(
  () => import("../views/app_pages/auth/EBook_Login")
);
const EBookRegister = React.lazy(
  () => import("../views/app_pages/auth/EBook_Register")
);
const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));
const HomePage = React.lazy(() => import("../views/front_pages/HomePage"));
const JoinUsPage = React.lazy(() => import("../views/front_pages/JoinUs"));
const ChannelPartner = React.lazy(
  () => import("../views/front_pages/ChannelPartner")
);
const NetworkPage = React.lazy(() => import("../views/front_pages/Network"));
const SitemapPage = React.lazy(() => import("../views/front_pages/Sitemap"));
const EBookShipNow = React.lazy(
  () => import("../views/front_pages/EBook/ShipNow")
);
const TravelfreeShipNow = React.lazy(
  () => import("../views/front_pages/Travelfree/ShipNow")
);
const EBooking = React.lazy(() => import("../views/front_pages/EBook"));
const PaymentSuccess = React.lazy(
  () => import("../views/front_pages/EBook/component/PaymentSuccess")
);
const PrivacyPolicyPage = React.lazy(
  () => import("../views/front_pages/PrivacyPolicy")
);
const InnodelPrivacyPolicy = React.lazy(
  () => import("../views/front_pages/InnodelPrivacyPolicy")
);
const DisclaimerPage = React.lazy(
  () => import("../views/front_pages/Disclaimer")
);

const CarriagePage = React.lazy(
  () => import("../views/front_pages/CarriagePage")
);
const ContactUs = React.lazy(() => import("../views/front_pages/ContactUs"));
const BookingInquiry = React.lazy(
  () => import("../views/front_pages/BookingInquiry")
);
const TrackCourierPage = React.lazy(
  () => import("../views/front_pages/TrackCourier")
);
const BookingDetail = React.lazy(
  () => import("../views/front_pages/BookingDetail")
);
const MyBooking = React.lazy(() => import("../views/front_pages/MyBooking"));
const PrintOrder = React.lazy(() => import("../views/front_pages/PrintOrder"));
// const AllTransaction = React.lazy(
//   () => import("../views/app_pages/AllTransaction")
// );

export default function Router() {
  const navigate = useNavigate();
  const {
    AUTH: { isMaintenance },
  } = useStore();

  useEffect(() => {
    if (isMaintenance === true) {
      navigate("/");
    }
  }, [isMaintenance, navigate]);

  const element = useRoutes(RouterConfig);
  return element;
}

export const RouterConfig = [
  {
    path: "/dw",
    element: <Playstore />,
  },
  {
    element: <AuthLayout checkedLogin={false} checkedLoginType="" />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/ebook-register",
        element: <EBookRegister />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/ebook-login",
        element: <EBookLogin />,
      },
      {
        path: "/unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "/join-us",
        element: <JoinUsPage />,
      },
      {
        path: "/channel-partner",
        element: <ChannelPartner />,
      },
      {
        path: "/delivery-partner",
        element: <DeliveryPartner />,
      },
      {
        path: "/storage-partner",
        element: <StoragePartner />,
      },
      {
        path: "/network-map",
        element: <NetworkPage />,
      },
      {
        path: "/sitemap",
        element: <SitemapPage />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/innodel-privacy-policy",
        element: <InnodelPrivacyPolicy />,
      },
      {
        path: "disclaimer",
        element: <DisclaimerPage />,
      },
      {
        path: "conditions-of-carriage",
        element: <CarriagePage />,
      },
      {
        path: "ondc-disclosure",
        element: <ONDCDislosurePage />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "booking-inquiry",
        element: <BookingInquiry />,
      },
      {
        path: "track-shipment",
        element: <TrackCourierPage />,
      },
      {
        path: "/newsletter",
        element: <Newsletter />,
      },
      {
        path: "/newsletter/:topicId",
        element: <Newsletter />,
      },
      {
        path: "/printorder",
        element: <PrintOrder />,
      },
      {
        path: "/print-order",
        element: <PrintOrder />,
      },
      {
        path: "/tracking",
        element: <TrackingURL />,
      },
      {
        path: "/track-your-shipment",
        element: <TrackYourShipment />,
      },
      {
        path: "/part-payment-pending",
        element: <PartPaymentPending />,
      },      
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    element: <AuthLayout checkedLogin={true} checkedLoginType="ebooking" />,
    children: [
      {
        path: "e-booking",
        element: <EBookShipNow />,
      },
      {
        path: "ebooking-form",
        element: <EBooking />,
      },
      {
        path: "payment-success",
        element: <PaymentSuccess />,
      },
    ],
  },
  {
    element: <AuthLayout checkedLogin={true} checkedLoginType="travelfree" />,
    children: [
      {
        path: "travelfree",
        element: <TravelfreeShipNow />,
      },
      {
        path: "travelfree-form",
        element: <Travelfree />,
      },
      {
        path: "payment-success",
        element: <PaymentSuccess />,
      },
    ],
  },
  {
    element: <AuthLayout checkedLogin={true} checkedLoginType="client" />,
    children: [
      {
        path: "booking-detail",
        element: <BookingDetail />,
      },
      {
        path: "my-booking",
        element: <MyBooking />,
      },
    ],
  },
];
