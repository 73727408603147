import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class BookingInquiryStore {
  public fromPinList: any;
  public toPinList: any;
  public contactFormData: any;

  constructor() {
    makeAutoObservable(this);
  }

  public fromPincodeList = async (pin: any): Promise<any> => {
    return await axios
      .get(`${API_URL.BOOKING_INQUIRY.FROM_PINCODE}?term=${pin}`)
      .then(({ data }) => {
        this.fromPinList = data.data;
      });
  };

  public toPincodeList = async (pin: any): Promise<any> => {
    return await axios
      .get(`${API_URL.BOOKING_INQUIRY.TO_PINCODE}?term=${pin}`)
      .then(({ data }) => {
        this.toPinList = data.data;
      });
  };

  // API Functions
  // public contactAPICall = async (): Promise<any> => {
  //   return await axios.post(API_URL.CONTACT.BANNER).then(({ data }) => {
  //     this.contactData = data.data;
  //   });
  // };

  public submitBookingReqForm = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.BOOKING_INQUIRY.BOOKING_REQ_FORM, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);

      });
  };

  public bookingInqSendOTP = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.SEND_OTP, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  public bookingInqVerifyOTP = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.VERIFY_OTP, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };
}
