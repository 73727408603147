import React, { useEffect, useState } from "react";
// import HeaderLogoComponent from "../../Components/LogoComponent";
import HeaderMenuComponent from "../../Components/HeaderMenuComponent";
import { Header } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
// import LOGO_IMAGE from "../../../../assets/images/logo.svg";
import { ReactComponent as LOGO_IMAGE } from "../../../../assets/images/logo.svg";
import { observer } from "mobx-react";

const HeaderView: React.FC = observer(() => {
  const [sticky, setSticky] = useState<boolean>();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setSticky(window.scrollY > 100);
    });
  }, []);

  return (
    <Header className={`main__page__header ${sticky ? "fixed" : ""}`}>
      <div className="container">
        <div className="header_wraper">
          <div
            className="logo_wrapper"
            onClick={() => {
              window.scrollTo({ top: 100, behavior: "smooth" });
            }}
          >
            <Link to="/">
              <LOGO_IMAGE />
            </Link>
          </div>
          <HeaderMenuComponent />
        </div>
        {/* <HeaderLogoComponent /> */}
      </div>
    </Header>
  );
});

export default HeaderView;
