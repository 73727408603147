import { notification } from "antd";
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import Regex from "../config/Regex";

export const vsmNotify = {
  success: (data: any) => {
    notification.success({ placement: "bottomRight", duration: 3, ...data });
  },
  error: (data: any) => {
    notification.error({ placement: "bottomRight", duration: 3, ...data });
  },
};

export const BookingDetailRequest: RequestProps = {
  location_id: [{ required: true, message: Message.required("Location Name") }],
  date_range: [{ required: true, message: Message.required("Date") }],
};

export const ClientLogin: RequestProps = {
  username: [
    { required: true, message: Message.required("Username") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  password: [
    { required: true, message: Message.required("Password") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
};

export const CommonRequest: RequestProps = {
  phone: [
    { required: true, message: Message.required("Phone") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator() {
        const mobileNo = getFieldValue("mobile_no");

        if (mobileNo) {
          if (mobileNo.trim().length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  otp: [
    { required: true, message: Message.required("OTP") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
};

export const HomeSubscribeRequest: RequestProps = {
  email: [
    { required: true, message: Message.required("Email") },
    { type: "email", message: Message.type.email("Email ID") },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 8,
      message: Message.min(8),
    },
  ],
};

export const TravelfreeFormRequest: RequestProps = {
  quantity: [{ max: 10, message: Message.max(10) }],
  termandconditions: [
    { required: true, message: "Please accept all Terms and Conditions." },
  ],
  shipment_type: [
    { required: true, message: Message.required("Shipment type") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  eway_bill_no: [
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value && value.length !== 12) {
          return Promise.reject("Eway Bill No should be 12 Digit");
        }
        return Promise.resolve();
      },
    }),
  ],
  content: [
    { required: true, message: Message.required("Content") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  goods_value: [
    { required: true, message: Message.required("Good Values") },
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    {
      max: 7,
      message: "Value cannot more than 1000000",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value > 0 || value < 1000000) {
          return Promise.resolve();
        } else if (value > 1000000) {
          return Promise.reject("Value cannot more than 1000000");
        } else {
          return Promise.reject("Value cannot be zero");
        }
      },
    }),
  ],
  length: [
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value > 0) {
          return Promise.resolve();
        }
        return Promise.reject("Value cannot be zero");
      },
    }),
  ],
  width: [
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value > 0) {
          return Promise.resolve();
        }
        return Promise.reject("Value cannot be zero");
      },
    }),
  ],
  height: [
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value > 0) {
          return Promise.resolve();
        }
        return Promise.reject("Value cannot be zero");
      },
    }),
  ],
  volumetric_weight: [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value <= 15) {
          return Promise.resolve();
        }
        return Promise.reject("Vol Weight cannot be greater than 15 Kgs");
      },
    }),
  ],
  service_type: [
    { required: true, message: Message.required("Service Type") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_name: [
    { required: true, message: Message.required("Full Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  pickup_address1: [
    { required: true, message: Message.required("Address1") },
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_address2: [
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_city: [
    { required: true, message: Message.required("City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_state: [
    { required: true, message: Message.required("State") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_name: [
    { required: true, message: Message.required("Full Name") },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_mobile: [
    { required: true, message: Message.required("Mobile No.") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator() {
        const mobileNo = getFieldValue("delivery_mobile");

        if (mobileNo) {
          if (mobileNo.trim().length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  delivery_address1: [
    { required: true, message: Message.required("Address1") },
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_address2: [
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_email: [
    { required: true, message: Message.required("Email") },
    { type: "email", message: Message.type.email("Email ID") },
    { min: 8, message: Message.min(8) },
    { max: 100, message: Message.max(100) },
  ],
  delivery_city: [
    { required: true, message: Message.required("City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],

  delivery_state: [
    { required: true, message: Message.required("State") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_what3words: [
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  pick_date: [{ required: true, message: Message.required("Pickup date") }],
  pickup_slot: [{ required: true, message: Message.required("Time Slot") }],
  pickup_cp: [{ required: true, message: Message.required("Channel Partner") }],
  dropoff_cp: [
    { required: true, message: Message.required("Channel Partner") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  gst_name: [
    { required: true, message: Message.required("Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  gst_number: [
    { required: true, message: Message.required("GST Number") },
    {
      pattern:
        /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}$/,
      message: "GST Number is not valid",
    },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pick_dropoff_type: [
    { required: true, message: Message.required() },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
};
export const EbookFormRequest: RequestProps = {
  termandconditions: [
    { required: true, message: "Please accept all Terms and Conditions." },
  ],
  shipment_type: [
    { required: true, message: Message.required("Shipment type") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  eway_bill_no: [
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value && value.length !== 12) {
          return Promise.reject("Eway Bill No should be 12 Digit");
        }
        return Promise.resolve();
      },
    }),
  ],
  content: [
    { required: true, message: Message.required("Content") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  goods_value: [
    { required: true, message: Message.required("Good Values") },
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value > 0 && value <= 1000000) {
          return Promise.resolve();
        } else if (value >= 1000000) {
          return Promise.reject("Value cannot more than 1000000");
        } else {
          return Promise.reject("Value cannot be zero");
        }
      },
    }),
  ],
  length: [
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    // ({ getFieldValue }) => ({
    //   validator(rule, value) {
    //     if (value > 0) {
    //       return Promise.resolve();
    //     }
    //     return Promise.reject("Value cannot be zero");
    //   },
    // }),
  ],
  width: [
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    // ({ getFieldValue }) => ({
    //   validator(rule, value) {
    //     if (value > 0) {
    //       return Promise.resolve();
    //     }
    //     return Promise.reject("Value cannot be zero");
    //   },
    // }),
  ],
  height: [
    {
      pattern: Regex.decimal,
      message: "Only digit allow",
    },
    // ({ getFieldValue }) => ({
    //   validator(rule, value) {
    //     if (value > 0) {
    //       return Promise.resolve();
    //     }
    //     return Promise.reject("Value cannot be zero");
    //   },
    // }),
  ],
  // volumetric_weight: [
  //   ({ getFieldValue }) => ({
  //     validator(rule, value) {
  //       if (value <= 15) {
  //         return Promise.resolve();
  //       }
  //       return Promise.reject("Vol Weight cannot be greater than 15 Kgs");
  //     },
  //   }),
  // ],
  service_type: [
    { required: true, message: Message.required("Service Type") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_name: [
    { required: true, message: Message.required("Full Name") },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_address1: [
    { required: true, message: Message.required("Address1") },
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_address2: [
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_city: [
    { required: true, message: Message.required("City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pickup_state: [
    { required: true, message: Message.required("State") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_name: [
    { required: true, message: Message.required("Full Name") },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_mobile: [
    { required: true, message: Message.required("Mobile No.") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator() {
        const mobileNo = getFieldValue("delivery_mobile");

        if (mobileNo) {
          if (mobileNo.trim().length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  delivery_address1: [
    { required: true, message: Message.required("Address1") },
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_address2: [
    { min: 3, message: Message.min(3) },
    { max: 250, message: Message.max(250) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_email: [
    { required: true, message: Message.required("Email") },
    { type: "email", message: Message.type.email("Email ID") },
    { min: 8, message: Message.min(8) },
    { max: 100, message: Message.max(100) },
  ],
  delivery_city: [
    { required: true, message: Message.required("City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_state: [
    { required: true, message: Message.required("State") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  pick_date: [{ required: true, message: Message.required("Pickup date") }],
  pickup_slot: [{ required: true, message: Message.required("Time Slot") }],
  pickup_cp: [
    { required: true, message: Message.required("Channel Partner") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  dropoff_cp: [
    { required: true, message: Message.required("Channel Partner") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  gst_name: [
    { required: true, message: Message.required("Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  gst_number: [
    { required: true, message: Message.required("GST Number") },
    {
      pattern:
        /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}$/,
      message: "GST Number is not valid",
    },
  ],
  pick_dropoff_type: [
    { required: true, message: Message.required() },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  delivery_what3words: [
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
};

export const EbookRegisterRequest: RequestProps = {
  first_name: [
    { required: true, message: Message.required("First Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  last_name: [
    { required: true, message: Message.required("Last Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  email: [
    { required: true, message: Message.required("Email ID") },
    { type: "email", message: Message.type.email("Email ID") },
  ],
  mobile_no: [
    { required: true, message: Message.required("Mobile No") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
  password: [
    { required: true, message: Message.required("Password") },
    {
      pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
      message: (
        <div>
          Password must contain at least one digit.
          <br />
          Password must contain at least one uppercase letter & one lowercase
          letter.
          <br />
          Password must contain at least one symbol.
          <br />
          Password must contain at least 8 characters.
        </div>
      ),
    },
  ],
  confirm_password: [
    { required: true, message: Message.required("Confirm Password") },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          "New Password and Confirm Password does not match."
        );
      },
    }),
  ],
};

export const EbookRequest: RequestProps = {
  pickup_pincode: [
    { required: true, message: Message.required("Pickup pincode") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
  delivery_pincode: [
    { required: true, message: Message.required("Delivery pincode") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
  mobile_no: [
    { required: true, message: Message.required("Mobile No") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value) {
          if (value.trim().length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  password: [
    { required: true, message: Message.required("Password") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  accept_policy: [{ required: true, message: "" }],
};

export const SendAttachmentTrackRequest: RequestProps = {
  name: [
    { required: true, message: Message.required("Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  email: [
    { required: true, message: Message.required("Email ID") },
    { type: "email", message: Message.type.email("Email ID") },
  ],
};

export const ContactRequest: RequestProps = {
  name: [
    { required: true, message: Message.required("Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  company_name: [
    { required: true, message: Message.required("Company Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  email: [
    { required: true, message: Message.required("Email ID") },
    { type: "email", message: Message.type.email("Email ID") },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 8,
      message: Message.min(8),
    },
  ],
  phone: [
    { required: true, message: Message.required("Phone") },
    // {
    //   max: 10,
    //   message: Message.max(10),
    // },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        // const mobileNo = getFieldValue("Phone");

        if (value) {
          if (value.trim().length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Phone should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  address1: [
    { required: true, message: Message.required("Address 1") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 250,
      message: Message.max(250),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  address2: [
    { required: true, message: Message.required("Address 2") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 250,
      message: Message.max(250),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  city: [
    { required: true, message: Message.required("City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  state: [
    { required: true, message: Message.required("State") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  subject: [{ required: true, message: Message.required("Subject") }],
  complain_option: [
    { required: true, message: Message.required("Complaint option") },
  ],
  shipment_number: [
    { required: true, message: Message.required("Shipment Number") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        // const mobileNo = getFieldValue("Shipment Number");

        if (value) {
          if (value.trim().length === 11 || value.trim().length === 14) {
            return Promise.resolve();
          } else {
            return Promise.reject("Shipment no. should be 11 or 14 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  mobile_number: [
    { required: true, message: Message.required("Mobile Number") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        // const mobileNo = getFieldValue("Mobile Number");

        if (value) {
          if (value.trim().length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  feedback: [
    { required: true, message: Message.required("Feedback") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 800,
      message: Message.max(800),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  re_captcha: [
    { required: true, message: "It cannot be blank." },
    ({ getFieldValue }) => ({
      validator() {
        const captcha = getFieldValue("captcha");
        const recaptcha = getFieldValue("re_captcha");

        if (recaptcha) {
          if (captcha.trim() === recaptcha) {
            return Promise.resolve();
          } else {
            return Promise.reject("CAPTCHA does not match.");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
};

export const CareerRequest: RequestProps = {
  name: [
    { required: true, message: Message.required("Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  bank_name: [
    { required: true, message: Message.required("Bank Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  branch_name: [
    { required: true, message: Message.required("Branch Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  vat_registered_type: [
    { required: true, message: Message.required("Type of registration") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  vendor_name: [
    { required: true, message: Message.required("Vendor Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  excise: [
    { required: true, message: Message.required("Excise Registration no.") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 15,
      message: Message.max(15),
    },
    {
      min: 15,
      message: "The Field should contain 15 characters.",
    },
  ],
  vattin: [
    { required: true, message: Message.required("VAT /CST TIN no.") },
    {
      pattern: Regex.vattin,
      message: "VAT /CST TIN number is not valid",
    },
  ],
  gst_number: [
    { required: true, message: Message.required("GST Number") },
    {
      pattern:
        /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}$/,
      message: "GST Number is not valid",
    },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  service_tax_no: [
    { required: true, message: Message.required("Service Tax no.") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 15,
      message: Message.max(15),
    },
    {
      min: 15,
      message: "The Field should contain 15 characters.",
    },
  ],
  pan: [
    { required: true, message: Message.required("Pan No.") },
    {
      pattern: Regex.pan,
      message: "Pan No. is not valid",
    },
  ],
  age: [
    { required: true, message: Message.required("Age") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator() {
        const Age = getFieldValue("age");
        if (Age) {
          if (Age <= 99) {
            return Promise.resolve();
          } else {
            return Promise.reject("Above 99 age is not allow");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  email: [
    { required: true, message: Message.required("Email ID") },
    { type: "email", message: Message.type.email("Email ID") },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 8,
      message: Message.min(8),
    },
  ],
  phone: [
    { required: true, message: Message.required("Phone/Mobile") },
    {
      max: 10,
      message: Message.max(10),
    },
    {
      min: 10,
      message: Message.min(10),
    },
    { pattern: Regex.digit, message: "Only digit allow" },
  ],

  contact_number: [
    { required: true, message: Message.required("Contact Number") },
    {
      max: 10,
      message: Message.max(10),
    },
    {
      min: 10,
      message: Message.min(10),
    },
    { pattern: Regex.digit, message: "Only digit allow" },
  ],
  bank_account_no: [
    { required: true, message: Message.required("Bank Account no.") },
    { pattern: Regex.digit, message: "Only digit allow" },
    {
      max: 16,
      message: Message.max(16),
    },
    {
      min: 6,
      message: Message.min(6),
    },
  ],
  ifci_code: [
    { required: true, message: Message.required("IFCI Code") },
    {
      max: 11,
      message: Message.max(11),
    },
    {
      min: 11,
      message: Message.min(11),
    },
  ],
  swift_code: [
    { required: true, message: Message.required("Swift Code") },
    {
      max: 11,
      message: Message.max(11),
    },
    {
      min: 8,
      message: Message.min(8),
    },
  ],
  qualification: [
    { required: true, message: Message.required("Qualification") },
  ],
  applied_for: [
    { required: true, message: Message.required("Applied for") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  location: [
    { required: true, message: Message.required("Address") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 250,
      message: Message.max(250),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  LocationState: [{ required: true, message: Message.required("State") }],
  LocationCity: [{ required: true, message: Message.required("City") }],

  officeLocation: [
    { required: true, message: Message.required("Office Location") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 250,
      message: Message.max(250),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  officeState: [{ required: true, message: Message.required("Office State") }],
  officeCity: [
    { required: true, message: Message.required("Office City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],

  Reference: [
    { required: true, message: Message.required("Reference") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  IsDelivery: [
    { required: true, message: Message.required("Is Delivery Other Courier") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  ReferenceEmpName: [
    { required: true, message: Message.required("Reference Employee Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  gender: [{ required: true, message: Message.required("Gender") }],
  pincode: [
    { required: true, message: Message.required("Pincode") },
    { pattern: Regex.digit, message: "Only digit allow" },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  exp_Year: [
    { required: true, message: Message.required("Experience in Year") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  manpower: [
    { required: true, message: Message.required("Man Power") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 10,
      message: Message.max(10),
    },
  ],
  current_position: [
    { required: true, message: Message.required("Current Business") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 250,
      message: Message.max(250),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  delivery_courier_name: [
    { required: true, message: Message.required("Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  address: [
    { required: true, message: Message.required("Address") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 250,
      message: Message.max(250),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
  state: [
    { required: true, message: Message.required("State") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  city: [
    { required: true, message: Message.required("City") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  total_area: [
    { required: true, message: Message.required("Total Area") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    {
      max: 10,
      message: Message.max(10),
    },
    {
      min: 2,
      message: Message.min(3),
    },
  ],
  selection: [{ required: true, message: Message.required("Selection") }],
  vehicle_type: [
    { required: true, message: Message.required("Type Of Vehicle") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      max: 100,
      message: Message.max(100),
    },
    {
      min: 3,
      message: Message.min(3),
    },
  ],
};

export const NetworkFormRequest: RequestProps = {
  PincodeList: [
    { required: true, message: Message.required("Pincode") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  CenterList: [
    { required: true, message: Message.required("Center Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
};

export const BookignReqFormRequest: RequestProps = {
  FromPincode: [
    { required: true, message: Message.required("From Pincode") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  ToPincode: [
    { required: true, message: Message.required("To Pincode") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
  PickupArea: [
    { required: true, message: Message.required("Pickup Area") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      min: 3,
      message: Message.min(3),
    },
    {
      max: 250,
      message: Message.max(250),
    },
  ],
  DeliveryArea: [
    { required: true, message: Message.required("Delivery Area") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      min: 3,
      message: Message.min(3),
    },
    {
      max: 250,
      message: Message.max(250),
    },
  ],
  SenderName: [
    { required: true, message: Message.required("Sender Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      min: 3,
      message: Message.min(3),
    },
    {
      max: 100,
      message: Message.max(100),
    },
  ],
  SenderAddress: [
    { required: true, message: Message.required("Sender Address") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    {
      min: 3,
      message: Message.min(3),
    },
    {
      max: 250,
      message: Message.max(250),
    },
  ],
  SenderMobileNo: [
    { required: true, message: Message.required("Sender Mobile No") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator() {
        const SenderMobileNo = getFieldValue("SenderMobileNo");
        if (SenderMobileNo) {
          if (SenderMobileNo.length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  senderemail: [
    { required: true, message: Message.required("Sender email") },
    { type: "email", message: Message.type.email("Email ID") },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  TypeID: [{ required: true, message: Message.required("Document Type ID") }],
  ServiceTypeID: [
    { required: true, message: Message.required("Service Type") },
  ],
  TravelBy: [{ required: true, message: Message.required("Travel Type") }],
  Weight: [{ required: true, message: Message.required("Weight") }],
  Height: [
    { required: true, message: Message.required("Height") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
  Width: [
    { required: true, message: Message.required("Width") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
  Length: [
    { required: true, message: Message.required("Length") },
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
  ],
  Content: [
    { required: true, message: Message.required("Content") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  RefName: [
    // { required: true, message: Message.required("Reference Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 100, message: Message.max(100) },
  ],
  RefMobile: [
    {
      pattern: Regex.digit,
      message: "Only digit allow",
    },
    ({ getFieldValue }) => ({
      validator() {
        const RefMobile = getFieldValue("RefMobile");
        if (RefMobile) {
          if (RefMobile.length === 10) {
            return Promise.resolve();
          } else {
            return Promise.reject("Mobile Number should be 10 Digit");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  Remark: [
    // { required: true, message: Message.required("Reference Name") },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
    { min: 3, message: Message.min(3) },
    { max: 800, message: Message.max(800) },
  ],
  OTP: [
    { required: true, message: Message.required("OTP") },
    { max: 6, message: Message.max(6) },
    {
      pattern: Regex.nonWhiteSpace,
      message: "No whitespace allow at the beginning or end",
    },
  ],
};
