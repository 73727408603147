import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class TrackCourierStore {
  public track_shipment_data: any;
  public track_send_otp: any;
  public multiple_tracking: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  public checkMultipleTracking = (e: boolean): void => {
    this.multiple_tracking = e;
  };

  // State LOV
  public getShipmentData = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.TRACK_SHIPMENT.GET_SHIPMENT_DATA, data)
      .then(({ data }) => {
        this.track_shipment_data = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getTrackingOTP = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.SEND_OTP, data)
      .then(({ data }) => {
        this.track_send_otp = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getVerifyOTP = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.VERIFY_OTP, data)
      .then(({ data }) => {
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getTrackingAttachment = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.SEND_ATTACHMENT, data)
      .then(({ data }) => {
        if (data.data.url) {
          window.location.href = data.data.url;
        }
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };
}
