import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class TravelfreeStore {
  public travelfree_form_data: any | null = JSON.parse(
    localStorage.getItem("travelfree-form") as any | null
  );
  public getBaggageList: any = {};
  public pickup_channel_partner_data: any;
  public delivery_channel_partner_data: any;
  public server_date_time: any;
  public payment_info: any;
  public travelfree_pickup_pincode_data: any;
  public travelfree_delivery_pincode_data: any;
  public travelfree_pick_up_charge: any;
  public travelfree_data: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public getTravelFreeData = async (): Promise<any> => {
    return await axios.post(API_URL.TRAVELFREE_FORM).then(({ data }) => {
      this.travelfree_data = data.data;
    });
  };

  public travelfreePickupPincodeCheck = async (payload: any): Promise<any> => {
    this.travelfree_pickup_pincode_data = null;
    return await axios
      .post(API_URL.TRAVELFREE_PINCODE_VERIFICATION, payload)
      .then(({ data }) => {
        this.travelfree_pickup_pincode_data = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public travelfreeDeliveryPincodeCheck = async (
    payload: any
  ): Promise<any> => {
    this.travelfree_delivery_pincode_data = null;
    return await axios
      .post(API_URL.TRAVELFREE_PINCODE_VERIFICATION, payload)
      .then(({ data }) => {
        console.log("data", data);
        this.travelfree_pick_up_charge = data.pickup_charge;
        this.travelfree_delivery_pincode_data = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public baggagesList = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.TRAVELFREE_SELECT_BAG, payload)
      .then(({ data }) => {
        this.getBaggageList = data.data.map((bag: any) => {
          return { ...bag, quantity: 0 };
        });

        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchPickupChannelPartner = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.TRAVELFREE_CHANNEL_PARTNER, data)
      .then(({ data }) => {
        this.pickup_channel_partner_data = data.data;
        this.server_date_time = data.server_date;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchDeliveryChannelPartner = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.TRAVELFREE_CHANNEL_PARTNER, data)
      .then(({ data }) => {
        this.delivery_channel_partner_data = data.data;
        this.server_date_time = data.server_date;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchPaymentInfo = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.TRAVELFREE_PAYMENT_INFO, data)
      .then(({ data }) => {
        this.payment_info = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public saveAsDraft = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.TRAVELFREE_SAVE_AS_DRAFT, payload)
      .then(({ data }) => {
        return data;
      });
  };
}
