import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class PrivacyPolicyStore {
  public privacyPolicyData: any;
  public innodelPrivacyPolicyData: any;
  public disclaimerData: any;
  public carriageData: any;
  public OndcDisclosureData: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public privacyPolicyAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.PRIVACYPOLICY).then(({ data }) => {
      this.privacyPolicyData = data.data;
    });
  };

  public disclaimerAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.DISCLAIMER).then(({ data }) => {
      this.disclaimerData = data.data;
    });
  };

  public carriageAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.CARRIAGE).then(({ data }) => {
      this.carriageData = data.data;
    });
  };

  public innodelPrivacyPolicyAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.INNODEL_PRIVACYPOLICY).then(({ data }) => {
      this.innodelPrivacyPolicyData = data.data;
    });
  };

  public ONDCDisclosureAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.ONDC_DISCLOSURE).then(({ data }) => {
      this.OndcDisclosureData = data.data;
    });
  };
}
