import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { b64toBlob } from "../../utils/GlobalFunction";

export default class EbookStore {
  public ebook_pickup_pincode_data: any;
  public ebook_delivery_pincode_data: any;
  public ebook_mobile_exist: any;
  public rate_card_data: any;
  // public ebook_form_data: any = {};
  public ebook_form_data: any | null = JSON.parse(
    localStorage.getItem("ebook-form") as any | null
  );
  public pickup_channel_partner_data: any;
  public delivery_channel_partner_data: any;
  public server_date_time: any;
  public payment_info: any;
  public transaction_info: any;
  public ebooking_form_data: any;
  public pending_document_data: any;
  public pending_document_error: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public getEbookingFormData = async (): Promise<any> => {
    return await axios.post(API_URL.EBOOKING_FORM).then(({ data }) => {
      this.ebooking_form_data = data.data;
    });
  };

  public ebookPickupPincodeCheck = async (payload: any): Promise<any> => {
    this.ebook_pickup_pincode_data = null;
    return await axios
      .post(API_URL.EBOOKING_PINCODE_VERIFICATION, payload)
      .then(({ data }) => {
        this.ebook_pickup_pincode_data = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public ebookDeliveryPincodeCheck = async (payload: any): Promise<any> => {
    this.ebook_delivery_pincode_data = null;
    return await axios
      .post(API_URL.EBOOKING_PINCODE_VERIFICATION, payload)
      .then(({ data }) => {
        this.ebook_delivery_pincode_data = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public checkEbookMobileNO = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.EBOOK_MOBILE_VERIFICATION, payload)
      .then(({ data }) => {
        this.ebook_mobile_exist = data.exists;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getEbookOTP = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.SEND_OTP, data)
      .then(({ data }) => {
        // this.track_send_otp = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getVerifyOTP = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.VERIFY_OTP, data)
      .then(({ data }) => {
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchRateCard = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.EBOOK_FETCH_RATECARD, data)
      .then(({ data }) => {
        this.rate_card_data = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchPickupChannelPartner = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.CHANNEL_PARTNER, data)
      .then(({ data }) => {
        this.pickup_channel_partner_data = data.data;
        this.server_date_time = data.server_date;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchDeliveryChannelPartner = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.CHANNEL_PARTNER, data)
      .then(({ data }) => {
        this.delivery_channel_partner_data = data.data;
        this.server_date_time = data.server_date;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public fetchPaymentInfo = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.PAYMENT_INFO, data)
      .then(({ data }) => {
        this.payment_info = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public saveAsDraft = async (payload: any): Promise<any> => {
    return await axios.post(API_URL.SAVE_AS_DRAFT, payload).then(({ data }) => {
      return data;
    });
  };

  public getTransactionDetail = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.GET_TRANSACTION, payload)
      .then(({ data }) => {
        this.transaction_info = data;
        return data;
      });
  };

  public printBookingOrder = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.PRINT_BOOKING_ORDER, data)
      .then(({ data }) => {
        const file = b64toBlob(data.blob_content, "application/pdf", 512);
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        //pdfWindow.location.href = fileURL

        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public printLabel = async (data: any): Promise<any> => {
    return await axios
      .post(API_URL.PRINT_LABEL, data)
      .then(({ data }) => {
        const file = b64toBlob(data.blob_content, "application/pdf", 512);
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        //pdfWindow.location.href = fileURL
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getPendingDocumentData = async (docNo: any): Promise<any> => {
    return await axios
      .get(`${API_URL.PENDINGPAYMENTDETAILS}?doc=${docNo}`)
      .then(({ data }) => {
        this.pending_document_data = data.data;
      })
      .catch(({ data }) => {
        this.pending_document_error = data;
      });
  };
}
