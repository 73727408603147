import { makeAutoObservable } from "mobx";
import axios from "axios";
import { userType, headerType, footerType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";

export default class AuthStore {
  // Variables
  public user?: userType;
  public token?: string;
  public app_loading: boolean;
  public email?: string;
  private rootStore: RootStore;
  public headerData?: headerType;
  public footerData?: footerType;
  public loginType?: string;
  public isMaintenance?: boolean = false;
  public isHeader?: boolean = false;
  public isFooter?: boolean = false;
  public menuList?: any;

  constructor() {
    this.app_loading = true;
    this.rootStore = new RootStore();
    makeAutoObservable(this);
  }

  // Initiate Application Functions
  public InitializeApp = (): void => {
    this.rootStore.setAxiosBaseUrl();
    this.rootStore.setAxiosInterceptor();
    this.rootStore.setNotificationConfig();
    this.setupHeaders(localStorage.getItem("token") ?? "");
    this.headerAPICall();
    this.footerAPICall();
  };

  public setupHeaders = (access_token?: string): void => {
    this.setAppLoading(true);
    this.rootStore
      .setAxiosHeaders(access_token)
      .then((token) => {
        this.setToken(token);
        this.setLoginType(localStorage.getItem("login_type") ?? "");
        // this.fetchAuthUser();
        this.setAppLoading(false);
      })
      .catch(() => {
        this.setToken();
        this.setLoginType("");
        this.setUser();
        this.setAppLoading(false);
      });
  };

  // Setter Functions
  public setIsHeader = (value?: boolean): void => {
    this.isHeader = value;
  };
  public setIsFooter = (value?: boolean): void => {
    this.isFooter = value;
  };
  public setUser = (value?: userType): void => {
    this.user = value;
  };
  public setToken = (value?: string): void => {
    this.token = value;
  };
  public setLoginType = (value?: string): void => {
    this.loginType = value;
  };
  public setAppLoading = (value: boolean): void => {
    this.app_loading = value;
  };
  public setEmail = (value: string): void => {
    this.email = value;
  };

  // Header API call
  public headerAPICall = async (): Promise<any> => {
    return await axios
      .get(API_URL.HEADER)
      .then(({ data }) => {
        this.headerData = data.data.header;
        this.isMaintenance = false;
        this.isHeader = true;
        this.menuList = data?.data?.header?.menu;
      })
      .catch(({ data }) => {
        this.isMaintenance = true;
        return Promise.reject(data);
      });
  };

  // Header API call
  public footerAPICall = async (): Promise<any> => {
    return await axios
      .get(API_URL.FOOTER)
      .then(({ data }) => {
        this.footerData = data.data.footer;
        this.isMaintenance = false;
        this.isFooter = true;
      })
      .catch(({ data }) => {
        this.isMaintenance = true;
        return Promise.reject(data);
      });
  };

  // API Functions
  // public doLogin = async (payload: doLoginType): Promise<any> => {
  //   return await axios
  //     .post(API_URL.LOGIN_WITH_EMAIL, payload)
  //     .then(({ data }) => {
  //       this.setupHeaders(data.access_token);
  //       return data;
  //     })
  //     .catch(({ data }) => {
  //       this.setToken();
  //       this.setUser();
  //       return Promise.reject(data);
  //     });
  // };

  public doEbookLogin = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.EBOOK_LOGIN, payload)
      .then(({ data }) => {
        localStorage.setItem("user_mobile_no", data.data.mobile_no);
        localStorage.setItem("login_type", "ebooking");
        this.setupHeaders(data.data.session);
        return data;
      })
      .catch(({ data }) => {
        this.setToken();
        // this.setUser();
        return Promise.reject(data);
      });
  };

  public doEbookRegister = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.EBOOK_REGISTRATION, payload)
      .then(({ data }) => {
        this.setupHeaders(data.data.session);
        localStorage.setItem("login_type", "ebooking");
        return data;
      })
      .catch(({ data }) => {
        this.setToken();
        // this.setUser();
        return Promise.reject(data);
      });
  };

  public doClientLogin = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.CLIENT_LOGIN, payload)
      .then(({ data }) => {
        localStorage.setItem("client_detail", JSON.stringify(data.data));
        localStorage.setItem("login_type", "client");
        this.setupHeaders(data.data.session);
        return data;
      })
      .catch(({ data }) => {
        this.setToken();
        // this.setUser();
        return Promise.reject(data);
      });
  };

  // public fetchAuthUser = async (): Promise<any> => {
  //   return await axios
  //     .post(API_URL.ME)
  //     .then(({ data }) => {
  //       this.setUser(data.user);
  //       return data;
  //     })
  //     .catch(({ data }) => {
  //       this.setToken();
  //       this.setUser();
  //       return Promise.reject(data);
  //     })
  //     .finally(() => this.setAppLoading(false));
  // };
}
