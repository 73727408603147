import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStore from "../../../store";

import PageBanner from "../../../components/PageBanner";
import { NewsletterBreadcrumb } from "../../../../src/config/BreadcrumbConfig";
import SeoHeader from "../../../components/SeoHeader";
import { Link } from "react-router-dom";
import { textToUrl } from "../../../utils/GlobalFunction";

const Newsletter: React.FC = observer(() => {
  const {
    NEWSLETTERSTORE: { newsletterAPICall, newsletterData },
  } = useStore();
  const pageBannerData = newsletterData?.slider[0];
  const newsletterPageData = newsletterData?.sections?.page_content;
  const newsletterContent = newsletterData?.sections?.page_content?.tags;

  const [activeNews, setActiveNews] = useState<Number>();

  useEffect(() => {
    newsletterAPICall();
  }, [newsletterAPICall]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (
      window.location.pathname === "/newsletter/" ||
      window.location.pathname === "/newsletter"
    ) {
      setActiveNews(0);
    }
  }, [newsletterContent]);
  const SeoData = {
    title:
      newsletterData?.seo_data?.title ??
      "Newsletter from Shree Maruti Courier Pvt. Ltd.",
    description:
      newsletterData?.seo_data?.description ??
      "Shree Maruti provides Travel Free process for online parcel booking, same-day door-to-door delivery service, and the best home pickup courier service in India.",
    keywords:
      newsletterData?.seo_data?.keywords ??
      "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
    url: newsletterData?.seo_data?.url ?? "/join-us",
    ogTitle:
      newsletterData?.seo_data?.og_title ??
      "Newsletter from Shree Maruti Courier Pvt. Ltd.",
    ogImage: newsletterData?.seo_data?.og_image,
  };
  return (
    <>
      <SeoHeader seoData={SeoData} />
      <PageBanner
        backgroundImg={pageBannerData?.backgroud_image_url}
        heading1={pageBannerData?.heading_1}
        heading2={pageBannerData?.heading_2}
        heading3={pageBannerData?.heading_3}
        buttonTitle={pageBannerData?.button_title}
        buttonLink={pageBannerData?.button_link}
        breadcrumbData={NewsletterBreadcrumb.path}
      />

      <section className="privacyPolicy newsletterSection pv-64">
        <div className="container">
          <div className="sc_title text-center">
            <div
              className="main_title"
              data-title={newsletterPageData?.heading ?? ""}
            >
              <h3>{newsletterPageData?.heading ?? ""}</h3>
            </div>
          </div>

          <div className="tabs_wrapper">
            <div className="container pt-64">
              <ul className="tab_wrap">
                {newsletterContent?.length > 0
                  ? newsletterContent?.map((item: any, index: number) => {
                      return (
                        <li
                          key={index}
                          className={
                            window.location.pathname ===
                              `/newsletter/${textToUrl(item.heading)}/` ||
                            activeNews === index
                              ? "tab active"
                              : "tab"
                          }
                          onClick={() => {
                            setActiveNews(index);
                            window.scrollTo({
                              top: window?.visualViewport?.height,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <Link to={`/newsletter/${textToUrl(item.heading)}/`}>
                            {item.date}
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>

            {newsletterContent?.length > 0
              ? newsletterContent?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={
                        window.location.pathname ===
                          `/newsletter/${textToUrl(item.heading)}/` ||
                        activeNews === index
                          ? "tabs_container active"
                          : "tabs_container"
                      }
                    >
                      <div className="newsletterContent">
                        <h2 className="heading">{item?.heading}</h2>
                        <h3 className="sub_heading">{item?.sub_heading}</h3>
                        {item?.titles?.length > 0 ? (
                          <div className="titles">
                            {item?.titles?.map(
                              (text: string, index: number) => {
                                return (
                                  <div key={index} className="box">
                                    {text}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : null}
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{ __html: item?.content }}
                        ></div>
                      </div>
                    </div>
                  );
                })
              : "No Newsletter"}
          </div>
        </div>
      </section>
    </>
  );
});

export default Newsletter;
