import React, { useEffect } from "react";
import { Layout } from "antd";
// import useStore from "../../../store";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import HeaderView from "./Header/HeaderView";
import FooterView from "./Footer/FooterView";
import useStore from "../../../store";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";

const AuthLayout: React.FC<any> = observer(() => {
  const location = useLocation();
 
  const navigate = useNavigate();
  const {
    AUTH: { isMaintenance, headerData },
  } = useStore();

  useEffect(() => {
    // if (pathname + search === "/dw/?a=R98s3K") {
    //   window.location.replace(
    //     "https://play.google.com/store/apps/details?id=com.maruticourier.android"
    //   );
    // } else if (pathname + search === "/dw/?a=S79s3P") {
    //   window.location.replace(
    //     "https://apps.apple.com/in/app/shree-maruti-courier/id658296993"
    //   );

    // }
    if (headerData === null) {
      navigate("/");
    }
  }, [navigate, headerData]);

  



  return isMaintenance ? (
    <PageSpinner />
  ) : (
    <Layout className={`main__page__wrapper has__header`}>
      <>
        <HeaderView />
        <Layout.Content
          className={`main__page__content ${location.pathname === "/" ? "home" : "inner"
            }`}
        >
          <Outlet />
        </Layout.Content>
        <FooterView />
      </>
    </Layout>
  );
});

export default AuthLayout;
