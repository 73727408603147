import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class JoinUsStore {
  public careerData: any;
  public dropdown_state_list: any;
  public dropdown_city_list: any;
  public dropdown_pincode_list: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public careerAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.CAREER.CAREER_DATA).then(({ data }) => {
      this.careerData = data.data;
    });
  };

  // State LOV
  public getStateList = async (): Promise<any> => {
    return await axios
      .post(API_URL.STATE_LOV)
      .then(({ data }) => {
        this.dropdown_state_list = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // City LOV
  public getCityList = async (stateId: any): Promise<any> => {
    return await axios
      .post(API_URL.CITY_LOV, stateId)
      .then(({ data }) => {
        this.dropdown_city_list = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // Pincode LOV
  public getStatePincodeList = async (stateId: any): Promise<any> => {
    return await axios
      .get(API_URL.PINCODE_BY_STATE_LOV + stateId)
      .then(({ data }) => {
        this.dropdown_pincode_list = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // Career Form submit
  public submitCareerForm = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.CAREER.CAREERFORM, payload)
      .then(({ data }) => {
        return data;
      });
  };

  // Career Form submit
  public submitChannelPartnerForm = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.CHANNELPARTNER.CP_FORM, payload)
      .then(({ data }) => {
        return data;
      });
  };

  // Delivery Partner Form submit
  public submitDeliveryPartnerForm = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.DELIVERYPARTNER_FORM, payload)
      .then(({ data }) => {
        return data;
      });
  };
}
