import { useEffect, useState } from "react";
import BannerImg from "../../../assets/images/banner-img-04.png";
import TrackCourier from "../TrackCourier";
const TrackingURL = () => {
  const [trackingData, setTrackingData] = useState<any>();
  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const data = {
      type: 1,
      shipment_number: searchParams.get("d"),
    };
    localStorage.setItem("trackShipmentData", JSON.stringify(data));
    setTrackingData(data);
  }, []);

  return trackingData ? (
    <TrackCourier />
  ) : (
    <div
      className="fullPageBG"
      style={{ backgroundImage: `url(${BannerImg})` }}
    >
      <section
        className="bookingDetailPage pv-64"
        style={{ backgroundColor: "rgba(0,0,0,0.45)", paddingTop: "200px" }}
      >
        <div className="container">
          <div className="sc_title text-center">
            <div className="main_title" data-title="Tracking">
              <h3>Tracking</h3>
            </div>
          </div>
          <h2 className="text-center mt-30">No data found!</h2>
        </div>
      </section>
    </div>
  );
};

export default TrackingURL;
