import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Form, Button, InputNumber, Modal } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Scrollbars } from "react-custom-scrollbars";
// import SVGSprite from "../../../../assets/images/icon/eBook-sprite.svg";
import { TravelfreeFormRequest } from "../../../../requests/PagesRequest";
// import Message from "../../../../config/Message";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import SeoHeader from "../../../../components/SeoHeader";
// import selectBaggage from '../selectBaggage.json';

const SelectBag: React.FC<any> = observer(({ setCurrent, current }: any) => {
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  const {
    TRAVELFREESTORE,
    TRAVELFREESTORE: {
      baggagesList,
      getBaggageList,
      getTravelFreeData,
      travelfree_data,
    },
  } = useStore();
  const [saving, setSaving] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [serviceType, setServiceType] = useState<string>("economy");
  const [selectedBaggage, setSelectedBaggage] = useState<any>();
  const [totalPrice, setTotalPrice] = useState<Number>(0);
  const [updateValueOfItem, setUpdateValueOfItem] = useState<any>([1]);
  const [termModal, setTermModal] = useState<boolean>(false);

  const getBaggagesList = useCallback(() => {
    const pickupPin: any = JSON.parse(
      localStorage.getItem("travelfree_pickup_pin") as any | null
    );
    const deliveryPin: any = JSON.parse(
      localStorage.getItem("travelfree_delivery_pin") as any | null
    );

    const payload: any = {
      pincode_type: pickupPin?.pincode_type,
      pickup_pincode: pickupPin?.pincode,
      delivery_pincode: deliveryPin?.pincode,
    };
    baggagesList(payload);
  }, [baggagesList]);

  useEffect(() => {
    getBaggagesList();
  }, [getBaggagesList]);

  const updateQuantity = (index: any, type: string) => {
    if (type === "inc") {
      if (selectedBaggage[index].quantity < 10) {
        selectedBaggage[index].quantity++;
      }
    } else if (type === "dec") {
      if (selectedBaggage[index].quantity > 0) {
        selectedBaggage[index].quantity--;
      }
    }
    const totalPrices = selectedBaggage
      .map((bag: any) => {
        let per_bag_price =
          serviceType === "economy" ? bag.price_economy : bag.price_express;
        return per_bag_price * bag.quantity;
      })
      .reduce((total: any, price: any) => total + price, 0);
    setSelectedBaggage([...selectedBaggage]);
    setTotalPrice(totalPrices);
    setDisabled(false);
  };

  const findTab = (val: any) => {
    if (val === 2) {
      setServiceType("express");
    } else {
      setServiceType("economy");
    }
    let baggage = selectedBaggage.map((bag: any) => {
      return { ...bag, quantity: bag.quantity ?? 0 };
    });
    const totalPrices = baggage
      .map((bag: any) => {
        let per_bag_price = val === 1 ? bag.price_economy : bag.price_express;
        return per_bag_price * bag.quantity;
      })
      .reduce((total: any, price: any) => total + price, 0);
    setSelectedBaggage([...baggage]);
    setTotalPrice(totalPrices);
    // setTotalPrice(0);
  };

  const handleSubmit = (data: any) => {
    setSaving(true);
    setTermModal(true);

    if (data) {
      let payload: any = data;
      payload.service_type = data.service_type;
      payload.service_type_name =
        data.service_type === 2 ? "express" : "economy";
      payload.items = selectedBaggage.map((bag: any) => {
        return { ...bag };
      });
      payload.totalPrice = totalPrice;
      payload.house_hold_items = data.house_hold_items ?? [0];
      payload.goods_value = data.goods_value ?? "0.00";

      TRAVELFREESTORE.travelfree_form_data = {
        ...TRAVELFREESTORE.travelfree_form_data,
        ...payload,
      };
      localStorage.setItem(
        "travelfree-form",
        JSON.stringify(TRAVELFREESTORE.travelfree_form_data)
      );
      setSaving(false);
    }
  };

  const agreedTerm = (data: any) => {
    form.validateFields().then(() => {
      if (data === 1 || data?.agreed_radio === 1) {
        setCurrent(current + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setTermModal(false);
      }
    });
  };

  const closeAgreedTerm = () => {
    setTermModal(false);
  };

  const getValueOfItems = (e: any) => {
    setUpdateValueOfItem(e);
  };

  useEffect(() => {
    let getLocalData: any | null = JSON.parse(
      localStorage.getItem("travelfree-form") as any | null
    );
    let updateData: any =
      TRAVELFREESTORE?.travelfree_form_data?.length > 0
        ? TRAVELFREESTORE?.travelfree_form_data
        : getLocalData;
    if (updateData) {
      setDisabled(false);
    }

    updateData?.service_type
      ? setServiceType(updateData?.service_type === 2 ? "express" : "economy")
      : setServiceType("economy");
    updateData?.items
      ? setSelectedBaggage([...updateData?.items])
      : getBaggageList?.length > 0
      ? setSelectedBaggage([...getBaggageList])
      : setSelectedBaggage([]);
    updateData?.totalPrice && setTotalPrice(updateData?.totalPrice);
    updateData?.house_hold_items &&
      setUpdateValueOfItem(updateData?.house_hold_items);
    form.setFieldsValue({
      service_type: updateData?.service_type ?? 1,
      content: updateData?.content ?? "",
      house_hold_items: updateData?.house_hold_items ?? [1],
      goods_value: updateData?.goods_value ?? "0.00",
    });
  }, [form, TRAVELFREESTORE, getBaggageList]);

  const SeoData = {
    title: travelfree_data?.seo_data?.title,
    description: travelfree_data?.seo_data?.description,
    keywords: travelfree_data?.seo_data?.keywords,
    url: travelfree_data?.seo_data?.url,
    ogTitle: travelfree_data?.seo_data?.og_title,
    ogImage: travelfree_data?.seo_data?.og_image,
  };

  useEffect(() => {
    getTravelFreeData();
  }, [getTravelFreeData]);

  return (
    <>
      <SeoHeader seoData={SeoData} />
      <div className="travelFreeForm">
        <p>
          Excess Baggage Service (Travel Free) is one of our service offerings
          which is the most important product in the current scenario where
          passengers can travel hassle free without carrying their baggage. We
          provide doorstep pick-up and delivery service at destination
          locations. We intend to provide a cost effective solution for
          passengers for their baggage.
        </p>
        <p>
          Excess baggage will travel through air and surface both, based on
          customers requirement. customers can also easily track their baggage
          status at any time.
        </p>
        <FormBox
          form={form}
          id="SelectBagForm"
          onFinish={handleSubmit}
          className="mt-40"
        >
          <div className="ServiceBox">
            <div className="input_group serviceType">
              <InputBox.Radio
                label="Service Type"
                initialValue={1}
                name="service_type"
                required
                options={{
                  list: [
                    { id: 1, name: "Economy (By Surface)" },
                    { id: 2, name: "Express (By Air)" },
                  ],
                  valueKey: "id",
                  textKey: "name",
                }}
                onChange={(e) => findTab(e.target.value)}
              />
            </div>
          </div>

          <table className="baggageTable responsive">
            <thead>
              <tr>
                <th>Quantity</th>
                <th></th>
                <th>Price Per Bag</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4} height={"20px"}>
                  {" "}
                </td>
              </tr>
              {selectedBaggage?.length > 0 &&
                selectedBaggage.map((data: any, index: any) => {
                  let per_bag_price =
                    serviceType === "economy"
                      ? data.price_economy
                      : data.price_express;
                  return (
                    <tr
                      key={index}
                      className={data.quantity > 0 ? "active" : ""}
                    >
                      <td data-label="Quantity">
                        <Button
                          className="incDec autoWidth autoHeight"
                          onClick={() => updateQuantity(index, "dec")}
                        >
                          -
                        </Button>

                        <InputNumber
                          defaultValue={0}
                          controls={false}
                          className="qty"
                          value={selectedBaggage[index].quantity}
                          max={10}
                          min={0}
                          readOnly
                        />
                        <Button
                          className="incDec autoWidth autoHeight"
                          onClick={() => updateQuantity(index, "inc")}
                        >
                          +
                        </Button>
                      </td>
                      <td className="details">
                        {`${data.title} upto ${data.weight} Kgs`}
                        <br />
                        {data.description}
                      </td>
                      <td data-label="Price Per Bag">₹{per_bag_price}</td>
                      <td data-label="Total">
                        ₹ {per_bag_price * data.quantity}.00
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="ServiceBox">
            <Row gutter={20} className="input_group_blk" align={"middle"}>
              <Col xs={24} md={24} lg={7} xl={9}>
                <div className="input_group serviceType">
                  <InputBox.Text
                    label="Content"
                    placeholder="e.g Clothes, Books, etc."
                    name="content"
                    required
                    rules={TravelfreeFormRequest.content}
                  />
                </div>
              </Col>
              <Col xs={24} md={12} lg={10} xl={8}>
                <InputBox.Checkbox
                  // buttonstyle='solid'
                  className="acceptNoValue"
                  name="house_hold_items"
                  options={{
                    list: [
                      {
                        id: 1,
                        name: "House Hold Items Hence No Commercial Value",
                      },
                    ],
                  }}
                  onChange={(e) => {
                    getValueOfItems(e);
                  }}
                />

                {updateValueOfItem?.[0] === 1 ? null : (
                  <div className="input_group">
                    <InputBox.Text
                      name="goods_value"
                      label="Good Value Rs"
                      placeholder="In INR"
                      required
                      rules={TravelfreeFormRequest.goods_value}
                      maxLength={7}
                    />
                  </div>
                )}
              </Col>
              <Col xs={24} md={12} lg={7} xl={7} className="text-right">
                <div className="grandTotal">{`Total: ${totalPrice}.00`}</div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={{ span: 24 }} className="text-right col_btn submitBtn">
              <Button
                className="btn btn_secondary"
                htmlType="submit"
                disabled={disabled}
                loading={saving}
              >
                Next
              </Button>
            </Col>
          </Row>
        </FormBox>
      </div>

      <Modal
        className="primaryModal termsAndConditions"
        open={termModal}
        onCancel={closeAgreedTerm}
        centered
        title={"Terms and Conditions of Carriage"}
        footer={
          <FormBox
            form={modalForm}
            id="agreedTermForm"
            onFinish={agreedTerm}
            // onChange={handleChange}
          >
            <div className="buttonGroup">
              <InputBox.Radio
                name="agreed_radio"
                required
                rules={TravelfreeFormRequest.termandconditions}
                options={{
                  list: [{ id: 1, name: "I Accept all Terms and Conditions." }],
                  valueKey: "id",
                  textKey: "name",
                }}
              />
              <Button type="primary" htmlType="submit">
                Continue
              </Button>
            </div>
          </FormBox>
        }
      >
        <Scrollbars
          className="customScrollbars"
          // style={{ maxHeight: "400px" }}
          universal
          autoHeight
          autoHeightMax={320}
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          <div
            className="fixedContent"
            dangerouslySetInnerHTML={{
              __html:
                travelfree_data &&
                travelfree_data?.sections?.page_content?.terms_and_conditions,
            }}
          ></div>
        </Scrollbars>
      </Modal>
    </>
  );
});

export default SelectBag;
