import React, { useState } from "react";
import { observer } from "mobx-react";
import { Col, Divider, Row, Spin } from "antd";
import { InputBox } from "../../../../../components/AntdAddons";
import { CareerRequest } from "../../../../../requests/PagesRequest";
import useStore from "../../../../../store";

const AgencyForm: React.FC<any> = observer(({ form }) => {
  const { JOINUSSTORE } = useStore();
  const [fetchState, setFetchState] = useState<boolean>(true);
  const [isRegistered, setIsRegistered] = useState<string>("0");
  const [isHaveGST, setIsHaveGST] = useState<string>("0");

  const getStateID = (stateName: string) => {
    const state = JOINUSSTORE?.dropdown_state_list?.find(
      (state: any) => state.StateName === stateName
    );
    return state ? state.StateID : null;
  };

  const handleStateChange = (stateName: any) => {
    form.setFieldsValue({ city: null });
    const stateID = getStateID(stateName);
    if (stateID && stateID !== undefined) {
      const data = { state_id: parseInt(stateID) };
      JOINUSSTORE.getCityList(data);
    }
  };

  return (
    <Row gutter={15}>
      <Col xs={24} md={12} lg={8}>
        <InputBox.Text hidden name="form_type" value="agency" />
        <div className="input_group">
          <InputBox.Text
            label="Vendor Name"
            name="vendor_name"
            required
            rules={CareerRequest.vendor_name}
          />
        </div>
      </Col>

      <Col xs={24}></Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Registered Address"
            name="registered_address"
            required
            rules={CareerRequest.address}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Select
            label="State"
            name="registered_state"
            required
            rules={CareerRequest.state}
            showSearch
            onFocus={() =>
              fetchState &&
              JOINUSSTORE.getStateList().then(() => setFetchState(false))
            }
            notFoundContent={
              fetchState ? <Spin size="small" /> : "No Record Found."
            }
            onChange={(e) => handleStateChange(e)}
            options={{
              list: JOINUSSTORE.dropdown_state_list,
              valueKey: "StateName",
              textKey: "StateName",
            }}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="City"
            name="registered_city"
            required
            rules={CareerRequest.city}
          />
        </div>
        {/* <div className="input_group">
          <InputBox.Select
            label="City"
            name="registered_city"
            required
            showSearch
            rules={CareerRequest.city}
            notFoundContent={
              JOINUSSTORE?.dropdown_city_list ? (
                <Spin size="small" />
              ) : (
                "No Record Found."
              )
            }
            options={{
              list: JOINUSSTORE?.dropdown_city_list,
              valueKey: "CityName",
              textKey: "CityName",
            }}
          />
        </div> */}
      </Col>

      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Communication Address"
            name="communication_address"
            required
            rules={CareerRequest.address}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Select
            label="State"
            name="communication_state"
            required
            rules={CareerRequest.state}
            showSearch
            onFocus={() =>
              fetchState &&
              JOINUSSTORE.getStateList().then(() => setFetchState(false))
            }
            notFoundContent={
              fetchState ? <Spin size="small" /> : "No Record Found."
            }
            onChange={(e) => handleStateChange(e)}
            options={{
              list: JOINUSSTORE.dropdown_state_list,
              valueKey: "StateName",
              textKey: "StateName",
            }}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="City"
            name="communication_city"
            required
            rules={CareerRequest.city}
          />
        </div>
        {/* <div className="input_group">
          <InputBox.Select
            label="City"
            name="communication_city"
            required
            showSearch
            rules={CareerRequest.city}
            notFoundContent={
              JOINUSSTORE?.dropdown_city_list ? (
                <Spin size="small" />
              ) : (
                "No Record Found."
              )
            }
            options={{
              list: JOINUSSTORE?.dropdown_city_list,
              valueKey: "CityName",
              textKey: "CityName",
            }}
          />
        </div> */}
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="PAN No."
            name="pan_no"
            required
            rules={CareerRequest.pan}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Excise Registration No."
            name="excise_registration_no"
            required
            rules={CareerRequest.excise}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Service Tax No"
            name="service_tax_no"
            required
            rules={CareerRequest.service_tax_no}
          />
        </div>
      </Col>
      <Col xs={24}>
        <Divider />
      </Col>
      <Col xs={24}>
        <div className="input_group mb-0">
          <h3 className="pb-0 mb-10">VAT & CST Details</h3>
          <InputBox.Radio
            label="Registered / Unregistered"
            name="vat_registered"
            required
            rules={CareerRequest.selection}
            options={{
              list: [
                { id: "1", value: "Yes" },
                { id: "0", value: "No" },
              ],
              valueKey: "id",
              textKey: "value",
            }}
            onChange={(e: any) => {
              setIsRegistered(e?.target?.value);
            }}
          />
        </div>
      </Col>
      {isRegistered === "1" && (
        <>
          <Col xs={24} md={12} lg={8}>
            <div className="input_group">
              <InputBox.Select
                label="State"
                name="vat_registered_state"
                required
                rules={CareerRequest.state}
                showSearch
                onFocus={() =>
                  fetchState &&
                  JOINUSSTORE.getStateList().then(() => setFetchState(false))
                }
                notFoundContent={
                  fetchState ? <Spin size="small" /> : "No Record Found."
                }
                onChange={(e) => handleStateChange(e)}
                options={{
                  list: JOINUSSTORE.dropdown_state_list,
                  valueKey: "StateName",
                  textKey: "StateName",
                }}
              />
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className="input_group">
              <InputBox.Text
                label="Type of registration"
                name="vat_registered_type"
                required
                rules={CareerRequest.vat_registered_type}
              />
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className="input_group">
              <InputBox.Text
                label="VAT /CST TIN number"
                name="vat_tin_number"
                required
                rules={CareerRequest.vattin}
              />
            </div>
          </Col>
        </>
      )}
      <Col xs={24}>
        <Divider />
      </Col>
      <Col xs={24}>
        <div className="input_group mb-0">
          <h3 className="pb-0 mb-10">GST Registration Details</h3>
          <InputBox.Radio
            label="Registered / Unregistered"
            name="gst_registered"
            required
            rules={CareerRequest.selection}
            options={{
              list: [
                { id: "1", value: "Yes" },
                { id: "0", value: "No" },
              ],
              valueKey: "id",
              textKey: "value",
            }}
            onChange={(e: any) => {
              setIsHaveGST(e?.target?.value);
            }}
          />
        </div>
      </Col>
      {isHaveGST === "1" && (
        <>
          <Col xs={24} md={12} lg={8}>
            <div className="input_group">
              <InputBox.Select
                label="State"
                name="gst_registered_state"
                required
                rules={CareerRequest.state}
                showSearch
                onFocus={() =>
                  fetchState &&
                  JOINUSSTORE.getStateList().then(() => setFetchState(false))
                }
                notFoundContent={
                  fetchState ? <Spin size="small" /> : "No Record Found."
                }
                onChange={(e) => handleStateChange(e)}
                options={{
                  list: JOINUSSTORE.dropdown_state_list,
                  valueKey: "StateName",
                  textKey: "StateName",
                }}
              />
            </div>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <div className="input_group">
              <InputBox.Text
                label="GST Registration number"
                name="gst_registration_number"
                required
                rules={CareerRequest.gst_number}
              />
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className="input_group">
              <InputBox.Text
                label="GST Registration Address"
                name="gst_registration_address"
                required
                rules={CareerRequest.address}
              />
            </div>
          </Col>
        </>
      )}
      <Col xs={24}>
        <Divider />
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <h4>Particular</h4>
          <InputBox.Text
            label="Contact Person Name"
            name="contact_person_name"
            required
            rules={CareerRequest.name}
          />
          <InputBox.Text
            label="Contact Number"
            name="contact_name"
            required
            rules={CareerRequest.contact_number}
          />
          <InputBox.Text
            label="Email ID"
            name="contact_email"
            required
            rules={CareerRequest.email}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <h4>Business</h4>
          <InputBox.Text
            label="Contact Person Name"
            name="business_contact_person_name"
            rules={CareerRequest.name}
          />
          <InputBox.Text
            label="Contact Number"
            name="business_contact_name"
            rules={CareerRequest.contact_number}
          />
          <InputBox.Text
            label="Email ID"
            name="business_contact_email"
            rules={CareerRequest.email}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <h4>Finance</h4>
          <InputBox.Text
            label="Contact Person Name"
            name="finance_contact_person_name"
            rules={CareerRequest.name}
          />
          <InputBox.Text
            label="Contact Number"
            name="finance_contact_name"
            rules={CareerRequest.contact_number}
          />
          <InputBox.Text
            label="Email ID"
            name="finance_contact_email"
            rules={CareerRequest.email}
          />
        </div>
      </Col>
      <Col xs={24}>
        <Divider />
      </Col>
      <Col xs={24}>
        <h4 className="d-inline">Cheque in favour of/Account Name:</h4>
        <h3 className="d-inline">
          <u>SHREE MARUTI INTIGRATED LOGISTICS LIMITED</u>
        </h3>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Vendor Name as per Bank data"
            name="name_as_bank"
            required
            rules={CareerRequest.name}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Bank Name"
            name="bank_name"
            required
            rules={CareerRequest.bank_name}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Branch Name"
            name="branch_name"
            required
            rules={CareerRequest.branch_name}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Bank Account No."
            name="bank_account_no"
            required
            rules={CareerRequest.bank_account_no}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="IFCI Code No."
            name="ifci_code"
            required
            rules={CareerRequest.ifci_code}
            maxLength={11}
            minLength={11}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Swift Code No."
            name="swift_code"
            required
            rules={CareerRequest.swift_code}
          />
        </div>
      </Col>
      <Col xs={24}>
        <h4 className="d-inline">Declaration:</h4>
        <p>
          The information / documents furnished along with this form for vendor
          registration are true and authentic to the best of my knowledge and
          belief. I / we, am / are well aware of fact that furnishing of any
          false information / fabricated document would lead to rejection of my
          / our registration at any stage.
        </p>
      </Col>
      <Col xs={24}>
        <Divider />
      </Col>
    </Row>
  );
});

export default AgencyForm;
