import React from "react";
import { Tooltip } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
// import type { ColumnsType } from "antd/es/table";
import TooltipIcon from "../../../../assets/images/icon/info.png";
import { useNavigate } from "react-router-dom";
import { vsmNotify } from "../../../../requests/PagesRequest";

interface IMyProps {
  headingText: string;
  multipleTracking: boolean;
}
const BookingInfo: React.FC<IMyProps> = observer((props: IMyProps) => {
  const { headingText, multipleTracking } = props;
  const { TRACK_COURIER_STORE } = useStore();
  const { track_shipment_data, checkMultipleTracking, getShipmentData } =
    TRACK_COURIER_STORE;
  const navigate = useNavigate();

  // interface DataType {
  //   DocumentNo: number;
  //   BookingDate: any;
  //   BookingCenter: any;
  //   ToCenter: any;
  //   BookingType: any;
  //   DeliveryDate: any;
  //   DeliveryStatus: any;
  // }
  const handleSubmit = (val: any) => {
    const payload = {
      type: 1,
      shipment_number: val,
    };
    getShipmentData(payload)
      .then((data: any) => {
        checkMultipleTracking(false);
        navigate("/track-shipment");
      })
      .catch((e: any) => {
        vsmNotify.success({
          message: e.errors,
        });
      });

    // navigate("/track-shipment", {
    //   state: {
    //     type: 1,
    //     shipment_number: val,
    //   },
    // });
  };

  // const columns: ColumnsType<DataType> = [
  //   {
  //     title: "DOCUMENT NO",
  //     dataIndex: "DocumentNo",
  //     key: "DocumentNo",
  //     render: (DocumentNo) =>
  //       multipleTracking === true ? (
  //         <span
  //           className="cursorPointer"
  //           onClick={() => handleSubmit(DocumentNo)}
  //         >
  //           {DocumentNo}
  //         </span>
  //       ) : (
  //         DocumentNo
  //       ),
  //   },
  //   {
  //     title: "BOOKING DATE",
  //     dataIndex: "BookingDate",
  //     key: "BookingDate",
  //   },
  //   {
  //     title: "BOOKING CENTER",
  //     dataIndex: "BookingCenter",
  //     key: "BookingCenter",
  //     render: (BookingCenter) => (
  //       <Tooltip
  //         placement="topLeft"
  //         title={() => (
  //           <div
  //             dangerouslySetInnerHTML={{
  //               __html: BookingCenter.tool_tip,
  //             }}
  //           />
  //         )}
  //       >
  //         {BookingCenter.title}{" "}
  //         <img className="tooltipIcon" src={TooltipIcon} alt="ToolTip" />
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: "TO CENTER",
  //     dataIndex: "ToCenter",
  //     key: "ToCenter",
  //     render: (ToCenter) => (
  //       <Tooltip
  //         placement="topLeft"
  //         title={() => (
  //           <div
  //             dangerouslySetInnerHTML={{
  //               __html: ToCenter.tool_tip,
  //             }}
  //           />
  //         )}
  //       >
  //         {ToCenter.title}{" "}
  //         <img className="tooltipIcon" src={TooltipIcon} alt="ToolTip" />
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: "BOOKING TYPE",
  //     dataIndex: "BookingType",
  //     key: "BookingType",
  //   },
  //   {
  //     title: "DELIVERY DATE",
  //     dataIndex: "DeliveryDate",
  //     key: "DeliveryDate",
  //   },
  //   {
  //     title: "STATUS",
  //     dataIndex: "DeliveryStatus",
  //     key: "DeliveryStatus",
  //   },
  // ];

  return (
    <>
      <div className="trackingInfoBlock">
        <div className="sc_title text-center">
          <div className="main_title mb-2" data-title={headingText}>
            <h3>{headingText}</h3>
          </div>
        </div>

        <div className="infoTableWrap">
          {track_shipment_data.bookingData?.length !== 0 ? (
            <>
              {/* <Table
                pagination={false}
                columns={columns}
                dataSource={track_shipment_data.bookingData}
              /> */}
              <div className="bookingReportWrap">
                <table className="tracking">
                  <thead>
                    <tr>
                      <th>Document No</th>
                      <th>Booking Date</th>
                      <th>Booking Center</th>
                      <th>To Center</th>
                      <th>Booking Type</th>
                      <th>Status Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {track_shipment_data?.bookingData?.map(
                      (item: any, index: any) => {
                        const {
                          DocumentNo,
                          BookingDate,
                          BookingCenter,
                          ToCenter,
                          BookingType,
                          DeliveryDate,
                          DeliveryStatus,
                        } = item;
                        return (
                          <tr key={index}>
                            <td data-label="Document No">
                              {multipleTracking === true ? (
                                <span
                                  className="cursorPointer"
                                  onClick={() => handleSubmit(DocumentNo)}
                                >
                                  {DocumentNo}
                                </span>
                              ) : (
                                DocumentNo
                              )}
                            </td>
                            <td data-label="Booking Date">
                              {BookingDate !== "" ? BookingDate : "-"}
                            </td>
                            <td data-label="Booking Center">
                              {BookingCenter.title ?? "-"}{" "}
                              {BookingCenter.tool_tip && (
                                <Tooltip
                                  placement="leftTop"
                                  title={() => (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: BookingCenter.tool_tip,
                                      }}
                                    />
                                  )}
                                >
                                  <img
                                    className="tooltipIcon"
                                    src={TooltipIcon}
                                    alt="ToolTip"
                                  />
                                </Tooltip>
                              )}
                            </td>
                            <td data-label="To Center">
                              {ToCenter.title ?? "-"}{" "}
                              {ToCenter.tool_tip && (
                                <Tooltip
                                  placement="leftTop"
                                  title={() => (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: ToCenter.tool_tip,
                                      }}
                                    />
                                  )}
                                >
                                  <img
                                    className="tooltipIcon"
                                    src={TooltipIcon}
                                    alt="ToolTip"
                                  />
                                </Tooltip>
                              )}
                            </td>
                            <td data-label="Booking Type">
                              {BookingType !== "" ? BookingType : "-"}
                            </td>
                            <td data-label="Status Date">
                              {DeliveryDate !== "" ? DeliveryDate : "-"}
                            </td>
                            <td data-label="Status">
                              {DeliveryStatus !== "" ? DeliveryStatus : "-"}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p className="noData">{track_shipment_data.bookingDataMsg}</p>
          )}
        </div>
      </div>
    </>
  );
});

export default BookingInfo;
