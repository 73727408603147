import { createContext, useContext } from "react";
import { Context } from "vm";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import HomepageStore from "./HomepageStore/HomepageStore";
import JoinUsStore from "./JoinUsStore/JoinUsStore";
import AllContentPageStore from "./AllContentPageStore/AllContentPageStore";
import ContactUsStore from "./ContactUsStore/ContactUsStore";
import BookingInquiryStore from "./BookingInquiryStore/BookingInquiryStore";
import TrackCourierStore from "./TrackCourierStore/TrackCourierStore";
import EbookStore from "./EbookStore/EbookStore";
import BookingDetailStore from "./BookingDetailStore/BookingDetailStore";
import TravelfreeStore from "./TravelfreeStore/TravelfreeStore";
import NewsletterStore from "./Newsletter/NewsletterStore";
import NetworkStore from "./NetworkStore/NetworkStore";

const AppContext = createContext({
  ROOT: new RootStore(),
  AUTH: new AuthStore(),
  HOMEPAGESTORE: new HomepageStore(),
  JOINUSSTORE: new JoinUsStore(),
  ALLCONTENTPAGESTORE: new AllContentPageStore(),
  CONTACTUSSTORE: new ContactUsStore(),
  BOOKING_INQUIRY_STORE: new BookingInquiryStore(),
  TRACK_COURIER_STORE: new TrackCourierStore(),
  EBOOKSTORE: new EbookStore(),
  BOOKING_DETAIL_STORE: new BookingDetailStore(),
  TRAVELFREESTORE: new TravelfreeStore(),
  NEWSLETTERSTORE: new NewsletterStore(),
  NETWORKSTORE: new NetworkStore(),
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
