import React, { useEffect, useState } from "react";
// import TrackBG from "../../../../../assets/images/track-bg.jpg";
import { Button, Form } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
// import { useNavigate } from "react-router";
import Regex from "../../../../../config/Regex";
import Message from "../../../../../config/Message";
import useStore from "../../../../../store";
import { useNavigate } from "react-router-dom";

const TrackCourier: React.FC<any> = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { trackingData, isTrackYourShipment } = props;
  const {
    TRACK_COURIER_STORE: { checkMultipleTracking },
  } = useStore();
  const [trackTab, setTrackTab] = useState<number>(1);
  const [disabled, setDisabled] = useState<boolean>(true);
  // const navigate = useNavigate();

  const findTab = (val: any) => {
    setTrackTab(val);
  };

  const handleSubmit = () => {
    const data: any = {
      type: form.getFieldValue("type"),
      shipment_number: form.getFieldValue("shipment_number"),
    };
    localStorage.setItem("trackShipmentData", JSON.stringify(data));
    if (isTrackYourShipment) {
      navigate("/track-shipment");
    } else {
      window.open(`/track-shipment`, "_blank");
    }
  };

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  useEffect(() => {
    if (trackingData?.shipment_number) {
      form.setFieldValue("shipment_number", trackingData?.shipment_number);
    }
  });

  return (
    <>
      <div
        className="SectionBG"
        // style={{ backgroundImage: `url(${TrackBG})` }}
      ></div>
      <div className="container">
        <div className="sc_title text-center">
          <div className="main_title mb-2" data-title="Track Your Shipment">
            <h3>Track Your Shipment</h3>
          </div>
        </div>
        <div className="trackCourier_wrap">
          <FormBox
            form={form}
            id="trackCourierForm1"
            onFinish={handleSubmit}
            onChange={handleChange}
          >
            <div className="radio_wrap">
              <div className="input_group">
                <InputBox.Radio
                  onChange={(e) => findTab(e.target.value)}
                  initialValue={1}
                  name="type"
                  // label="Gender"
                  // name="gender"
                  required
                  // rules={CareerRequest.gender}
                  options={{
                    list: [
                      { id: 1, name: "Shipment No." },
                      { id: 2, name: "Mobile Number" },
                      { id: 3, name: "Order ID / Ref ID" },
                    ],
                    valueKey: "id",
                    textKey: "name",
                  }}
                />
              </div>
            </div>
            <div className="tabs_container">
              <div className="tab_content input_group">
                {trackTab === 1 ? (
                  <InputBox.Text
                    className="input"
                    placeholder="Enter Your Shipment No"
                    name="shipment_number"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        pattern: Regex.digitCommaSpace,
                        message: "Only digit allowed",
                      },
                      ({ getFieldValue }) => ({
                        validator() {
                          const shipNo = getFieldValue("shipment_number");
                          let shipNoArr: any = [];
                          if (shipNo) {
                            if (shipNo.includes(",")) {
                              checkMultipleTracking(true);
                              shipNoArr = shipNo.split(",");
                              const trimmedArray = shipNoArr.map((val: any) =>
                                val.trim()
                              );
                              const result = trimmedArray.filter(
                                (num: any) =>
                                  num.length === 11 || num.length === 14
                              );

                              if (trimmedArray.length > 5) {
                                return Promise.reject(
                                  "Track up to 5 numbers at a time. Separated by a comma (,) or Space."
                                );
                              } else if (
                                trimmedArray.length === result.length
                              ) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Shipment No. is either 11 or 14 digit"
                                );
                              }
                            } else if (
                              shipNo.length === 11 ||
                              shipNo.length === 14
                            ) {
                              checkMultipleTracking(false);
                              return Promise.resolve();
                            } else {
                              checkMultipleTracking(false);
                              return Promise.reject(
                                "Shipment No. is either 11 or 14 digit"
                              );
                            }
                          }
                        },
                      }),
                    ]}
                  />
                ) : trackTab === 2 ? (
                  <InputBox.Text
                    className="input"
                    placeholder="Enter Your Mobile No"
                    name="shipment_number"
                    // onChange={handleChange}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        max: 10,
                        message: Message.max(10),
                      },
                      {
                        pattern: Regex.digit,
                        message: "Only digit allow",
                      },
                    ]}
                  />
                ) : trackTab === 3 ? (
                  <InputBox.Text
                    className="input"
                    placeholder="Enter Your Order ID / Ref ID"
                    name="shipment_number"
                    // onChange={handleChange}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  />
                ) : (
                  ""
                )}
                {/* <button className="btn btn_secondary" disabled={disabled}>
                    Track Shipment
                  </button> */}
                <Button
                  className="btn btn_secondary"
                  htmlType="submit"
                  disabled={trackingData?.shipment_number ? false : disabled}
                  // loading={saving}
                >
                  Track Shipment
                </Button>
              </div>
            </div>
          </FormBox>
        </div>
      </div>
    </>
  );
};

export default TrackCourier;
