import React, { useState } from "react";
import { observer } from "mobx-react";
import { Col, Row, Spin } from "antd";
import { InputBox } from "../../../../../components/AntdAddons";
import { CareerRequest } from "../../../../../requests/PagesRequest";
import useStore from "../../../../../store";

const IndividualForm: React.FC<any> = observer(({ form }) => {
  const { JOINUSSTORE } = useStore();
  const [fetchState, setFetchState] = useState<boolean>(true);

  const getStateID = (stateName: string) => {
    const state = JOINUSSTORE?.dropdown_state_list?.find(
      (state: any) => state.StateName === stateName
    );
    return state ? state.StateID : null;
  };

  const handleStateChange = (stateName: any) => {
    form.setFieldsValue({ city: null });
    const stateID = getStateID(stateName);
    if (stateID && stateID !== undefined) {
      const data = { state_id: parseInt(stateID) };
      JOINUSSTORE.getCityList(data);
    }
  };

  return (
    <Row gutter={48}>
      <Col xs={24} md={12} lg={8}>
        <InputBox.Text hidden name="form_type" value="individual" />
        <div className="input_group">
          <InputBox.Text
            label="Name"
            name="name"
            required
            rules={CareerRequest.name}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Contact Number"
            name="contact_number"
            required
            rules={CareerRequest.contact_number}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Email ID"
            name="contact_email"
            required
            rules={CareerRequest.email}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Select
            label="State"
            name="state"
            required
            rules={CareerRequest.state}
            showSearch
            onFocus={() =>
              fetchState &&
              JOINUSSTORE.getStateList().then(() => setFetchState(false))
            }
            notFoundContent={
              fetchState ? <Spin size="small" /> : "No Record Found."
            }
            onChange={(e) => handleStateChange(e)}
            options={{
              list: JOINUSSTORE.dropdown_state_list,
              valueKey: "StateName",
              textKey: "StateName",
            }}
          />
        </div>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="City"
            name="city"
            required
            rules={CareerRequest.city}
          />
        </div>
        {/* <div className="input_group">
          <InputBox.Select
            label="City"
            name="city"
            required
            showSearch
            rules={CareerRequest.city}
            notFoundContent={
              JOINUSSTORE?.dropdown_city_list ? (
                <Spin size="small" />
              ) : (
                "No Record Found."
              )
            }
            options={{
              list: JOINUSSTORE?.dropdown_city_list,
              valueKey: "CityName",
              textKey: "CityName",
            }}
          />
        </div> */}
      </Col>
      <Col xs={24} md={12} lg={8}>
        <div className="input_group">
          <InputBox.Text
            label="Type Of Vehicle"
            name="vehicle_type"
            required
            rules={CareerRequest.vehicle_type}
          />
        </div>
      </Col>
    </Row>
  );
});

export default IndividualForm;
