// import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import PageBanner from "../../../components/PageBanner";
import useStore from "../../../store";
import { ONDCDisclosureBreadcrumb } from "../../../config/BreadcrumbConfig";
import SeoHeader from "../../../components/SeoHeader";

const ONDCDisclosurePage: React.FC = observer(() => {
  const { ALLCONTENTPAGESTORE } = useStore();
  const { ONDCDisclosureAPICall, OndcDisclosureData } = ALLCONTENTPAGESTORE;

  const pageBannerData = OndcDisclosureData?.slider[0];

  const OndcData = OndcDisclosureData?.sections?.page_content;

  useEffect(() => {
    ONDCDisclosureAPICall();
  }, [ONDCDisclosureAPICall]);

  const SeoData = {
    title:
      OndcDisclosureData?.seo_data?.title ??
      "ONDC Disclosure for Shree Maruti Courier Pvt. Ltd.",
    description:
      OndcDisclosureData?.seo_data?.description ??
      "Shree Maruti provides Travel Free process for online parcel booking, same-day door-to-door delivery service, and the best home pickup courier service in India.",
    keywords:
      OndcDisclosureData?.seo_data?.keywords ??
      "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
    url: OndcDisclosureData?.seo_data?.url ?? "/conditions-of-carriage",
    ogTitle:
      OndcDisclosureData?.seo_data?.og_title ??
      "ONDC Disclosure for Shree Maruti Courier Pvt. Ltd.",
    ogImage: OndcDisclosureData?.seo_data?.og_image,
  };

  return (
    <>
      <SeoHeader seoData={SeoData} />
      <PageBanner
        backgroundImg={pageBannerData?.backgroud_image_url}
        heading1={pageBannerData?.heading_1}
        heading2={pageBannerData?.heading_2}
        heading3={pageBannerData?.heading_3}
        buttonTitle={pageBannerData?.button_title}
        buttonLink={pageBannerData?.button_link}
        breadcrumbData={ONDCDisclosureBreadcrumb.path}
      />

      <section className="privacyPolicy pv-64">
        <div className="container">
          <div className="sc_title text-center">
            <div className="main_title" data-title={OndcData?.heading}>
              <h3>{OndcData?.heading}</h3>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: OndcData?.content }} />
        </div>
      </section>
    </>
  );
});

export default ONDCDisclosurePage;
