import React from "react";
import { useLocation } from "react-router-dom";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";

const Playstore: React.FC = () => {
    const { search } = useLocation();
    if (search === "?a=R98s3K") {
        window.location.replace(
            "https://play.google.com/store/apps/details?id=com.maruticourier.android"
        );
    } else if (search === "?a=S79s3P") {
        window.location.replace(
            "https://apps.apple.com/in/app/shree-maruti-courier/id658296993"
        );
    }

    return search ? (
        <PageSpinner />
    ) : null
};

export default Playstore;
