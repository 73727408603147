import ShreeMarutiLogo from "../assets/images/Shree-Maruti-Courier-Pvt-Ltd.jpg";
export const seoDefaultData = {
  title:
    "India's fastest express delivery, supply chain, and logistics provider",
  description:
    "Shree Maruti is leading in Asia pacific for logistic and courier delivery services, integrated express package distribution, and supply chain services.",
  keywords:
    "fastest express delivery, supply chain, logistics provider,shree maruti, courier",
  url: "/",
  ogTitle:
    "India's fastest express delivery, supply chain, and logistics provider",
  ogImage: ShreeMarutiLogo,
};
