import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import SeoHeader from "../../../components/SeoHeader";
import PageBanner from "../../../components/PageBanner";
import useStore from "../../../store";
import { StoragePartnerBreadcrumb } from "../../../config/BreadcrumbConfig";
import StoragePartnerForm from "./component/StoragePartnerForm";

const StoragePartner: React.FC = observer(() => {
  const { JOINUSSTORE } = useStore();

  useEffect(() => {
    JOINUSSTORE.careerAPICall();
  }, [JOINUSSTORE]);

  const pageBannerData = JOINUSSTORE?.careerData?.slider[0];
  const SeoData = {};
  return (
    <>
      <SeoHeader seoData={SeoData} />
      <PageBanner
        backgroundImg={pageBannerData?.backgroud_image_url}
        heading1={pageBannerData?.heading_1}
        heading2={pageBannerData?.heading_2}
        heading3={pageBannerData?.heading_3}
        buttonTitle={pageBannerData?.button_title}
        buttonLink={pageBannerData?.button_link}
        breadcrumbData={StoragePartnerBreadcrumb.path}
      />
      <div className="tabs_wrapper">
        <div className="container pt-64">
          <ul className="tab_wrap">
            <li className={`tab `}>
              <Link to="/join-us">Career</Link>
            </li>
            <li className={`tab`}>
              <Link to="/channel-partner">Channel Partner</Link>
            </li>
            <li className={`tab`}>
              <Link to="/delivery-partner">Delivery Partner</Link>
            </li>
            <li className={`tab active`}>
              <Link to="/storage-partner">Storage Partner</Link>
            </li>
          </ul>
        </div>

        <div className="tabs_container">
          <StoragePartnerForm />
        </div>
      </div>
    </>
  );
});

export default StoragePartner;
