const Regex = {
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[0-9]).*$/,
  digit: /^(0|[1-9][0-9]*)$/,
  digitCommaSpace: /^[0-9 ,]*$/,
  decimal: /(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )$/,
  nonWhiteSpace: /^[^\s]+(\s+[^\s]+)*$/,
  pan: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
  gstin:
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}$/,
  vattin: /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/,
};

export default Regex;
