import React from "react";
// import { Spin } from "antd";
import maruti_page_loadergif from "../../assets/images/icon/maruti_page_loader.gif";

const PageSpinner: React.FC = () => (
  <div className={"fullscreen__spinner__wrapper"}>
    {/* <Spin size={"large"} /> */}
    <img
      src={maruti_page_loadergif}
      alt="Loading..."
      style={{ width: "150px", height: "auto" }}
    />
  </div>
);

export default PageSpinner;
