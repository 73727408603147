import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Row } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import {
  CareerRequest,
  ContactRequest,
} from "../../../../requests/PagesRequest";
import { randomnumbersubstring } from "../../../../utils/GlobalFunction";
import SVGSprite from "../../../../assets/images/icon/connect-sprite.svg";
import AgencyForm from "./elements/AgencyForm";
import IndividualForm from "./elements/IndividualForm";
import useStore from "../../../../store";

const DeliveryPartnerForm: React.FC = observer(() => {
  const [form] = Form.useForm();
  const { ROOT, JOINUSSTORE } = useStore();
  const [saving, setSaving] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [formType, setFormType] = useState<string>("");

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const handleSubmit = (data: any) => {
    setSaving(true);

    if (formType === "Individual") {
      data.form_type = "individual";
    } else if (formType === "Agency") {
      data.form_type = "agency";
    }
    JOINUSSTORE.submitDeliveryPartnerForm(data)
      .then(() => {
        setSaving(false);
        setFormType("");
        form.resetFields();
        resetCaptcha();
      })
      .catch((e: any) => {
        if (e.data.errors) {
          // form.setFields(e.errors);
          resetCaptcha();
          ROOT.assignErrorToInput(form, e?.data?.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const resetCaptcha = () => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  };

  useEffect(() => {
    form.setFieldsValue({ captcha: randomnumbersubstring() });
  }, [form]);

  return (
    <section className="career_section pv-48">
      <div className="container_sm">
        <div className="sc_title text-center">
          <div className="main_title" data-title="Delivery Partner">
            <h3>Delivery Partner</h3>
          </div>
        </div>

        <div className="input_group_blk mt-48">
          <FormBox
            form={form}
            id="DeliveryPartnerForm"
            onFinish={handleSubmit}
            onChange={handleChange}
          >
            <div className="travelFreeForm">
              <div className=" ServiceBox">
                <div className="serviceType">
                  <InputBox.Radio
                    label="Delivery Partner Type?"
                    name="form_type"
                    required
                    rules={CareerRequest.selection}
                    options={{
                      list: [
                        { id: "Individual", value: "Individual" },
                        { id: "Agency", value: "Agency" },
                      ],
                      valueKey: "id",
                      textKey: "value",
                    }}
                    onChange={(e: any) => {
                      setFormType(e?.target?.value);
                      form.resetFields();
                      form.setFieldsValue({ form_type: e?.target?.value });
                      resetCaptcha();
                    }}
                  />
                </div>
              </div>
            </div>
            <Row gutter={48}>
              <Col xs={24}>
                {formType === "Individual" ? (
                  <IndividualForm form={form} />
                ) : formType === "Agency" ? (
                  <AgencyForm form={form} />
                ) : null}
              </Col>

              {(formType === "Individual" || formType === "Agency") && (
                <>
                  <Col xs={24} md={12} lg={8} className="captchaWrap">
                    <Row>
                      <Col xs={22}>
                        <div className="captchaLeft">
                          <InputBox.Text
                            label="Captcha"
                            name="captcha"
                            className="captchaCode"
                            disabled
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <Button
                          type="link"
                          className="resetCaptcha autoWidth autoHeight"
                          onClick={() => resetCaptcha()}
                        >
                          <svg className="resetCaptcha">
                            <use xlinkHref={`${SVGSprite}#reset-icon`}></use>
                          </svg>
                        </Button>
                      </Col>
                    </Row>
                    <div className="input_group">
                      <InputBox.Text
                        label="Enter Captcha"
                        name="re_captcha"
                        required
                        // onPaste={(e) => {
                        //   e.preventDefault();
                        //   return false;
                        // }}
                        rules={ContactRequest.re_captcha}
                      />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabled}
                      loading={saving}
                    >
                      Submit
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </FormBox>
        </div>
      </div>
    </section>
  );
});

export default DeliveryPartnerForm;
