import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class ContactUsStore {
  public contactData: any;
  public contactFormData: any;

  constructor() {
    makeAutoObservable(this);
  }

  // API Functions
  public contactAPICall = async (): Promise<any> => {
    return await axios.post(API_URL.CONTACT.BANNER).then(({ data }) => {
      this.contactData = data.data;
    });
  };

  public submitContactForm = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.CONTACT.CONTACTFORM, payload)
      .then(({ data }) => {
        return data;
      });
  };
}
