import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import TrackCourier from "../HomePage/component/TrackCourier";

const TrackYourShipment: React.FC = observer(() => {
  const [trackingData, setTrackingData] = useState<any>();
  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const dKey = searchParams.get("d");
    const tracking_idKey = searchParams.get("tracking_id");
    const docnoKey = searchParams.get("docno");

    const data = {
      type: 1,
      shipment_number: dKey
        ? searchParams.get("d")
        : tracking_idKey
        ? searchParams.get("tracking_id")
        : docnoKey
        ? searchParams.get("docno")
        : null,
    };
    localStorage.setItem("trackShipmentData", JSON.stringify(data));
    setTrackingData(data);
  }, []);

  return (
    <section className="trackCourier_section pv-64">
      <TrackCourier trackingData={trackingData} isTrackYourShipment={true} />
    </section>
  );
});

export default TrackYourShipment;
