import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "antd";
import useStore from "../../../store";
import { ReactComponent as DotIcon } from "../../../assets/images/icon/12dot.svg";

const PartPaymentPending: React.FC<any> = observer(() => {
  const { EBOOKSTORE } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    const url = new URL(window.location.href);
    let searchParams: null | any = new URLSearchParams(url.search);

    const isLogin = localStorage.getItem("token");

    localStorage.setItem("desinationPage", "part-payment-pending");
    localStorage.setItem("PendingPaymentDocNo", searchParams.get("doc"));
    if (isLogin) {
      EBOOKSTORE?.getPendingDocumentData(searchParams.get("doc"));
    } else {
      navigate("/ebook-login/");
    }
  }, [EBOOKSTORE, navigate]);

  const bookingData = EBOOKSTORE?.pending_document_data;
  const payuData = EBOOKSTORE?.pending_document_data?.payu;

  const handleSubmit = () => {
    var form = document.createElement("form");
    var key = document.createElement("input");
    var hash = document.createElement("input");
    var txnid = document.createElement("input");
    var amount = document.createElement("input");
    var firstname = document.createElement("input");
    var email = document.createElement("input");
    var phone = document.createElement("input");
    var productinfo = document.createElement("input");
    var surl = document.createElement("input");
    var furl = document.createElement("input");
    var udf1 = document.createElement("input");

    if (payuData) {
      form.method = "POST";
      form.action =
        payuData?.payu_submit_url ?? "https://test.payu.in/_payment";

      key.value = payuData?.payu_key ?? "uc73yu";
      key.name = "key";
      form.appendChild(key);

      hash.value = payuData?.hash;
      hash.name = "hash";
      form.appendChild(hash);

      txnid.value = payuData?.txnid;
      txnid.name = "txnid";
      form.appendChild(txnid);

      amount.value = payuData?.amount;
      amount.name = "amount";
      form.appendChild(amount);

      firstname.value = payuData?.firstname;
      firstname.name = "firstname";
      form.appendChild(firstname);

      if (payuData?.user_email) {
        email.value = payuData?.user_email;
        email.name = "email";
        form.appendChild(email);
      }

      phone.value = payuData?.phone;
      phone.name = "phone";
      form.appendChild(phone);

      productinfo.value = payuData?.productinfo;
      productinfo.name = "productinfo";
      form.appendChild(productinfo);

      surl.value = payuData?.surl;
      surl.name = "surl";
      form.appendChild(surl);

      furl.value = payuData?.furl;
      furl.name = "furl";
      form.appendChild(furl);

      udf1.value = payuData?.udf1;
      udf1.name = "udf1";
      form.appendChild(udf1);

      document.body.appendChild(form);
      form.submit();
    }
  };

  return (
    <section
      className="eBook_section pv-64"
      style={{ paddingTop: "200px" }} //backgroundColor: "rgba(0,0,0,0.70)",
    >
      <div className="container_sm">
        <div className="sc_title text-center">
          <div className="main_title" data-title="Pending Part Payment">
            <h3>Pending Part Payment</h3>
          </div>
        </div>
        {bookingData ? (
          <div className="summaryWrap">
            <h3 className="mt-20">
              Document No.: {bookingData?.DocumentNo ?? "-"}
            </h3>
            <Row gutter={30} align="top">
              <Col xs={24} lg={15}>
                <div className="mainBox">
                  <div className="dataSection">
                    <div className="header">
                      <strong>
                        <DotIcon /> Order Details
                      </strong>
                    </div>
                    <div className="details">
                      <table className="orderDetails ebooking">
                        <thead>
                          <tr>
                            <th style={{ width: "40%" }}>Item</th>
                            <th style={{ width: "60%" }} className="text-left">
                              Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Shipment Type</td>
                            <td>{bookingData?.ShipmentType ?? "-"}</td>
                          </tr>
                          <tr>
                            <td>Service Type</td>
                            <td>{bookingData?.ServiceType ?? "-"}</td>
                          </tr>
                          <tr>
                            <td>Old Weight</td>
                            <td>{bookingData?.OldWeight ?? "-"}</td>
                          </tr>
                          <tr>
                            <td>Actual Weight</td>
                            <td>{bookingData?.ActualWeight ?? "-"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={9}>
                <div className="mainBox">
                  <div className="paymentSection">
                    <h4 className="title">Payment Summary</h4>
                    <ul className="priceSummary pendingPayment">
                      <li>
                        <span>Booking Amount</span>
                        <span>
                          {bookingData?.BookingAmount?.toFixed(2) ?? "0.00"}
                        </span>
                      </li>
                      {bookingData?.IGSTCharges === 0 ? (
                        <>
                          <li>
                            <span>SGST {`(9%)`}</span>
                            <span>
                              {parseFloat(bookingData?.SGSTCharges)?.toFixed(
                                2
                              ) ?? "0.00"}
                            </span>
                          </li>
                          <li>
                            <span>CGST {`(9%)`}</span>
                            <span>
                              {parseFloat(bookingData?.CGSTCharges)?.toFixed(
                                2
                              ) ?? "0.00"}
                            </span>
                          </li>
                        </>
                      ) : (
                        <li>
                          <span>IGST Charges {`(18%)`}</span>
                          <span>
                            {parseFloat(bookingData?.IGSTCharges)?.toFixed(2) ??
                              "0.00"}
                          </span>
                        </li>
                      )}

                      <li className="highlight borderTop">
                        <span>Amount to be paid</span>
                        <span>
                          {parseFloat(bookingData?.AmountToBePaid)?.toFixed(
                            2
                          ) ?? "0.00"}
                          *
                        </span>
                      </li>
                      <li className="highlight">
                        <span>Previous Payment Done</span>
                        <span>
                          {bookingData?.PreviousPaymentDone?.toFixed(2) ??
                            "0.00"}
                          *
                        </span>
                      </li>
                      <li className="highlight">
                        <span>Pending Payment</span>
                        <span>
                          {bookingData?.PendingPeyment?.toFixed(2) ?? "0.00"}*
                        </span>
                      </li>
                    </ul>

                    <p className="note">* Transportation Cost</p>
                    <p className="note">
                      * The rates indicated are dependent on the actual weight
                      and the final price will be determined in the Shree Naruti
                      Courier outlet in the case of scheduled pickup.
                    </p>
                  </div>
                </div>
                {/* <div className="gstForm">
                <Form.Item name="gst_invoce">
                  <Checkbox onChange={(e) => handleGSTChange(e)}>
                    Request GST Tax Invoice?
                  </Checkbox>
                </Form.Item>
                {showGST && (
                  <FormBox
                    form={form}
                    className="gst_form"
                    id="gstForm"
                    onFinish={handleGSTSubmit}
                    onChange={gstHandleChange}
                  >
                    <Row gutter={15}>
                      <Col xs={24} sm={12} md={8} lg={24}>
                        <div className="input_group">
                          <InputBox.Text
                            placeholder="Name *"
                            name="gst_name"
                            required
                            rules={EbookFormRequest.gst_name}
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={24}>
                        <div className="input_group">
                          <InputBox.Text
                            placeholder="GST Number *"
                            name="gst_number"
                            required
                            rules={EbookFormRequest.gst_number}
                          />
                        </div>
                      </Col>
                      <Col xs={24} md={8} lg={24}>
                        <div className="input_group">
                          <Button
                            type="default"
                            id="gstForm"
                            htmlType="submit"
                            disabled={disabledGST}
                          >
                            Apply
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </FormBox>
                )}
              </div> */}
              </Col>
            </Row>

            <div className="text-center">
              <Button
                type="primary"
                // className="btn btn_secondary"
                onClick={handleSubmit}
                // htmlType="submit"
                // disabled={disabledPayment}
              >
                Proceed Payment
              </Button>
            </div>
          </div>
        ) : (
          <>
            <h3 className="text-center">Please try later</h3>
            <h4 className="text-center">
              {EBOOKSTORE?.pending_document_error?.errors ?? ""}
            </h4>
          </>
        )}
      </div>
    </section>
  );
});

export default PartPaymentPending;
